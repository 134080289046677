import React, { useEffect, useState } from 'react';
import { PlusOutlined} from '@ant-design/icons';
import { App, Button, Col, Drawer, Form, Input, Row, Select, Space } from 'antd';
import { v4 as uuidv4 } from 'uuid'; 
import { insertTemplates } from '../lib/db';
import { useNavigate } from 'react-router-dom';

const NewTemplates = ({ onAddSuccess }) => {
    const [open, setOpen] = useState(false);
    const { message } = App.useApp();
    const [form] = Form.useForm();
    const currentUser = localStorage.getItem('currentUser');
  
    const navigate = useNavigate();
 
    const onFinish = (values) => {
 
    const { data, timestamp } = JSON.parse(currentUser);
 
    const templateId = uuidv4();
    const updatedValues = {
        userid:data.id,
      ...values,
      created_at: new Date().toISOString(),
      template_id: templateId,
      template:{}
    };
    const sanitizedValues = Object.fromEntries(
        Object.entries(updatedValues).map(([key, value]) => [key, value !== undefined ? value : ""])
      );
    const insertData = async(data) =>{
    try{
        await insertTemplates(data);
        onAddSuccess();
         form.resetFields();
         onClose();
         navigate(`/templates/create/${templateId}`);
    }
    catch(error){

    }
}

insertData(sanitizedValues);

      };


  

    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
 



    return (
        <>
            <Button type="primary" onClick={showDrawer} icon={<PlusOutlined />}>
                Create
            </Button>
            <Drawer
                title="Create a new Template"
                width={720}
                onClose={onClose}
                open={open}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={() => form.submit()} type="primary">
                            Submit
                        </Button>
                    </Space>
                }
            >
                <Form form={form}  onFinish={onFinish} layout="vertical">
                    <Row gutter={16}>
                        <h1 className="font-bold py-3 text-xl">Basic Info</h1>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="name"
                                label="Name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required',


                                    },
                                ]}
                            >
                                <Input size="large" placeholder="Enter a Name" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                             
                            >
                                <Button  type="primary" htmlType="submit">Submit </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </>
    );
};
export default NewTemplates;