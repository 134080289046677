const InitEmailTemplate = `<div contenteditable="true" translate="no"
                                                class="ProseMirror tip_tap_editor_styles" tabindex="0">
                                                <h2 style="text-align: center">Greetings {{name}},</h2>
                                                <p>&nbsp;</p>
                                                <p style="text-align: center">Thank You so much for your Participation
                                                    in <strong>{{event.name}} by
                                                        {{organization.name}}</strong>.<br><br>Share your certificates
                                                    on social media handles using hashtags
                                                    <strong>{{event.hashtags}}</strong></p>
                                                <p style="text-align: center"><br class="ProseMirror-trailingBreak"></p>
                                                <p style="text-align: center"><br class="ProseMirror-trailingBreak"></p>
                                                <p style="text-align: center">Download or verify your certificate below
                                                </p>
                                                <p><br><br class="ProseMirror-trailingBreak"></p>
                                                <table width="100%" table-layout="fixed" style="table-layout: fixed;">
                                                    <tbody>
                                                        <tr>
                                                            <td colspan="1" rowspan="1">
                                                                <p style="text-align: center"><a target="_blank"
                                                                        rel="noopener noreferrer nofollow"
                                                                        class="editor_link editor_link editor_link_button"
                                                                        href="{{download_link}}"
                                                                        style="display:inline-block;padding:16.0px 36.0px;font-family:&quot;Source Sans Pro&quot;, Helvetica, Arial, sans-serif;font-size:16.0px;color:rgb(255,255,255);text-decoration:none;border-radius:6.0px;background-color:#1a82e2"
                                                                        title="Linked To: {{download_link}}">Download</a>
                                                                </p>
                                                            </td>
                                                            <td colspan="1" rowspan="1">
                                                                <p style="text-align: center"><a target="_blank"
                                                                        rel="noopener noreferrer nofollow"
                                                                        class="editor_link editor_link editor_link_button"
                                                                        href="{{verification_link}}"
                                                                        style="display:inline-block;padding:16.0px 36.0px;font-family:&quot;Source Sans Pro&quot;, Helvetica, Arial, sans-serif;font-size:16.0px;color:rgb(255,255,255);text-decoration:none;border-radius:6.0px;background-color:#1a82e2"
                                                                        title="Linked To: {{verification_link}}">Verify</a>
                                                                </p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <p><br class="ProseMirror-trailingBreak"></p>
                                                <p><br class="ProseMirror-trailingBreak"></p>
                                                <p>To get the most out of your digital badge, take these next steps:</p>
                                                <ul>
                                                    <li>
                                                        <p>Download your certificate.</p>
                                                    </li>
                                                    <li>
                                                        <p>Share on social media with hashtags.</p>
                                                    </li>
                                                    <li>
                                                        <p>Update your LinkedIn Profile.</p>
                                                    </li>
                                                </ul>
                                                <p><br class="ProseMirror-trailingBreak"></p>
                                                <p>Digital certificate save our trees and our environment. Say Hello
                                                    to&nbsp;<strong>Give My Certificate.</strong><br><br>We would like
                                                    to assure you that your data is safe with us and is used for one
                                                    reason alone: Verification of your Documents. We do not and will
                                                    never share your data with anyone.</p>
                                                <p><br class="ProseMirror-trailingBreak"></p>
                                                <p>In case you are having trouble reaching your certificate please copy
                                                    and paste below URL</p>
                                                <p>{{download_link}}</p>
                                                <p><br class="ProseMirror-trailingBreak"></p>
                                                <p><br>Regards</p>
                                                <p>{{organization.name}}</p>
                                                <p><br class="ProseMirror-trailingBreak"></p>
                                                <hr contenteditable="false">
                                                <p><br class="ProseMirror-trailingBreak"></p>
                                            </div>`;

export { InitEmailTemplate };