import React, { useEffect, useState } from 'react';
import { getUserCreatedRecordsCount, isSuperAdmin } from '../lib/db';
import { Typography } from 'antd';
import Loader from '../components/Loader';

const Home = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [userscredit, setUsersCredit] = useState(null);
  const [creditsdetails, setCreditsDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAdminStatus = async () => {
      const storedUser = localStorage.getItem('currentUser');
      if (storedUser) {
        const userdata = JSON.parse(storedUser);
        const adminStatus = await isSuperAdmin(userdata.data.id);
        const userData = await getUserCreatedRecordsCount(userdata.data.id);

        setCreditsDetails(userdata);
        setIsAdmin(adminStatus);
        setUsersCredit(userData);
      }
      setLoading(false);
    };

    // Delay the check until after the component has mounted
    const timeoutId = setTimeout(() => {
      setCurrentUser(localStorage.getItem('currentUser'));
      checkAdminStatus();
    }, 500); // Delay of 500ms to ensure localStorage is set

    // Cleanup timeout on component unmount
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <>
      {loading && <Loader />}

      {!loading && currentUser && (
        <div className='w-full h-full p-3 flex gap-5 justify-start flex-col items-center'>
          {creditsdetails && (
            <div className="w-full p-3 bg-white rounded-lg">
              <Typography.Title level={2}>Welcome back, {creditsdetails.data?.email}!</Typography.Title>
            </div>
          )}
          <div className="w-full p-3 bg-white rounded-lg">
            {userscredit && (
              <div className="flex gap-3">
                <div className="flex-1">
                  <div className="homecard-1 text-white p-5 rounded-lg">
                    <h1 className="font-bold">
                      {isAdmin ? 'Total Organizations' : 'Organizations Created'}
                    </h1>
                    <h1 className="font-bold text-5xl">
                      {userscredit.organization}
                    </h1>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="homecard-2 text-white p-5 rounded-lg">
                    <h1 className="font-bold">
                      {isAdmin ? 'Total Events' : 'Events Created'}
                    </h1>
                    <h1 className="font-bold text-5xl">
                      {userscredit.events}
                    </h1>
                  </div>
                </div>
                {isAdmin ? (
                  <div className="flex-1">
                    <div className="homecard-3 text-white p-5 rounded-lg">
                      <h1 className="font-bold">Total Users</h1>
                      <h1 className="font-bold text-5xl">
                        {userscredit.orgadmin}
                      </h1>
                    </div>
                  </div>
                ) : (
                  <div className="flex-1">
                    <div className="homecard-3 text-white p-5 rounded-lg">
                      <h1 className="font-bold">Credits Details</h1>
                      <h1 className="font-bold text-5xl">
                        {userscredit.usedCredits}/{userscredit.credits}
                      </h1>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Home;
