import React, { useEffect, useState } from 'react';
import { Button, Table, Space, App, Popconfirm, Badge } from 'antd';

import { deleteCertificate, deleteJobs, getCertificatesByJobId, getJobByUserId } from '../lib/db';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Link, useParams } from 'react-router-dom';
import DownloadLinkInput from './DownloadLinkInput';
import ShowCertificate from './ShowCertificate';
import EditCertificateJob from './EditCertificateJob';
import VerifyLinkInput from './VerifyLinkInput';
import Loader from './Loader';


const CertificateList = () => {

  const [loading, setLoading] = useState(false);
  const [pageload,setPageLoad] = useState(true);

  const currentUser = localStorage.getItem('currentUser');
  const [Jobsdata, setJobsData] = useState([]);
  const { message } = App.useApp();
  const { id: jobid } = useParams();
  const [certificateKey ,setCertificateKey] = useState('');

  const columns = (onEditSuccess) => [
    {
      title: 'Certificate ID',
      dataIndex: 'id',
    },
    {
      title: 'Recipient Name',
      dataIndex: 'recipient_name',
    },
    {
      title: 'Download Link',
      render: (text, record) => (
        <DownloadLinkInput id={record.id} />
      ),
    },
    {
      title: 'Verification Link',
      render: (text, record) => (
        <VerifyLinkInput id={record.id} />
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Space size="middle">
<ShowCertificate data={record}/>
          <Popconfirm
            title="Delete the Row"
            description="Are you sure to delete this record?"
            onConfirm={() => confirmDelete(record.key)}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <Button shape="circle" icon={<DeleteOutlined />} danger />
          </Popconfirm>
<EditCertificateJob data={record} certificateKey={certificateKey} onUpdate={fetchJobData} />
        </Space>
      ),
    },
  ];


  const confirmDelete = async (key) => {

    try {
      await deleteCertificate(certificateKey,key);
      fetchJobData();
      message.success('Certificate Deleted Successfully');

    }
    catch (error) {

    }
  };
  const cancel = (e) => {
    console.log(e);
    message.error('Cancelled');
  };


  const fetchJobData = async () => {


    const userdata = JSON.parse(currentUser);


    const fetchJobdata = await getCertificatesByJobId(jobid);
   
    if (Object.keys(fetchJobdata).length > 0) {
        const jsonJobArray = Object.entries(fetchJobdata).map(([id, details]) => {
          setCertificateKey(id);
          return { ...details };
        });
      
        // Extract keys and values, preserving the key-value pairs
        const jobsDataWithKeys = Object.entries(jsonJobArray[0]).map(([key, value]) => {
          return { key, ...value }; // Or you can return { [key]: value } if you want an object format
        });
        setJobsData(jobsDataWithKeys);
        setPageLoad(false);

      }else {
      setJobsData([]);
      setPageLoad(false);

    }

  }


  useEffect(() => {

    fetchJobData();
  }, []);


  const start = () => {
    setLoading(true);
    fetchJobData();
    setTimeout(() => {

      setLoading(false);
    }, 1000);
  };





  return (
    <>
    {pageload && <Loader/>}
   {!pageload &&  <div className="w-full p-3 bg-white rounded">
      <div style={{ marginBottom: 16 }} className='flex justify-between'>
        <Button type="primary" onClick={start} loading={loading}>
          Refresh
        </Button>
        <Link to={"/certificate/new"}>
          <Button type="primary" icon={<PlusOutlined />}>
            Create
          </Button>
        </Link>
      </div>
      <Table columns={columns(fetchJobData)} dataSource={Jobsdata} />
    </div>}
    </>
  );
};

export default CertificateList;
