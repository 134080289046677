import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { getTemplateByUserIdAndTemplateId } from '../lib/db';
import CertificateCanvasEdit from './CertificateCanvasEdit';
import CertificateCanvas from './CertificateCanvas';

const EditCertificate = () => {

  const currentUser = localStorage.getItem('currentUser');
  const { data:userData, timestamp } = JSON.parse(currentUser);  
  const { id: templateId } = useParams();
  const [template, setTemplate] = useState(null);
  const [loading, setLoading] = useState(true);

    
    useEffect(() => {
        const fetchTemplate = async () => {
          const userId = userData.id; 
          try {
            const templateData = await getTemplateByUserIdAndTemplateId(userId, templateId);    
      
            setTemplate(templateData);
          } catch (error) {
            console.error('Error fetching template:', error);
          }
          finally {
            setLoading(false);
          }
        };
    
        if (templateId) {
          fetchTemplate();
        }
      }, [templateId, userData.id]);


  if (loading) {
    return <div>Loading...</div>;
  }

  if (!template || !template.templatedesign || !template.templatedesign.elements) {
    return <CertificateCanvas />;
  }
      
  return (
    <div className='w-full'>
    <CertificateCanvasEdit
      templateId={templateId}
      template={template}
      userData={userData}
    />
  </div>
  )
}

export default EditCertificate