import React, { createContext, useContext, useState, useEffect } from 'react';
import { database } from '../lib/firebase';
import { get, onValue, push, ref, set, update } from 'firebase/database';
import { insertEmailConfig , updateUser as updateUserInDb} from '../lib/db';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(() => {
        const storedUser = localStorage.getItem('currentUser');
        if (storedUser) {
          const { data, timestamp } = JSON.parse(storedUser);
          // Check if data is not expired (1 day)
          if (Date.now() - timestamp < 24 * 60 * 60 * 1000) {
            return data;
          } else {
            localStorage.removeItem('currentUser');
          }
        }
        return null;
      });

      useEffect(() => {
        if (currentUser) {
          const storedUser = JSON.stringify({ data: currentUser, timestamp: Date.now() });
          localStorage.setItem('currentUser', storedUser);
        } else {
          localStorage.removeItem('currentUser');
        }
      }, [currentUser]);

      const login = async (email, password) => {
        try {
          const usersRef = ref(database, 'users');
          let userData = null;
    
          // Listen for changes to the users node
          await new Promise((resolve, reject) => {
            onValue(usersRef, (snapshot) => {
              const users = snapshot.val();

              const userId = Object.keys(users).find(id => users[id].email === email);
              
              if (userId) {
                userData = { id: userId, ...users[userId] };
                resolve();
              } else {
                reject(new Error('User not found'));
              }
            }, {
              onlyOnce: true // Ensure the listener is triggered only once
            });
          });
    
          // Check if userData is null (no user found) or password doesn't match
          if (!userData || userData.password !== password) {
            throw new Error('Incorrect email or password');
          }
          const { password: _, ...userWithoutPassword } = userData;
          // Login successful, set current user
          setCurrentUser({ ...userWithoutPassword, id: userWithoutPassword.id });
        } catch (error) {
          console.error('Error logging in user:', error.message);
          throw error;
        }
      };
  const register = async (email, password, role,credits,admid,usedCredits=0) => {
    try {
      const newUserRef = push(ref(database, 'users/')); // Push generates a new key for the user
      await set(newUserRef, {
        email,
        password,
        role,
        credits,
        admid,
        usedCredits
      });
      const userId = newUserRef.key;
      const userWithoutPassword = { id: userId, email, role,admid };
        setCurrentUser(userWithoutPassword);
      const updatedData = {
        emailTemplate: 'Default'
      };
      await insertEmailConfig(userId,updatedData);
      
    } catch (error) {
      console.error('Error registering user:', error.message);
      throw error;
    }
  };

  const logout = () => {
    setCurrentUser(null);
    localStorage.removeItem('currentUser');
  };

  const updateUser = async (userId, email, password, role, credits) => {
    try {
      await updateUserInDb(userId, email, password, role, credits);
      // Optionally, update the local state if needed
      // e.g., refresh currentUser data
    } catch (error) {
      console.error('Error updating user in context:', error.message);
      throw error;
    }
  };
  

  return (
    <AuthContext.Provider value={{ currentUser, login, register , logout,updateUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
