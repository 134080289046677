import React, { useState, useEffect, useRef } from "react";
import { Image as KonvaImage } from "react-konva";
import QRCode from "qrcode";
import configs from "../../lib/config";

const QrElement = ({ attrs, url, elementProps, isWhiteLabeled }) => {
	const qrImageRef = useRef(null);
	const [qrImage, setQrImage] = useState(null);
	const logoRatio = 4;

	const gmcLogoUrl =  configs.logowithback;
	const blankImageUrl = 'https://gpnmjenofbfeawopmhkj.supabase.co/storage/v1/object/public/public/gmc_files/blank.png?';
	let displayLogoOnQr = false;
	let logoImgUrl = blankImageUrl;

	if (elementProps && elementProps['gmcLogo']) {
		logoImgUrl = gmcLogoUrl;
		displayLogoOnQr = true;
	}
	
	if (elementProps && elementProps['customLogo'] && typeof elementProps['customLogoUrl'] === 'string' && elementProps['customLogoUrl'].length > 0) {
		logoImgUrl = elementProps['customLogoUrl'];
		displayLogoOnQr = true;
	}

	if (!isWhiteLabeled) {
		logoImgUrl = gmcLogoUrl;
		displayLogoOnQr = true;
	}
	
	const logoImg = new Image();
	// Load the logo image with cross-origin to handle potential CORS issues
	logoImg.crossOrigin = "Anonymous";
	logoImg.src = logoImgUrl;

	// Function to load the QR image asynchronously
	const loadImage = async () => {
		let image = new Image();
		image.crossOrigin = "Anonymous";
		let data = await QRCode.toDataURL(url, {
			errorCorrectionLevel: "H",
			scale: 6,
		});
		image.src = data;
		qrImageRef.current = image;
		qrImageRef.current.addEventListener("load", () => {
			setQrImage(qrImageRef.current);
		});
	};

	// Effect to load the QR image whenever the URL changes
	useEffect(() => {
		loadImage();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [url]);

	return (
		<>
			{qrImage && (
				<KonvaImage 
					{...attrs} 
					height={attrs.size} 
					width={attrs.size} 
					image={qrImage} 
				/>
			)}
			{displayLogoOnQr && (
				<KonvaImage
					height={attrs.size / logoRatio}
					width={attrs.size / logoRatio}
					image={logoImg}
					x={attrs.x + (attrs.size * (1 - 1 / logoRatio)) / 2}
					y={attrs.y + (attrs.size * (1 - 1 / logoRatio)) / 2}
				/>
			)}
		</>
	);
};

export default QrElement;
