import React, { useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Button, Drawer, Form, Input, Select, Space, Tooltip } from 'antd';
import { updateCertificate } from '../lib/db';

const { Option } = Select;

const EditCertificateJob = ({ data,certificateKey, onUpdate }) => {
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();
 
    const showDrawer = () => {
        setOpen(true);
        // Set initial form values
        form.setFieldsValue({
            name: data.extra_metadata.name,
            email: data.extra_metadata.email,
            type: data.type,
        });
    };

    const onClose = () => {
        setOpen(false);
    };

    const handleFormSubmit = (values) => {
        // Construct the updated data JSON object
        const updatedData = {
            ...data, // Copy the original data
            extra_metadata: {
                ...data.extra_metadata,
                name: values.name,
                email: values.email,
            },
            recipient_email:values.email,
            recipient_name:values.name,
            type: values.type,
        };

        const updateData = async(updatedData) =>{
            
            await updateCertificate(certificateKey,data.key,updatedData);
            onUpdate(updatedData);
            onClose();
        }
        updateData(updatedData);

        
    };

    return (
        <>
            <Tooltip title="Edit" onClick={showDrawer}>
                <Button shape="circle" icon={<EditOutlined />} />
            </Tooltip>

            <Drawer
                title={`Edit Certificate: ${data.id}`}
                width={480}
                onClose={onClose}
                open={open}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={() => form.submit()} type="primary">
                            Submit
                        </Button>
                    </Space>
                }
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleFormSubmit}
                >
                    <Form.Item
                        name="name"
                        label="Name"
                        rules={[{ required: true, message: 'Please enter the name' }]}
                    >
                        <Input placeholder="Enter recipient's name" />
                    </Form.Item>

                    <Form.Item
                        name="email"
                        label="Email"
                        rules={[
                            { required: true, message: 'Please enter the email' },
                            { type: 'email', message: 'Please enter a valid email address' },
                        ]}
                    >
                        <Input placeholder="Enter recipient's email" />
                    </Form.Item>

                    <Form.Item
                        name="type"
                        label="Certificate Type"
                        rules={[{ required: true, message: 'Please select the type' }]}
                    >
                        <Select
                        placeholder="Select certificate type" 
                        options={[
                            {
                                label: "Certificate Type Select...",
                                value: ""
                            },
                            {
                                label: "Participant",
                                value: "Participant"
                            },
                            {
                                label: "Winner",
                                value: "Winner"
                            },
                            {
                                label: "Coordinator",
                                value: "Coordinator"
                            },
                            {
                                label: "Organizer",
                                value: "Organizer"
                            },
                            {
                                label: "Volunteer",
                                value: "Volunteer"
                            },
                            {
                                label: "Ambassador",
                                value: "Ambassador"
                            },
                            {
                                label: "Speaker",
                                value: "Speaker"
                            },
                            {
                                label: "Mentor",
                                value: "Mentor"
                            },
                            {
                                label: "Judge",
                                value: "Judge"
                            },
                            {
                                label: "Award",
                                value: "Award"
                            },
                            {
                                label: "Achievement",
                                value: "Achievement"
                            },
                            {
                                label: "Completion",
                                value: "Completion"
                            },
                            {
                                label: "Experience",
                                value: "Experience"
                            },
                            {
                                label: "Grade",
                                value: "Grade"
                            },
                            {
                                label: "Offer letter",
                                value: "Offer letter"
                            },
                            {
                                label: "Other",
                                value: "Other"
                            }
                        ]} />
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    );
};

export default EditCertificateJob;
