import React from 'react';
import { Text, Image as KonvaImage } from 'react-konva';
import QrElement from './elements/QrElement';

const DrawingSheet = ({ elementsData, imageElementsHolder, isWhiteLabeled }) => {
  return (
    <>
      {Object.keys(elementsData).map((key) => {
        const element = elementsData[key];
        const { data, type } = element;
           console.log(type);
           
        // Conditionally render based on the element type
        if (type === 'text') {
          return (
            <Text
              key={key}
              x={data.x}
              y={data.y}
              fontSize={data.fontSize}
              fill={data.fill}
              text={data.text}
              width={data.width}
              height={data.height}
            />
          );
        } else if (type === 'image') {
          console.log(imageElementsHolder);
          
          return (
            <KonvaImage
              key={key}
              x={data.x}
              y={data.y}
              width={data.width}
              height={data.height}
              image={imageElementsHolder[key] ? imageElementsHolder[key] : null}
              alt={imageElementsHolder[key]?.alt || 'Image Element'}
            />
          );
        }
        else if (type === 'qr') {
         
          
          return (
            <QrElement key={key} elementProps={element} url={element.data.url} attrs={{ ...element.data, id: key }} isWhiteLabeled={isWhiteLabeled} />
          );
        }
         else if (data.name === 'image') {
          console.log(imageElementsHolder);
          
          return (
            <KonvaImage
              key={key}
              x={data.x}
              y={data.y}
              width={data.width}
              height={data.height}
              image={imageElementsHolder[key] ? new window.Image(imageElementsHolder[key].src) : null}
              alt={imageElementsHolder[key]?.alt || 'Image Element'}
            />
          );
        }

        return null;
      })}
    </>
  );
};

export default DrawingSheet;
