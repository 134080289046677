import React, { useState, useRef, useEffect } from 'react';
import { Stage, Layer, Image as KonvaImage, Text, Transformer, Rect } from 'react-konva';
import { InboxOutlined, DeleteOutlined, FontSizeOutlined, FileImageOutlined, SaveOutlined } from '@ant-design/icons';
import { Upload, Modal, Input, Button, InputNumber, Radio, Popconfirm, App, Select } from 'antd';
import Konva from 'konva';
import { FiAlignCenter, FiAlignLeft, FiAlignRight } from 'react-icons/fi';
import { FaBold, FaItalic, FaUnderline } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { createCertificateTemplates, getTemplateByUserIdAndTemplateId } from '../lib/db';
import { storage } from '../lib/firebase';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';

const { Dragger } = Upload;

const CertificateCanvas = () => {
  const [uploadedImage, setUploadedImage] = useState(null);
  const [image, setImage] = useState(null);
  const { message } = App.useApp();
  const [canvasSize, setCanvasSize] = useState({ width: 0, height: 0 });
  const [uploadedImageSize, setUploadedImageSize] = useState({ width: 0, height: 0 });
  const [SelectedShape, setSelectedShape] = useState(null);
  const [qrCodeAdded, setQrcodeAdded] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalType, setModalType] = useState('');
  const [verifylinkclick, setVerifyLinkClick] = useState('');
  const [elementName, setElementName] = useState('');
  const [elements, setElements] = useState({});
  const [template, setTemplate] = useState(null);
  const [fileObject, setFileObject] = useState(null);
  const [saveStart, setSaveStart] = useState(false);
  const [textProps, setTextProps] = useState({
    text: '',
    fontSize: 20,
    fill: '#000000',
    align: 'left',
    fontStyle: '',
    textDecoration: '',
  });
  const [imageProps, setImageProps] = useState({
    width: 100,
    height: 100,
  });
  const stageRef = useRef(null);
  const containerRef = useRef(null);
  const transformerRef = useRef(null);
  const currentUser = localStorage.getItem('currentUser');
  const { data: userData, timestamp } = JSON.parse(currentUser);
  const immortalNames = [
    'verificationlink', 'fullname', 'qrcode'
  ];

  const { id: templateId } = useParams();

  const props = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    accept: 'image/*',
    beforeUpload: (file) => {
      const reader = new FileReader();
      reader.onload = () => {
        setUploadedImage(reader.result);
      };
      reader.readAsDataURL(file);
      setFileObject(file);
      return false; // Prevent upload
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };


  const updateCanvasSize = (img) => {
    const container = containerRef.current;
    const containerStyle = window.getComputedStyle(container);
    const containerWidth = container.clientWidth - parseFloat(containerStyle.paddingLeft) - parseFloat(containerStyle.paddingRight);
    const containerHeight = container.clientHeight - parseFloat(containerStyle.paddingTop) - parseFloat(containerStyle.paddingBottom);
    const aspectRatio = img.width / img.height;
    let newWidth, newHeight;

    if (containerWidth / containerHeight < aspectRatio) {
      newWidth = containerWidth;
      newHeight = containerWidth / aspectRatio;
    } else {
      newHeight = containerHeight;
      newWidth = containerHeight * aspectRatio;
    }

    setUploadedImageSize({ width: img.width, height: img.height });
    setCanvasSize({ width: newWidth, height: newHeight });
  };

  useEffect(() => {
    if (uploadedImage) {
      const img = new window.Image();
      img.src = uploadedImage;
      img.onload = () => {
        setImage(img);
        // Use setTimeout to delay the execution
        setTimeout(() => {
          updateCanvasSize(img);
        }, 100);
      };
    }
  }, [uploadedImage]);

  useEffect(() => {
    const handleResize = () => {
      if (image) {
        updateCanvasSize(image);
      }
    };

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [image]);


  useEffect(() => {
    const fetchTemplate = async () => {
      const userId = userData.id;
      try {
        const templateData = await getTemplateByUserIdAndTemplateId(userId, templateId);

        setTemplate(templateData);
      } catch (error) {
        console.error('Error fetching template:', error);
      }
    };

    if (templateId) {
      fetchTemplate();
    }
  }, [templateId]);

  useEffect(() => {
    if (stageRef.current != null && qrCodeAdded === false && canvasSize.width > 0 && canvasSize.height > 0) {
      addImageNode('qrcode');
      addTextNode('fullname', 'Full Name');
      addVerificationNode('verificationlink');
      setQrcodeAdded(true);
    }
  }, [canvasSize, qrCodeAdded]);

  const handleAddText = () => {
    setModalType('text');
    setModalVisible(true);
  };

  const handleInsertImage = () => {
    setModalType('image');
    setModalVisible(true);
  };

  const handleModalOk = () => {
    if (modalType === 'text') {
      addTextNode(elementName);
    } else if (modalType === 'image') {
      addImageNode(elementName);
    }
    setModalVisible(false);
    setElementName('');
  };

  const handleModalCancel = () => {
    setModalVisible(false);
    setElementName('');
  };

  const addTextNode = (name, textvalue = 'Double click to edit') => {
    const stage = stageRef.current.getStage();
    const layer = stage.children[0];
    const textNode = new Konva.Text({
      x: 50,
      y: 60,
      fontSize: 20,
      text: textvalue,
      name: name,
      draggable: true,
      dragBoundFunc: function (pos) {
        const newX = Math.max(0, Math.min(canvasSize.width - textNode.width(), pos.x));
        const newY = Math.max(0, Math.min(canvasSize.height - textNode.height(), pos.y));
        return { x: newX, y: newY };
      },
    });

    layer.add(textNode);

    const tr = new Konva.Transformer({
      node: textNode,
      padding: 5,
      flipEnabled: false,
      enabledAnchors: ['middle-left', 'middle-right'],
      boundBoxFunc: (oldBox, newBox) => {
        if (Math.abs(newBox.width) < 20) {
          return oldBox;
        }
        return newBox;
      },
    });

    layer.add(tr);
    if (transformerRef != null && transformerRef.current != null) {
      transformerRef.current.nodes([]);
    }
    transformerRef.current = tr;
    textNode.on('transform', () => {
      textNode.width(Math.max(textNode.width() * textNode.scaleX(), 20));
      textNode.scaleX(1);
      textNode.scaleY(1);
    });

    textNode.on('dblclick', () => {
      const textPosition = textNode.absolutePosition();
      const stageBox = stage.container().getBoundingClientRect();

      const areaPosition = {
        x: stageBox.left + textPosition.x,
        y: stageBox.top + textPosition.y,
      };

      const textarea = document.createElement('textarea');
      document.body.appendChild(textarea);

      textarea.value = textNode.text();
      textarea.style.position = 'absolute';
      textarea.style.top = `${areaPosition.y}px`;
      textarea.style.left = `${areaPosition.x}px`;
      textarea.style.width = `${textNode.width()}px`;

      textarea.focus();

      const removeTextarea = () => {
        textarea.removeEventListener('blur', removeTextarea);
        document.body.removeChild(textarea);
        textNode.text(textarea.value);
        layer.batchDraw();
      };

      textarea.addEventListener('blur', removeTextarea);
    });

    stage.on('click', (e) => {
      if (e.target === stage) {
        transformerRef.current.nodes([]);
        layer.draw();
      }
    });
    setSelectedShape(textNode);
    setVerifyLinkClick(false);
    // Select transformer when clicking on the text node
    textNode.on('click', () => {
      transformerRef.current.nodes([textNode]);
      setSelectedShape(textNode);
      setVerifyLinkClick(false);
      layer.draw();
      setTextProps({
        text: textNode.text(),
        fontSize: textNode.fontSize(),
        fill: textNode.fill(),
        align: textNode.align(),
        fontStyle: textNode.fontStyle(),
        textDecoration: textNode.textDecoration(),
      });
    });

    textNode.on('dragmove', () => {
      // Update state or perform any additional actions on drag
      setElements(prevElements => ({
        ...prevElements,
        [name]: {
          ...prevElements[name],
          data: {
            ...prevElements[name].data,
            x: textNode.x(),
            y: textNode.y(),
          }
        }
      }));
    });

    setElements(prevElements => ({
      ...prevElements,
      [name]: {
        type: 'text',
        data: {
          x: textNode.x(),
          y: textNode.y(),
          fill: textNode.fill(),
          text: textNode.text(),
          fontSize: textNode.fontSize(),
          id: name,
          stroke: textNode.stroke() ?? "",
          strokeWidth: textNode.strokeWidth(),
          width: textNode.width(),
        },
        header: name,
        required: true,
        initialDisplay: true,
      }
    }));

    layer.draw();
  };
  const addVerificationNode = (name) => {
    const stage = stageRef.current.getStage();
    const layer = stage.children[0];
    const textNode = new Konva.Text({
      x: 50,
      y: 60,
      fontSize: 10,
      name: name,
      text: 'Authenticity of documents can be verified at https://mycertificate.in/verify/xxxxxxxxx',
      draggable: true,
      dragBoundFunc: function (pos) {
        const newX = Math.max(0, Math.min(canvasSize.width - textNode.width(), pos.x));
        const newY = Math.max(0, Math.min(canvasSize.height - textNode.height(), pos.y));
        return { x: newX, y: newY };
      },
    });

    layer.add(textNode);

    const tr = new Konva.Transformer({
      node: textNode,
      padding: 5,
      flipEnabled: false,
      enabledAnchors: ['middle-left', 'middle-right'],
      boundBoxFunc: (oldBox, newBox) => {
        if (Math.abs(newBox.width) < 20) {
          return oldBox;
        }
        return newBox;
      },
    });

    layer.add(tr);
    if (transformerRef != null && transformerRef.current != null) {
      transformerRef.current.nodes([]);
    }
    transformerRef.current = tr;
    textNode.on('transform', () => {
      textNode.width(Math.max(textNode.width() * textNode.scaleX(), 20));
      textNode.scaleX(1);
      textNode.scaleY(1);
    });

    stage.on('click', (e) => {
      if (e.target === stage) {
        transformerRef.current.nodes([]);
        layer.draw();
      }
    });
    setSelectedShape(textNode);
    setVerifyLinkClick(true);
    // Select transformer when clicking on the text node
    textNode.on('click', () => {
      transformerRef.current.nodes([textNode]);
      setSelectedShape(textNode);
      
      setVerifyLinkClick(true);
      layer.draw();
      setTextProps({
        text: textNode.text(),
        fontSize: textNode.fontSize(),
        fill: textNode.fill(),
        align: textNode.align(),
        fontStyle: textNode.fontStyle(),
        textDecoration: textNode.textDecoration(),
      });
    });

    textNode.on('dragmove', () => {
      // Update state or perform any additional actions on drag
      setElements(prevElements => ({
        ...prevElements,
        [name]: {
          ...prevElements[name],
          data: {
            ...prevElements[name].data,
            x: textNode.x(),
            y: textNode.y(),
          }
        }
      }));
    });



    setElements(prevElements => ({
      ...prevElements,
      [name]: {
        type: 'text',
        data: {
          x: textNode.x(),
          y: textNode.y(),
          fill: textNode.fill(),
          text: textNode.text(),
          fontSize: textNode.fontSize(),
          id: name,
          stroke: textNode.stroke() ?? "",
          strokeWidth: textNode.strokeWidth(),
          width: textNode.width(),
        },
        header: name,
        required: false,
        initialDisplay: true,
      }
    }));

    layer.draw();
  };

  const handleStyleChange = (style) => {
    const newFontStyle = textProps.fontStyle.includes(style)
      ? textProps.fontStyle.replace(style, '').trim()
      : `${textProps.fontStyle} ${style}`.trim();
    setTextProps({
      ...textProps,
      fontStyle: newFontStyle,
    });
    if (SelectedShape) {
      SelectedShape.fontStyle(newFontStyle);
    }
  };

  const handleWidthChange = (value) => {
    setImageProps({
      ...imageProps,
      width: value,
    });
    if (SelectedShape) {
      SelectedShape.width(value);
    }
  };
  const handleqrSizeChange = (value) => {
    setImageProps({
      ...imageProps,
      height:value,
      width: value,
    });
    if (SelectedShape) {
      SelectedShape.width(value);
      SelectedShape.height(value);
    }
  };
  const handleAlignChange = (e) => {
    const align = e.target.value;
    setTextProps({
      ...textProps,
      align,
    });
    if (SelectedShape) {
      SelectedShape.align(align);
    }
  };

  const handleHeightChange = (value) => {
    setImageProps({
      ...imageProps,
      height: value,
    });
    if (SelectedShape) {
      SelectedShape.height(value);
    }
  };

  const addImageNode = (name) => {
    const stage = stageRef.current.getStage();
    const layer = stage.children[0];
    const imgNode = new Konva.Image({
      x: 50,
      y: 60,
      image: new window.Image(),
      draggable: true,
      width: 100,
      name: name,
      height: 100,
      dragBoundFunc: function (pos) {
        const newX = Math.max(0, Math.min(canvasSize.width - imgNode.width(), pos.x));
        const newY = Math.max(0, Math.min(canvasSize.height - imgNode.height(), pos.y));
        return { x: newX, y: newY };
      }
    });

    if (name === 'qrcode') {
      imgNode.image().src = '/img/verify.png';
      imgNode.className = 'qr';
    } else {
      imgNode.image().src = '/img/placeholder.png';
    }
    layer.add(imgNode);

    const tr = new Konva.Transformer({
      node: imgNode,
      padding: 5,
      flipEnabled: false,
      enabledAnchors: [
        'top-left',
        'top-center',
        'top-right',
        'middle-left',
        'middle-right',
        'bottom-left',
        'bottom-center',
        'bottom-right'
      ],
      boundBoxFunc: (oldBox, newBox) => {
        if (Math.abs(newBox.width) < 20 || Math.abs(newBox.height) < 20) {
          return oldBox;
        }
        return newBox;
      },
    });

    layer.add(tr);

    if (transformerRef != null && transformerRef.current != null) {
      transformerRef.current.nodes([]);
    }


    imgNode.on('transform', () => {
      imgNode.width(Math.max(imgNode.width() * imgNode.scaleX(), 20));
      imgNode.height(Math.max(imgNode.height() * imgNode.scaleY(), 20));
      imgNode.scaleX(1);
      imgNode.scaleY(1);
    });

    imgNode.on('dragmove', () => {
      // Update state or perform any additional actions on drag
      setElements(prevElements => ({
        ...prevElements,
        [name]: {
          ...prevElements[name],
          data: {
            ...prevElements[name].data,
            x: imgNode.x(),
            y: imgNode.y(),
          }
        }
      }));
    });



    setSelectedShape(imgNode);

    transformerRef.current = tr;

    imgNode.on('click', () => {
      setVerifyLinkClick(false);
      transformerRef.current.nodes([imgNode]);
      setSelectedShape(imgNode);
      layer.draw();
    });





    setElements(prevElements => ({
      ...prevElements,
      [name]: {
        type: name == 'qrcode' ? 'qr':'image' ,
        data: {
          x: imgNode.x(),
          y: imgNode.y(),
          id: name,
          strokeWidth: imgNode.strokeWidth(),
          stroke: imgNode.stroke() ?? "",
          ...(name === 'qrcode' 
            ? { size: imgNode.width() } 
            : { height: imgNode.height(), width: imgNode.width(), url: imgNode.image().src })
        },
        required: name === 'qrcode' ? false : true
      }
    }));

    transformerRef.current.nodes([imgNode]);
    layer.draw();
  };

  const handleImageUpload = (canvasimg) => {
    return new Promise((resolve, reject) => {
      const storageRef = ref(storage, `images/${canvasimg.name}`);
      const uploadTask = uploadBytesResumable(storageRef, canvasimg);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Select.Optional: Track the upload progress
        },
        (error) => {
          message.error('Upload failed!');
          reject(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            resolve(downloadURL);
          }).catch((error) => {
            reject(error);
          });
        }
      );
    });
  };




  const handleSave = async () => {
    try {
      setSaveStart(true);
      const imageUrl = await handleImageUpload(fileObject);
      const templatesdata = template;

      if (imageUrl) {

        const finalJson = {
          user_id: userData.id,
          id: templateId,
          name: templatesdata.name,
          image_url: imageUrl,
          config: {
            name: templatesdata.name,
            image_url: imageUrl,
            rendered_dimensions: {
              height: canvasSize.height,
              width: canvasSize.width,
            },
            actual_dimensions: {
              height: uploadedImageSize.height,
              width: uploadedImageSize.width,
            },
          },
          elements: elements,
        };

        const newTemplate = {
          ...templatesdata,
          templatedesign: finalJson
        };
        const sanitizedValues = Object.fromEntries(
          Object.entries(newTemplate).map(([key, value]) => [key, value !== undefined ? value : ""])
        );
        const insertData = async (id, data) => {
          try {
            await createCertificateTemplates(id, data);

            message.success('Template Created Successfully');

          }
          catch (error) {
            console.log(error);
          }
        }

        insertData(templatesdata?.key, sanitizedValues);
      }
      setSaveStart(false);
    } catch (error) {
      console.error('Error saving template:', error);
    }

  };
  const updateElementState = (id, newData) => {
    setElements((prevElements) => ({
      ...prevElements,
      [id]: {
        ...prevElements[id],
        data: {
          ...prevElements[id].data,
          ...newData,
        },
      },
    }));
  };

  const handleStageClick = (e) => {
    if (e.target === e.target.getStage() || e.target.name() === 'backgroundRect' || e.target === image || e.target.name() === 'backgroundImg') {
      transformerRef.current.nodes([]);
      stageRef.current.getStage().batchDraw();
      setSelectedShape(null);
      setVerifyLinkClick(false);
    }
  };
  const handleFontFamilyChange = (value) => {
    setTextProps({
      ...textProps,
      fontFamily: value,
    });

    if (SelectedShape) {
      SelectedShape.fontFamily(value);
      SelectedShape.getLayer().batchDraw();

      updateElementState(SelectedShape.name(), { fontFamily: value });
    }
  };

  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setTextProps({
      ...textProps,
      [name]: value,
    });
    SelectedShape.text(value);
    SelectedShape.getLayer().batchDraw();
    updateElementState(SelectedShape.name(), { text: value });
  };

  const handleFontSizeChange = (value) => {
    setTextProps({
      ...textProps,
      fontSize: value,
    });
    SelectedShape.fontSize(value);
    SelectedShape.getLayer().batchDraw();
    updateElementState(SelectedShape.name(), { fontSize: value });
  };

  const handleColorChange = (e) => {
    const { value } = e.target;
    setTextProps({
      ...textProps,
      fill: value,
    });
    SelectedShape.fill(value);
    SelectedShape.getLayer().batchDraw();
    updateElementState(SelectedShape.name(), { fill: value });
  };

  const handleAlignmentChange = (e) => {
    const { value } = e.target;
    setTextProps({
      ...textProps,
      align: value,
    });
    SelectedShape.align(value);
    SelectedShape.getLayer().batchDraw();
    updateElementState(SelectedShape.name(), { align: value });
  };

  const handleFontStyleChange = (value) => {
    setTextProps({
      ...textProps,
      fontStyle: value,
    });
    SelectedShape.fontStyle(value);
    SelectedShape.getLayer().batchDraw();
    updateElementState(SelectedShape.name(), { fontStyle: value });
  };

  const handleTextDecorationChange = () => {
    const isUnderlined = textProps.textDecoration.includes('underline');
    const newTextDecoration = isUnderlined
      ? textProps.textDecoration.replace('underline', '').trim()
      : `${textProps.textDecoration} underline`.trim();
    setTextProps({
      ...textProps,
      textDecoration: newTextDecoration,
    });
    if (SelectedShape) {
      SelectedShape.textDecoration(newTextDecoration);
      SelectedShape.getLayer().batchDraw();

      updateElementState(SelectedShape.name(), { textDecoration: newTextDecoration });
    }
  };

  const handleDelete = () => {
    console.log(SelectedShape);
    
    if (SelectedShape) {
      SelectedShape.remove();
      setSelectedShape(null);
      if (transformerRef.current) {
        transformerRef.current.nodes([]);
        transformerRef.current.getLayer().batchDraw();
      }
    }
  };


  const shouldHideButton = (SelectedShape && immortalNames.includes(SelectedShape.name()));


  return (
    <div>
      {!uploadedImage ? (
        <div className='bg-white rounded-lg p-4 py-9 h-full'>
          <h1 className='py-3 font-bold text-xl'>Add Background Image</h1>
          <Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <p className="ant-upload-hint">Upload Only Image</p>
          </Dragger>
        </div>
      ) : (
        <>
          <div className='flex md:flex-row flex-col min-h-screen'>
            <div className="md:w-9/12 w-full h-full">
              <div ref={containerRef} className="p-3 flex min-h-[80vh] flex-col h-full">
                <Stage
                  width={canvasSize.width}
                  height={canvasSize.height}
                  ref={stageRef}
                  onMouseDown={handleStageClick}
                >
                  <Layer className="layer">
                    <Rect
                      name="backgroundRect"
                      width={canvasSize.width}
                      height={canvasSize.height}
                      fill="white"
                    />
                    <KonvaImage name="backgroundImg" image={image} width={canvasSize.width} height={canvasSize.height} />

                  </Layer>
                </Stage>
              </div>
              <div className="bg-white p-3 w-fit rounded-lg md:mx-0 mx-auto">
                <Button onClick={handleAddText} icon={<FontSizeOutlined />} type="primary" style={{ marginRight: '10px' }}>Add Text</Button>
                <Button onClick={handleInsertImage} icon={<FileImageOutlined />} type="primary" style={{ marginRight: '10px' }}>Insert Image</Button>
                <Button onClick={handleSave} loading={saveStart} disabled={saveStart} icon={<SaveOutlined />} type="primary">Save</Button>
              </div>
            </div>
            <div className='md:w-3/12 w-full '>
              {SelectedShape && SelectedShape.className === 'Text' && (
                <div style={{ marginTop: '20px' }} className='bg-white p-3 rounded-lg'>
                  <h1 className='text-xl text-center py-3 font-bold'>{SelectedShape && SelectedShape.name()}</h1>
                  {!verifylinkclick && (
                    <>
                      <label className='font-bold'>Text Value:</label>
                      <Input
                        name="text"
                        value={textProps.text}
                        className='my-3'
                        onChange={handleTextChange}
                        placeholder="Text"
                      />
                    </>
                  )}
                  <label className='font-bold'>Font Size/Color:</label><br />
                  <div className="flex gap-3 my-3">
                    <InputNumber
                      name="fontSize"
                      value={textProps.fontSize}
                      onChange={handleFontSizeChange}
                      placeholder="Font Size"
                      min={3}
                      className='w-1/2'
                      max={100}
                    />
                    <Input
                      name="fill"
                      type="color"
                      value={textProps.fill}
                      onChange={handleColorChange}
                      placeholder="Color"
                      className='w-1/2'
                    />
                  </div>
                  <label className='font-bold'>Font Family:</label><br />
                  <div className="flex gap-3 w-full my-3">

                    <Select className='w-full' onChange={handleFontFamilyChange} value={textProps.fontFamily ?? "Arial"}>
                      <Select.Option value="Arial" >Arial</Select.Option>
                      <Select.Option value="Helvetica">Helvetica</Select.Option>
                      <Select.Option value="Times New Roman">Times New Roman</Select.Option>
                      <Select.Option value="Courier New">Courier New</Select.Option>
                      <Select.Option value="Georgia">Georgia</Select.Option>
                      <Select.Option value="Verdana">Verdana</Select.Option>
                      {/* Add more font Select.Options as needed */}
                    </Select>
                  </div>
                  <label className='font-bold'>Text Alignment:</label><br />

                  <div className="flex justify-center items-center">
                    <Radio.Group className='my-3 flex items-center justify-center' onChange={handleAlignmentChange} value={textProps.align}>
                      <Radio.Button className='flex justify-center items-center' value="left"><FiAlignLeft /></Radio.Button>
                      <Radio.Button className='flex justify-center items-center' value="center"><FiAlignCenter /></Radio.Button>
                      <Radio.Button className='flex justify-center items-center' value="right"><FiAlignRight /></Radio.Button>
                    </Radio.Group>
                  </div>
                  <label className='font-bold'>Text Decoration:</label><br />
                  <div className="flex my-3 justify-center items-center">
                    <Button.Group>
                      <Button type="default" onClick={() => handleFontStyleChange('bold')}><FaBold /></Button>
                      <Button type="default" onClick={() => handleFontStyleChange('italic')}><FaItalic /></Button>
                      <Button onClick={handleTextDecorationChange}><FaUnderline /></Button>
                    </Button.Group>
                  </div>
                  {!shouldHideButton &&
                    (<div className="my-3 flex justify-center items-center w-full">
                      <Popconfirm
                        title="Delete the Element"
                        description="Are you sure to delete this element?"
                        onConfirm={handleDelete}
                        okText="Yes"
                        showCancel={false}
                      >
                        <Button value={"Delete"} icon={<DeleteOutlined />} danger>Delete</Button>

                      </Popconfirm>


                    </div>)
                  }
                </div>
              )}
              {SelectedShape && SelectedShape.className === 'Image' && (
                <div className='bg-white p-3 rounded-lg my-10'>
                  <h1 className='text-xl text-center py-3 font-bold'>{SelectedShape && SelectedShape.name()}</h1>
                  <div className="py-3">
                    <label className='font-bold'>Image Width:</label><br />
                    <InputNumber min={10} value={imageProps.width} onChange={handleWidthChange} />
                  </div>
                  <div className="py-3">
                    <label className='font-bold'>Image Height:</label><br />
                    <InputNumber min={10} value={imageProps.height} onChange={handleHeightChange} />
                  </div>

                  {!shouldHideButton &&
                    (<div className="my-3 flex justify-center items-center w-full">
                      <Popconfirm
                        title="Delete the Element"
                        description="Are you sure to delete this element?"
                        onConfirm={handleDelete}
                        okText="Yes"
                        showCancel={false}
                      >
                        <Button value={"Delete"} icon={<DeleteOutlined />} danger>Delete</Button>

                      </Popconfirm>


                    </div>)
                  }
                </div>
              )}
              {SelectedShape && SelectedShape.className === 'qr' && (
                <div className='bg-white p-3 rounded-lg my-10'>
                  <h1 className='text-xl text-center py-3 font-bold'>{SelectedShape && SelectedShape.name()}</h1>
                  <div className="py-3">
                    <label className='font-bold'>Size:</label><br />
                    <InputNumber min={10} value={imageProps.width} max={300} onChange={handleqrSizeChange} />
                  </div>
                </div>
              )}
              {!SelectedShape && <div className="w-full h-[75%] flex justify-center items-center flex-col">
                <h3>No Elements Selected</h3>
                <p>Click on any element to edit its properties.</p>
              </div>
              }

            </div>
          </div>

        </>
      )}

      <Modal
        title={`Add ${modalType === 'text' ? 'Text' : 'Image'} Element`}
        open={modalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        <Input placeholder="Element Header Name" value={elementName} onChange={(e) => setElementName(e.target.value)} />
      </Modal>
    </div>
  );
};

export default CertificateCanvas;
