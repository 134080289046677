import React, { useEffect, useState } from 'react'
import PreviewCertificate from '../components/PreviewCertificate';
import { useParams } from 'react-router-dom';
import { getCertificateData, getCertificateDetailData, getTemplateData } from '../lib/db';
import configs from '../lib/config'
import { Result } from 'antd';

const CertificateDisplay = () => {

  const [elementsData, setElementsData] = useState(null);
  const [configData, setConfigData] = useState(null);
  const [certificateData, setCertificateData] = useState(null);
  const [bg, setBg] = useState(null);
  const [imageLeftToRender, setImageLeftToRender] = useState(0);
  const [imageElementsHolder, setImageElementsHolder] = useState({});
  const [nodata, setNodata] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);



  const { id: certificateid } = useParams();

  useEffect(() => {

    const findCertificateData = async () => {
      const certificateDetails = await getCertificateDetailData(certificateid);
      console.log(certificateDetails);

      if (certificateDetails) {
        const key = Object.keys(certificateDetails)[0];
        const jobId = certificateDetails[key].job_id;
        const index = certificateDetails[key].index;
        const recipient_id = certificateDetails[key].recipient_id;
        const certificatekey = certificateDetails[key].certificate_key;
        const certificatedata = await getCertificateData(certificatekey, index);
        if (certificatedata) {
          const templateData = await getTemplateData(certificatedata.template_key);
          setElementsData(templateData.templatedesign.elements);
          // setElementsData({
          //   "name": {
          //     "data": {
          //       "x": 349.95342338146247,
          //       "y": 86.98086668735132,
          //       "id": "name",
          //       "fill": "#f4dede",
          //       "text": "Full Name",
          //       "width": 91.1328125,
          //       "stroke": "#42adf5",
          //       "fontSize": 20,
          //       "strokeWidth": 0
          //     },
          //     "type": "text",
          //     "header": "name",
          //     "required": true,
          //     "initialDisplay": true
          //   },
          //   "gmc_qr": {
          //     "auto": true,
          //     "data": {
          //       "x": 16.997360658282872,
          //       "y": 332.82780018616194,
          //       "id": "qrcode",
          //       "size": 100,
          //       "stroke": "#42adf5",
          //       "strokeWidth": 0
          //     },
          //     "type": "qr",
          //     "header": "gmc_qr",
          //     "gmcLogo": true,
          //     "required": true,
          //     "initialDisplay": true
          //   },
          //   "gmc_link": {
          //     "auto": true,
          //     "data": {
          //       "x": 378.52246897317957,
          //       "y": 432.82780018616194,
          //       "id": "gmc_link",
          //       "fill": "#ffffff",
          //       "text": "Authenticity of documents can be verified at https://givemycertificate.com/verify/xxxxxxxxxxxx",
          //       "width": 411.8212890625,
          //       "stroke": "#42adf5",
          //       "fontSize": 10,
          //       "strokeWidth": 0
          //     },
          //     "type": "text",
          //     "header": "gmc_link",
          //     "required": true,
          //     "initialDisplay": true
          //   }
          // });
          setBg(templateData.templatedesign.config.image_url)
          // setBg("http://localhost:3000/img/bg.png")
          setConfigData(templateData.templatedesign.config)
          // setConfigData({
          //   "name": "template test",
          //   "image_url": "http://localhost:3000/img/bg.png",
          //   "actual_dimensions": {
          //     "width": 1112,
          //     "height": 626
          //   },
          //   "rendered_dimensions": {
          //     "width": 805.125,
          //     "height": 453.24482913669067
          //   }
          // })
          setCertificateData(certificatedata)
          // console.log(templateData,certificatedata.template_key,certificatedata);
        }
      } else {
        setNodata(true);
      }

    }
    findCertificateData();
  }, [certificateid]);
  useEffect(() => {
    if (certificateData) {


      let imageObjectsHolder = [];
      if (elementsData && elementsData) {
        Object.keys(elementsData).forEach((key, index) => {
          if (key === "verificationlink") {
            elementsData[key].data.text = `Verify at ${configs.verify_url}${certificateData.id}`;
          }
          else if (key === "fullname") {
            elementsData[key].data.text = certificateData?.extra_metadata["name"];
          }
          else if (key === "qrcode") {
            elementsData[key].data.url = `${configs.verify_url}${certificateData.id}`;
          } else if (key === "certificateId") {
            elementsData[key].data.text = certificateData.id;
          }else if (elementsData[key].type === "image") {
            const imageUrl = certificateData?.extra_metadata[key];
            elementsData[key].data.url = imageUrl;
          
            if (imageUrl) {
              let image = new Image();
              image.crossOrigin = "Anonymous";
              image.src = imageUrl;
              image.name = key;
              setImageElementsHolder(vals => ({
                ...vals,
                [key]: image
              }));
            
              imageObjectsHolder[key] = image;
            } else {
              console.error("Invalid image URL:", imageUrl);
            }
          }
          
           else {
            elementsData[key].data.text = certificateData?.extra_metadata[elementsData[key].header];
          }
        });
        console.log(elementsData, certificateData);

        setDataLoaded(true)
      }
    }
  }, [certificateData]);


  return (
    <div className="w-full h-screen flex justify-center items-center">
      <div className="md:w-[65%] w-10/12  h-full">
        {!nodata && <div id="preview_certificate" style={{ width: '100%', height: '100%' }}>
          {elementsData && configData && bg && dataLoaded && imageElementsHolder && certificateData &&
            <PreviewCertificate
              elementsData={elementsData}
              configData={configData}
              bg={bg}
              imageElementsHolder={imageElementsHolder}
            />}
        </div>}
        {nodata && <Result
          status="404"
          title="404"
          subTitle="Sorry, the certificate you are looking for does not exist."
        />}
      </div>
    </div>
  )
}

export default CertificateDisplay