import React, { useState } from 'react';
import { EyeOutlined } from '@ant-design/icons';
import { Button, Drawer, Space, Tooltip, Table } from 'antd';

const ShowCertificate = ({ data }) => {
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  // Define the columns for the table
  const columns = [
    {
      title: 'Field',
      dataIndex: 'field',
      key: 'field',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
    },
  ];

  // Prepare the data for the table
  const tableData = [

    {
      key: '1',
      field: 'Created At',
      value: data.created_at,
    },
    {
      key: '2',
      field: 'Metadata',
      value: JSON.stringify({
        certificateType: data.extra_metadata.certificateType,
        company: data.extra_metadata.company,
        email: data.extra_metadata.email,
        name: data.extra_metadata.name,
      }),
    },
    {
      key: '3',
      field: 'Job ID',
      value: data.job_id,
    },
    {
      key: '4',
      field: 'Recipient Email',
      value: data.recipient_email,
    },
    {
      key: '5',
      field: 'Recipient Name',
      value: data.recipient_name,
    },
    {
      key: '6',
      field: 'Type',
      value: data.type,
    },
    {
      key: '7',
      field: 'Updated At',
      value: data.updated_at,
    },
  ];

  return (
    <>
      <Tooltip title="View" onClick={showDrawer}>
        <Button shape="circle" icon={<EyeOutlined />} />
      </Tooltip>

      <Drawer
        title={`Certificate ID: ${data.id}`}
        width={720}
        onClose={onClose}
        open={open}
        styles={{
            body: {
                paddingBottom: 80,
            },
        }}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
          </Space>
        }
      >
        <Table
          columns={columns}
          dataSource={tableData}
          pagination={false}
          bordered
        />
      </Drawer>
    </>
  );
};

export default ShowCertificate;
