import React, { useEffect, useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Form, Input, Select, Button, App, Tooltip, Drawer, Space } from 'antd';
import { useAuth } from '../../context/AuthContext';

const EditUser = ({ user,onEditSuccess }) => {
    console.log(user);
    
    const [form] = Form.useForm();
    const [email, setEmail] = useState('');
    const [open, setOpen] = useState(false);
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('org-admin'); // Default role if not provided
    const [credits, setCredits] = useState('');
    const { updateUser } = useAuth(); // Assuming an updateUser function is available
    const { notification } = App.useApp();
    useEffect(() => {
        if (user) {
            setEmail(user.email || '');
            setRole(user.role || 'org-admin');
            setCredits(user.credits || '');
            setPassword(user.password || '');
        }
    }, [user]);

    const handleSubmit = async () => {
        try {
            await updateUser(user.key, email, password, role, credits); // Assuming updateUser accepts user id
            notification.success({
                message: 'Update Successful',
                description: 'User details have been updated successfully.',
            });
            onEditSuccess();
            onClose();
            // Redirect or update UI after successful update
        } catch (error) {
            notification.error({
                message: 'Update Failed',
                description: error.message,
            });
        }
    };

    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Tooltip title="Edit" onClick={showDrawer}>
                <Button shape="circle" icon={<EditOutlined />} type="primary" ghost />
            </Tooltip>
            <Drawer
                title="Edit Organization"
                width={720}
                onClose={onClose}
                open={open}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={() => form.submit()} type="primary">
                            Submit
                        </Button>
                    </Space>
                }
            >
                <div className="max-w-md mx-auto my-8 p-6 bg-white shadow-lg rounded-lg">
                    <div className="w-full">
                        <h1 className='text-center py-3 font-bold text-xl'>Edit User</h1>
                        <Form form={form} layout="vertical" initialValues={{
                            email: email,
                            password: password,
                            role: role,
                            credits: credits

                        }} onFinish={handleSubmit}>
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[{ required: true, message: 'Please input the email!' }]}
                            >
                                <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
                            </Form.Item>

                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[{ required: false, message: 'Please input the password!' }]} // Password is optional for edit
                            >
                                <Input.Password type='password' value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
                            </Form.Item>

                            <Form.Item
                                label="Role"
                                name="role"
                                rules={[{ required: true, message: 'Please select a role!' }]}
                            >
                                <Select value={role} onChange={(value) => setRole(value)} placeholder="Select a role">
                                    <Select.Option value="super-admin">Super Admin</Select.Option>
                                    <Select.Option value="org-admin">Organization Admin</Select.Option>
                                </Select>
                            </Form.Item>

                            {role === 'org-admin' && (
                                <Form.Item
                                    label="Credits"
                                    name="credits"
                                    rules={[{ required: true, message: 'Please input credits!' }]}
                                >
                                    <Input type="number" value={credits} onChange={(e) => setCredits(e.target.value)} placeholder="Credits" />
                                </Form.Item>
                            )}

                            <Form.Item>
                                <Button type="primary" htmlType="submit">Update</Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Drawer>
        </>
    );
};

export default EditUser;
