import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Select, Button, Upload, Space, Table, Row, Col, Switch, App, Modal, Result } from 'antd';
import { UploadOutlined,DownloadOutlined } from '@ant-design/icons';
import { getCollections, getEmailConfig, getSettingsByUserId, insertCertificateData, insertCertificateDetailData, insertJobData, insertMaildata, isSuperAdmin } from '../lib/db';
import Papa from 'papaparse';
import EmailTemplateForm from './EmailTemplateForm';
import { v4 as uuidv4 } from 'uuid';
import config from '../lib/config';
import { finalemail } from '../lib/finalemail';
import { Link, useNavigate } from 'react-router-dom';
import configs from '../lib/config';

const { Option } = Select;
const { TextArea } = Input;

const CertificateCreate = () => {
    const [form] = Form.useForm();
    const [organizations, setOrganizations] = useState([]);
    const { message } = App.useApp();
    const [events, setEvents] = useState([]);
    const [jobdata, setJobdata] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [emailtemplates, setEmailTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [requiredFields, setRequiredFields] = useState([]);
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [emailTemplateInit, setEmailTemplateInit] = useState('<p>Initial HTML Content</p>');
    const [templateVisible, setTemplateVisible] = useState(false);
    const [editorContent, setEditorContent] = useState(emailTemplateInit);
    const [saveStart, setSaveStart] = useState(false);
    const [csvFields, setCsvFields] = useState([]);
    const currentUser = localStorage.getItem('currentUser');
    const userdata = JSON.parse(currentUser);
    const userId = userdata.data.id;
    const textAreaForCSVStringRef = useRef({});
    const downloadLink = (id) =>{
        const baseUrl = config.certificate_url;
    const downloadUrl = `${baseUrl}${id}`;
    return downloadUrl;
    }
    const verifyLink = (id) =>{
        const baseUrl = config.verify_url;
        const verifyUrl = `${baseUrl}${id}`;
    return verifyUrl;
    }
    const navigate = useNavigate();
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
      const checkAdminStatus = async () => {
        if (currentUser) {
       
          const adminStatus = await isSuperAdmin(currentUser.id);
          setIsAdmin(adminStatus);
        }
      };
      checkAdminStatus();
    }, [currentUser]);

    useEffect(() => {
        const fetchData = async () => {
            try {


                const [orgs, evnts, tmpls,emailtemplates] = await Promise.all([
                    getCollections(userId, 'organizations'),
                    getCollections(userId, 'events'),
                    getCollections(userId, 'templates'),
                    getCollections(userId, 'emailtemplate'),
                ]);
                setOrganizations(orgs);
                setEvents(evnts);
                setTemplates(tmpls);
                setEmailTemplates(emailtemplates);
            } catch (error) {
                console.error('Error fetching collections:', error);
            }
        };

        fetchData();
    }, [currentUser]);

    const handleDownloadSampleCSV = () => {
        let sampleCSVJSONHolder = [];
        let sampleCSVRow = {};
        csvFields.map( key => {
            switch (key) {
                case 'candidate_name':
                    sampleCSVRow['candidate_name'] = 'Arish N'
                    break;

                case 'fullname':
                    sampleCSVRow['name'] = 'Arish N'
                    break;

                case 'email':
                    sampleCSVRow['email'] = 'example@sample.com'
                    break;

                case 'date':
                    sampleCSVRow['date'] = formatDate()
                    break;
            
                default:
                    sampleCSVRow[key] = `Value for key ${key}`
                    break;
            }
            return true
        })
        sampleCSVJSONHolder.push(sampleCSVRow)
        
        exportCSV(Papa.unparse(sampleCSVJSONHolder), `Sample_CSV_for_data_upload.csv`)
    }

    const formatDate = () => {
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getDate()).padStart(2, '0');
        
        return `${year}-${month}-${day}`;
      };

   const exportCSV = ( csvString, fileName = 'data.csv' ) => {
        let csvContent = "data:text/csv;charset=utf-8,"
        csvContent = csvContent+csvString
        let encodedUri = encodeURI(csvContent)
        let link = document.createElement("a")
        link.setAttribute("href", encodedUri)
        link.setAttribute("download", fileName)
        document.body.appendChild(link)
        link.click()
    }


    const onFinish = async (values) => {
        const jobId = uuidv4();
        const currentTimestamp = new Date().toISOString();
        setSaveStart(true);
        const jobData = {
            id: jobId,
            userid: userId,
            name: values.jobName,
            certificate_type: values.certificatetype,
            is_custom_email_template_set: templateVisible,
            created_at: currentTimestamp,
            updated_at: currentTimestamp,
            organizations: {
                name: organizations.find(org => org.id === values.organization).name,
                organization_id: values.organization
            },
            events: {
                id: values.event,
                name: events.find(event => event.id === values.event).name
            },
            templates: {
                id: values.template,
                name: templates.find(template => template.id === values.template).name
            },
            status:false
        };

        const recipientData = data.map((row, index) => ({
            id: uuidv4(),
            recipient_name: row.name,
            recipient_email: row.email,
            extra_metadata: {
                ...row,
                certificateType: values.certificatetype
            },
            job_id: jobId,
            type: values.certificatetype,
            template_key:values.template,
            is_published: true,
            certificate_short_code: 'AAAA-BBBB-CCCC-DDDD',
            created_at: currentTimestamp,
            updated_at: currentTimestamp,
            certificate_status: false,
            organizations: {
                subdomain: ""
            }
        }));
        setJobdata(recipientData);

        const insertJobs = async (jobdata) => {
            await insertJobData(jobdata,jobId);
        }
        const insertCertificate = async (jobdata) => {
            const key = await insertCertificateData(jobdata,userId);
            if(key){
                await insertCertificateDetails(jobdata,key);
            }
        }
        const insertCertificateDetails = async (recipientData,key) => {
            
            for (const [index, recipient] of recipientData.entries()) {
                const certificateDetail = {
                    recipient_id: recipient.id,
                    job_id: recipient.job_id,
                    certificate_key:key,
                    index: index, 
                };
                await insertCertificateDetailData(certificateDetail, recipient.id); 
            }
        };
        try {
            // Insert job and recipient data
            insertJobs(jobData);
            insertCertificate(recipientData);
           
            const emailConfig = await getEmailConfig(userId);
            const smtpsettings = await getSettingsByUserId(userdata.data.admid);
            if (emailConfig && emailConfig.emailTemplate) {

                const templateHtml = getSelectedTemplateHtml(emailConfig.emailTemplate);
                const templateSubject = getSelectedTemplateSubject(emailConfig.emailTemplate);

                if (templateHtml) {
                    // Construct the email data set
                    const emailDataSet = constructEmailData(values, recipientData, templateHtml, emailConfig.emailTemplate, userId,templateSubject);
                   
                    
                    const insertmaildata = await insertMaildata(emailDataSet,jobId);

                    const sendJobId = async(jobId)=> {
                        const url = `${configs.mailapi_url}?jobid=${jobId}`;
                        
                        fetch(url, {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json',
                          },
                          
                        })
                        .then(response => response.json())
                        .then(data => {
                          console.log('Success:', data);
                        })
                        .catch(error => {
                          console.error('Error:', error);
                        });
                      }

                      await sendJobId(jobId);

                     


                } else {
                    console.error('No template found for the selected email template.');
                }
            } else {
                console.error('No EmailConfig found or no email template is set.');
            }
            setSaveStart(false);
            form.resetFields();
            textAreaForCSVStringRef.current.value = '';
            setData([]);
            const modal = Modal.success({
                icon: null, // This removes the default success icon
                content: (
                  <Result
                    status="success"
                    title="Job Successfully Assigned!"
                    subTitle="The job has been assigned and will be completed shortly. Please wait for the status to update."
                    extra={[
                    
                      <Button key='back'  onClick={() => {
                        navigate('/certificate');
                        modal.destroy(); 
                      }}>Go to List</Button>,
                      <Button type='primary' key='download'  onClick={()=>{handleDownloadJobDataCSV(jobdata,data,recipientData);}}>Download Job Details</Button>,
                    
                    ]}
                  />
                ),
                okButtonProps: {
                  style: { display: 'none' } // This hides the default OK button
                },
              });
            
        } catch (error) {
            console.error('Error processing the job:', error.message);
            message.error('Failed to create the job.');
        }

    };

    const getSelectedTemplateHtml = (templateId) => {
        const selectedTemplate = emailtemplates.find(template => template.name === templateId);
        return selectedTemplate ? selectedTemplate.fulltemplate : finalemail;
    };
    const getSelectedTemplateSubject = (templateId) => {
        const selectedTemplate = emailtemplates.find(template => template.name === templateId);
        return selectedTemplate ? selectedTemplate.subject : "Congratulations for your {{event.name}} certificate by {{organization.name}}";
    };

    const constructEmailData = (formvalues, recipients, templateHtml, templateId, userId, templateSubject) => {
        const event = events.find(event => event.id === formvalues.event);
        const eventname = event.name;
        const orgname = organizations.find(org => org.id === formvalues.organization).name;
        let eventHashtags = '';
    
        if (event && event.hashtags && Array.isArray(event.hashtags)) {
            eventHashtags = event.hashtags.map(hashtag => `#${hashtag}`).join(', ');
        }
    
        const emailDataArray = recipients.map((recipient) => {
            const download_link = downloadLink(recipient.id);
            const verify_link = verifyLink(recipient.id);
            const organizationlogo = organizations.logo !== "" ? organizations.logo : './img/logo.png';
            let personalizedHtml = templateHtml.replace(/{{name}}/g, recipient.recipient_name)
                .replace(/{{email}}/g, recipient.recipient_email)
                .replace(/{{event.hashtags}}/g, eventHashtags)
                .replace(/{{download_link}}/g, download_link)
                .replace(/{{verification_link}}/g, verify_link)
                .replace(/{{certificate_type}}/g, recipient.type)
                .replace(/{{organization.name}}/g, orgname)
                .replace(/{{organization.logo}}/g, organizationlogo)
                .replace(/{{event.name}}/g, eventname)
                .replace(/\n/g, '')
                .replace(/\/img\/logo.png/g, config.site_url+config.logo) 
                .replace(/contenteditable="true"/g, '')
                .replace(/\\/g, '');

            let personalizedSubject = templateSubject.replace(/{{name}}/g, recipient.recipient_name)
                .replace(/{{email}}/g, recipient.recipient_email)
                .replace(/{{event.hashtags}}/g, eventHashtags)
                .replace(/{{download_link}}/g, download_link)
                .replace(/{{verification_link}}/g, verify_link)
                .replace(/{{certificate_type}}/g, recipient.type)
                .replace(/{{organization.name}}/g, orgname)
                .replace(/{{event.name}}/g, eventname);
    
            return {
                name: recipient.recipient_name,
                email: recipient.recipient_email,
                templateHtml: personalizedHtml,
                subject: personalizedSubject,
                userId: userId,
                templateId: templateId,
                certificateid:recipient.id
            };
        });
    
        return emailDataArray;  // Final array of JSON objects
    };
    


    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const handleCertificateTypeChange = (value) => {
        console.log(value);
    };
    const handleFileUpload = (file) => {
        Papa.parse(file, {
            header: true,
            complete: (result) => {
                const { data, errors } = result;
                if (errors.length > 0) {
                    message.error('Error parsing CSV file.');
                } else {
                    if (requiredFields != null) {
                        const specialFieldMapping = {
                            fullname: 'name',
                            // Add more mappings if necessary
                        };

                        // Check for missing fields, considering special cases
                        const missingFields = requiredFields.filter(field => {
                            const mappedField = specialFieldMapping[field] || field;
                            return !(mappedField in data[0]);
                        });

                        if (missingFields.length > 0) {
                            message.error(`The following required fields are missing in the CSV file: ${missingFields.join(', ')}`);
                        } else {
                            const uniqueData = data.map((row, index) => ({ ...row, key: index }));
                            const cols = Object.keys(data[0]).map((key) => ({
                                title: key,
                                dataIndex: key,
                                key,
                            }));
                            
                            const availableCredit = typeof userdata.data.usedcredits !== 'undefined' ? userdata.data.usedcredits : userdata.data.credits;
                            if(!isAdmin && availableCredit < uniqueData.length){
                                    message.warning("uploaded data grater then your credits!")
                            }else{
                            
                            setColumns(cols);
                            setData(uniqueData);
                            }
                        }
                    }
                    else {
                        message.error("Please Select a Template Before Move Forward")
                    }
                }
            },
        });
        return false; // Prevent default upload behavior
    };

    const uploadProps = {
        beforeUpload: handleFileUpload,
        showUploadList: false,
        accept: '.csv', 
    };
    const handleTemplateChange = (value) => {
        const selectedTemplate = templates.find(template => template.id === value);
        setSelectedTemplate(selectedTemplate);
      
        if (selectedTemplate) {
            if (selectedTemplate.templatedesign !== undefined) {
                const requiredFields = Object.keys(selectedTemplate.templatedesign.elements)
                .filter(key => selectedTemplate.templatedesign.elements[key].required)
                .map(key => selectedTemplate.templatedesign.elements[key].header || key) // Use 'key' if 'header' is missing
                .filter(header => header !== 'qrcode' && header !== 'verificationlink');
              
              const csvFields = Object.keys(selectedTemplate.templatedesign.elements)
                .map(key => selectedTemplate.templatedesign.elements[key].header || key) // Use 'key' if 'header' is missing
                .filter(header => header !== 'qrcode' && header !== 'verificationlink');
              
                setRequiredFields(requiredFields);
                console.log(selectedTemplate.templatedesign.elements,requiredFields);
        
                csvFields.push('email');
                const filteredCsvFields = csvFields.filter(field => field !== undefined);
                setCsvFields(filteredCsvFields);
                console.log(filteredCsvFields);
            }
            else {
                message.error("The Selected Template Not Fully Completed!");
                setSelectedTemplate("")
                form.resetFields(['template']);
            }
        }


    };

    const handleSave = () => {
        form.validateFields()
            .then(values => {
                setEmailTemplateInit(editorContent);
                message.success('Template saved successfully!');
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
    };

    const handleEditorChange = (content) => {
        setEditorContent(content);
    };

    const parseString = () => {
        let CSVString = textAreaForCSVStringRef.current?.resizableTextArea?.textArea?.value;
        console.log(CSVString);
        
        Papa.parse(CSVString, {
            worker: true,
            header: true,
            skipEmptyLines: 'greedy',
            complete: (result) => {
                const data = result?.data ;
                if (requiredFields != null) {
                    const specialFieldMapping = {
                        fullname: 'name',
                        // Add more mappings if necessary
                    };
                    console.log(requiredFields);
                    
                    // Check for missing fields, considering special cases
                    const missingFields = requiredFields.filter(field => {
                        const mappedField = specialFieldMapping[field] || field;
                        return !(mappedField in data[0]);
                    });

                    if (missingFields.length > 0) {
                        message.error(`The following required fields are missing in the CSV file: ${missingFields.join(', ')}`);
                    } else {
                        const uniqueData = data.map((row, index) => ({ ...row, key: index }));
                        const cols = Object.keys(data[0]).map((key) => ({
                            title: key,
                            dataIndex: key,
                            key,
                        }));

                        const availableCredit = typeof userdata.data.usedcredits !== 'undefined' ? userdata.data.usedcredits : userdata.data.credits;
                        
                        
                        if(!isAdmin && availableCredit < uniqueData.length){
                                message.warning("uploaded data grater then your credits!")
                        }else{

                        setColumns(cols);
                        setData(uniqueData);
                    }
                    }
                }
                // const isValidData = checkCSVColumns(data[0])
                // if (!isValidData) {
                //     return false
                // }
                console.log(result);
            }
        })
    }

    const handleDownloadJobDataCSV = (jobData, uploadedData,recipientData) => {
        
        let csvDataHolder = [];
      
        uploadedData.forEach((row, index) => {
    
          let jobRow = {
            ...row, 
            job_name:jobData.name,
            certificate_link: `https://app.mycertificate.in/c/${recipientData[index].id}`,
            verification_url: `https://app.mycertificate.in/v/${recipientData[index].id}`
          };
      
          csvDataHolder.push(jobRow);
        });
      
        const csv = Papa.unparse(csvDataHolder);
      
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'Job_Details_with_Uploaded_Data.csv';
        link.click();
      };

    return (
        <div className="w-full h-full p-3 bg-white rounded-lg">
            <Form
                form={form}
                name="certificate-form"
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                className="p-4"
            >
                <Form.Item
                    label="Name your job"
                    name="jobName"
                    rules={[{ required: true, message: 'Please name your job!' }]}
                >
                    <Input placeholder="Give your job a super memorable name" />
                </Form.Item>

                <Form.Item label="Choose Event details">
                    <div className='w-full gap-3 flex justify-between items-center'>
                        <div className="w-3/12">
                            <Form.Item
                                name="organization"
                                label="Organization"
                                rules={[{ required: true, message: 'Please select an organization!' }]}
                            >
                                <Select placeholder="Organization">
                                    {organizations.length > 0 && organizations.map(org => (
                                        <Option key={org.id} value={org.id}>{org.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="w-3/12">
                            <Form.Item
                                label="Event"
                                name="event"
                                rules={[{ required: true, message: 'Please select an event!' }]}
                            >
                                <Select placeholder="Event">
                                    {events.length > 0 && events.map(event => (
                                        <Option key={event.id} value={event.id}>{event.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="w-3/12">

                            <Form.Item
                                name="template"
                                label="Template"
                                rules={[{ required: true, message: 'Please select a template!' }]}
                                placeholder="Template"

                            >
                                <Select placeholder="Template" onChange={handleTemplateChange}>
                                    {templates.length && templates.map(template => (
                                        <Option key={template.id} value={template.id}>{template.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="w-3/12">

                            <Form.Item
                                name="certificatetype"
                                label="Certificate Type"

                                rules={[{ required: true, message: 'Certificate Type' }]}
                            >
                                <Select
                                    defaultValue=""
                                    className='w-full'
                                    onChange={handleCertificateTypeChange}
                                    options={[
                                        {
                                            label: "Certificate Type Select...",
                                            value: ""
                                        },
                                        {
                                            label: "Participant",
                                            value: "Participant"
                                        },
                                        {
                                            label: "Winner",
                                            value: "Winner"
                                        },
                                        {
                                            label: "Coordinator",
                                            value: "Coordinator"
                                        },
                                        {
                                            label: "Organizer",
                                            value: "Organizer"
                                        },
                                        {
                                            label: "Volunteer",
                                            value: "Volunteer"
                                        },
                                        {
                                            label: "Ambassador",
                                            value: "Ambassador"
                                        },
                                        {
                                            label: "Speaker",
                                            value: "Speaker"
                                        },
                                        {
                                            label: "Mentor",
                                            value: "Mentor"
                                        },
                                        {
                                            label: "Judge",
                                            value: "Judge"
                                        },
                                        {
                                            label: "Award",
                                            value: "Award"
                                        },
                                        {
                                            label: "Achievement",
                                            value: "Achievement"
                                        },
                                        {
                                            label: "Completion",
                                            value: "Completion"
                                        },
                                        {
                                            label: "Experience",
                                            value: "Experience"
                                        },
                                        {
                                            label: "Grade",
                                            value: "Grade"
                                        },
                                        {
                                            label: "Offer letter",
                                            value: "Offer letter"
                                        },
                                        {
                                            label: "Other",
                                            value: "Other"
                                        }
                                    ]}
                                />
                            </Form.Item>
                        </div>
                    </div>
                </Form.Item>
                <div className="flex w-full justify-end">
                        <Button type='primary' icon={<DownloadOutlined />} onClick={handleDownloadSampleCSV} disabled={selectedTemplate ==  null ? true:false}>
                                Download Sample CSV
                        </Button>
                </div>
                <Row gutter={24} className='py-3'>
                <Col span={12}>
                <Form.Item label="Provide Data">
                    <div>
                        <Form.Item name="uploadCSV">
                            <Upload {...uploadProps}>
                                <Button icon={<UploadOutlined />} disabled={selectedTemplate ==  null ? true:false}>Click to Upload</Button>
                            </Upload>
                        </Form.Item>
                      

                    </div>
                </Form.Item>
                </Col>
                <Col span={12}>
                <Form.Item label="Enter CSV String">
                <TextArea
                    ref={textAreaForCSVStringRef}
                    placeholder="name,email,company
                John Doe,johndoe@example.com,Example Corp
                " 
                   />
                    <Button type='primary' onClick={parseString} className='my-3' disabled={selectedTemplate ==  null ? true:false}>
                               Decode String
                    </Button>
                </Form.Item>
                </Col>
                </Row>
                
                <Row gutter={24} className='py-3'>
                    <Col span={24}>
                    {data.length > 0 &&
                            <Table dataSource={data} columns={columns} pagination={{ pageSize: 5 }} />}
                    </Col>
                </Row>
                <Row gutter={24} className='py-3'>
                    <Col span={12}>
                        <h1 className='font-bold text-xl'>Email Template</h1>
                    </Col>
                    <Col span={12} className='flex justify-end'>
                        <Switch
                            checked={templateVisible}
                            onChange={() => setTemplateVisible(!templateVisible)}

                        />
                    </Col>
                </Row>

                {templateVisible && (<EmailTemplateForm />)}

                <Form.Item>
                    <Button type="primary" loading={saveStart} disabled={saveStart || !data.length > 0} htmlType="submit">
                        Submit Job
                    </Button>
                    {/* <Button className="ml-2">
                        Save For Later
                    </Button> */}
                </Form.Item>
            </Form>
        </div>
    );
};

export default CertificateCreate;
