import React from 'react'
import EmailSetting from '../components/EmailSetting'

const Settings = () => {
  return (
    <div className='w-full h-full flex justify-center items-center'>
        <div className="max-w-lg w-full bg-white p-3">
        <h1 className='font-bold text-xl py-3'>Mail API</h1>
        <EmailSetting/>
        </div>
    </div>
  )
}

export default Settings