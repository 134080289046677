import { Button, Menu, theme } from 'antd';
import Sider from 'antd/es/layout/Sider';
import React, { useEffect, useState } from 'react';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined
} from '@ant-design/icons';
import { Header } from 'antd/es/layout/layout';
import { IoHomeOutline } from 'react-icons/io5';
import { GoOrganization } from 'react-icons/go';
import { BsCalendar4Event } from 'react-icons/bs';
import { LuLayoutTemplate } from 'react-icons/lu';
import { TbFileCertificate } from 'react-icons/tb';
import { useAuth } from '../context/AuthContext';
import AvatarDropdown from './AvatarDropdown';
import { useLocation, Link } from 'react-router-dom';
import configs from "../lib/config"
import { isSuperAdmin } from '../lib/db';

const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(true);
  const [selectedMenuKey, setSelectedMenuKey] = useState('1');
  const { currentUser } = useAuth();
  const location = useLocation();
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const checkAdminStatus = async () => {
      if (currentUser) {
     
        const adminStatus = await isSuperAdmin(currentUser.id);
        setIsAdmin(adminStatus);
      }
    };
    checkAdminStatus();
  }, [currentUser]);

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  useEffect(() => {
    if (currentUser) {
      switch (location.pathname) {
        case '/organizations':
          setSelectedMenuKey('2');
          break;
        case '/events':
          setSelectedMenuKey('3');
          break;
        case '/templates':
          setSelectedMenuKey('4');
          break;
        case '/certificate':
          setSelectedMenuKey('5');
          break;
        case '/users':
          setSelectedMenuKey('6');
          break;
        case '/':
        case '/home':
        default:
          setSelectedMenuKey('1');
          break;
      }
    }
  }, [location, currentUser]);

  if (!currentUser) {
    return null;
  }

  const menuItems = [
    
    {
      key: '1',
      icon: <IoHomeOutline />,
      label: <Link to="/">Home</Link>,
    },
    {
      key: '2',
      icon: <GoOrganization />,
      label: <Link to="/organizations">Organisations</Link>,
    },
    {
      key: '3',
      icon: <BsCalendar4Event />,
      label: <Link to="/events">Events</Link>,
    },
    {
      key: '4',
      icon: <LuLayoutTemplate />,
      label: <Link to="/templates">Templates</Link>,
    },
    {
      key: '5',
      icon: <TbFileCertificate />,
      label: <Link to="/certificate">Certificates</Link>,
    },
  
    ...(isAdmin ? [  {
      key: '6',
      icon: <UserOutlined />,
      label: <Link to="/users">Users</Link>,
    }] : []),
  ];

  return (
    <div className='sidebar_container'>
      <Header
        style={{
          padding: 0,
          background: colorBgContainer,
          position: 'fixed',
          top: '0',
          left: '0',
          width: '100%',
          zIndex: 100
        }}
        className='topbar'
      >
        <div className="flex px-4 w-full">
          <div className="flex-1">
            <div className='logo_container  justify-start w-full h-full flex items-center'>
              <a href={configs.site_url}>
              <img
                src={configs.logo}
                className='object-contain h-full max-h-[64px]'
                alt="logo"
              />
              </a>
            </div>
          </div>
          <div className="flex-1">
            <div className="ml-auto w-fit pr-3 block">
              <AvatarDropdown />
            </div>
          </div>
        </div>
      </Header>
      <Sider theme='light' className='sidebar-menu' trigger={null} collapsible collapsed={collapsed}>
        <Menu
          theme="light"
          mode="inline"
          selectedKeys={[selectedMenuKey]}
          items={menuItems}
        />
      </Sider>
      <div style={{ background: colorBgContainer }} className="collapse_container">
        <Button
          type="text"
          theme="light"
          icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={() => setCollapsed(!collapsed)}
          style={{
            fontSize: '16px',
            width: '100%'
          }}
          className='sidebar_collapse'
        />
      </div>
    </div>
  );
};

export default Sidebar;
