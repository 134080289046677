import React, { useEffect, useState } from 'react';
import { Button, Table, Space, App, Popconfirm, Tooltip, Badge } from 'antd';

import { deleteJobs, getJobByUserId, isSuperAdmin } from '../lib/db';
import { DeleteOutlined, PlusOutlined, EyeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Loader from '../components/Loader';


const Certificate = () => {

  const [loading, setLoading] = useState(false);
  const [pageload,setPageLoad] = useState(true);
  const currentUser = localStorage.getItem('currentUser');
  const [Jobsdata, setJobsData] = useState([]);
  const { message } = App.useApp();

  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const checkAdminStatus = async () => {
      if (currentUser) {
        const userdata = JSON.parse(currentUser);
        const adminStatus = await isSuperAdmin(userdata.data.id);
        setIsAdmin(adminStatus);
      }
    };
    checkAdminStatus();
  }, [currentUser]);

  const columns = (onEditSuccess) => [
    ...(isAdmin ? [{
      title: 'Created By',
      dataIndex: 'creatorEmail',
    }] : []),
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Event Name',
      dataIndex: ['events', 'name'],
    },
    {
      title: 'Organization Name',
      dataIndex: ['organizations', 'name'],
    },
    {
      title: 'Template Name',
      dataIndex: ['templates', 'name'],
    },

    {
      title: 'Updated At',
      dataIndex: 'updated_at',  // Correct dataIndex for updated_at field
      key: 'updated_at',
      render: (text) => new Date(text).toLocaleString('en-US', {
        year: 'numeric', month: 'long', day: 'numeric',
        hour: 'numeric', minute: 'numeric', second: 'numeric',
        hour12: true
      })
    },
    {
      title: 'Status',
      render: (text, record) => (
        <Space direction="vertical">
          {
      record?.status == true ? <div className='flex items-center gap-3 justify-center'><Badge status="success" /> Success</div> : <div className='flex items-center gap-3 justify-center'>  <Badge status="processing"/>Processing</div>}
        </Space>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Space size="middle">
          <Tooltip title="View">
            <Link to={`/certificatelist/${record.id}`}>
            <Button shape="circle" icon={<EyeOutlined />} />
            </Link>
          </Tooltip>
          <Popconfirm
            title="Delete the Row"
            description="Are you sure to delete this record?"
            onConfirm={() => confirmDelete(record.key)}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <Button shape="circle" icon={<DeleteOutlined />} danger />
          </Popconfirm>

        </Space>
      ),
    },
  ];


  const confirmDelete = async (key) => {

    try {
      await deleteJobs(key);
      fetchJobData();
      message.success('Job Deleted Successfully');

    }
    catch (error) {

    }
  };
  const cancel = (e) => {
    console.log(e);
    message.error('Cancelled');
  };


  const fetchJobData = async () => {


    const userdata = JSON.parse(currentUser);


    const fetchJobdata = await getJobByUserId(userdata.data.id);

    if (Object.keys(fetchJobdata).length > 0) {
      const jsonJobArray = Object.entries(fetchJobdata).map(([id, details]) => {
        return { key: id, ...details };
      });
      setJobsData(jsonJobArray);
      setPageLoad(false);
    } else {
      setJobsData([]);
      setPageLoad(false);

    }

  }


  useEffect(() => {

    fetchJobData();
  }, []);


  const start = () => {
    setLoading(true);
    fetchJobData();
    setTimeout(() => {

      setLoading(false);
    }, 1000);
  };





  return (
    <>
    {pageload && <Loader/>}
    {!pageload && <div className="w-full p-3 bg-white rounded">
      <div style={{ marginBottom: 16 }} className='flex justify-between'>
        <Button type="primary" onClick={start} loading={loading}>
          Refresh
        </Button>
        <Link to={"/certificate/new"}>
          <Button type="primary" icon={<PlusOutlined />}>
            Create
          </Button>
        </Link>
      </div>
      <Table columns={columns(fetchJobData)} dataSource={Jobsdata} />
    </div>}
    </>
  );
};

export default Certificate;
