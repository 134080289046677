import React from 'react';
import { Input, Button, Tooltip, App } from 'antd';
import { CopyOutlined, LinkOutlined } from '@ant-design/icons';
import config from '../lib/config'
const DownloadLinkInput = ({ id }) => {
  const baseUrl = config.certificate_url;
  const downloadUrl = `${baseUrl}${id}`;
  const {message} = App.useApp();

  const copyToClipboard = () => {
    navigator.clipboard.writeText(downloadUrl);
    message.success('Link copied to clipboard!');
  };

  const openInNewTab = () => {
    window.open(downloadUrl, '_blank');
  };

  return (
    <Input
      addonBefore={
        <Tooltip title="Open in new tab">
          <Button icon={<LinkOutlined />} type='link' onClick={openInNewTab} />
        </Tooltip>
      }
      addonAfter={
        <Tooltip title="Copy link">
          <Button icon={<CopyOutlined />} type='link' onClick={copyToClipboard} />
        </Tooltip>
      }
      value={downloadUrl}
      readOnly
    />
  );
};

export default DownloadLinkInput;