import React, { useState } from 'react';
import { Form, Input, Button, Typography, App } from 'antd';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import configs from '../../lib/config';

const { Title } = Typography;

const Login = () => {
  const { login } = useAuth(); // Import your login function from AuthContext
  const [error, setError] = useState(null); // State for displaying login error
  const [loading, setLoading] = useState(false); // State to manage loading state
  const { notification } = App.useApp();
  const navigate = useNavigate();
  const handleSubmit = async (values) => {
    const { email, password } = values;
    setLoading(true);
    try {
      await login(email, password); 
      notification.success({
        message: 'Login Successful',
        description: 'You have successfully logged in.',
      });
      navigate("/");
    } catch (error) {
        notification.error({
            message: 'Login Failed',
            description: error.message,
          });
      setError(error.message); // Display error message
    }
    setLoading(false);
  };

  return (
    <div className="max-w-md mx-auto my-8 p-6 bg-white shadow-lg rounded-lg">
       <div className='logo_container  justify-center py-2 w-full h-full flex items-center'>
       <a href={configs.site_url}>
              <img
                src={configs.logo}
                className='object-contain h-full max-h-[64px]'
                alt="logo"
              />
              </a>
            </div>
      <Title level={3} className="text-center">Login</Title>
      {error && <p className="text-red-500 text-center mb-4">{error}</p>}
      <Form onFinish={handleSubmit}>
        <Form.Item
          name="email"
          rules={[{ required: true, message: 'Please enter your email!' }]}
        >
          <Input type="email" placeholder="Email" className="w-full mb-4" />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please enter your password!' }]}
        >
          <Input.Password placeholder="Password" className="w-full mb-4" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading} block>
            Log in
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;
