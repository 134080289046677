import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider, App as AntdApp } from 'antd';
import AuthProvider from './context/AuthContext';
import config from './lib/config';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <ConfigProvider theme={{ token: { colorPrimary: config.theme_color } }}>
        <AntdApp>
          <App />
        </AntdApp>
      </ConfigProvider>
    </AuthProvider>
  </React.StrictMode>
);

reportWebVitals();
