import React, { useEffect, useState } from 'react';
import { Button, Table, Space, App, Popconfirm } from 'antd';

import { deleteUser, getAllUsers, isSuperAdmin } from '../lib/db'; // Ensure functions for user operations exist
import { DeleteOutlined,PlusOutlined } from '@ant-design/icons';
import EditUser from './auth/EditUser';
import Register from './auth/Register';
import { Link } from 'react-router-dom';
import Loader from '../components/Loader';

const UsersList = () => {
  const [loading, setLoading] = useState(false);
  const [pageload,setPageLoad] = useState(true);

  const currentUser = localStorage.getItem('currentUser');
  const [userData, setUserData] = useState([]);
  const { message } = App.useApp();
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const checkAdminStatus = async () => {
      if (currentUser) {
        const userdata = JSON.parse(currentUser);
        const adminStatus = await isSuperAdmin(userdata.data.id);
        setIsAdmin(adminStatus);
      }
    };
    checkAdminStatus();
  }, [currentUser]);

  const columns = (onEditSuccess) => [
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Role',
      dataIndex: 'role',
    },
    {
      title: 'Credit',
      dataIndex: 'credits',
    },
    {
      title: 'Used Credit',
      dataIndex: 'usedCredits',
    },
    {
      title: 'Available Credits',
      dataIndex: 'availableCredits',
      render:(text,record)=>(
        <p>
  {record.credits != null && record.usedCredits != null ? record.credits - record.usedCredits : '-'}
</p>

      )
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Space size="middle">
          <EditUser user={record} onEditSuccess={onEditSuccess} />
          <Popconfirm
            title="Delete the Row"
            description="Are you sure to delete this record?"
            onConfirm={() => confirmDelete(record.key)}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <Button shape="circle" icon={<DeleteOutlined />} danger />
          </Popconfirm>
          {/* <ViewUser userprevdata={record} /> */}
        </Space>
      ),
    },
  ];

  const confirmDelete = async (key) => {
    try {
      await deleteUser(key);
      fetchUserData();
      message.success('User Deleted Successfully');
    } catch (error) {
      console.error('Error deleting user:', error.message);
    }
  };

  const cancel = (e) => {
    console.log(e);
    message.error('Cancelled');
  };

  const fetchUserData = async () => {
    const userdata = JSON.parse(currentUser);
    const fetchUserData = await getAllUsers(userdata.data.id); // Adjust function name if needed

    if (Object.keys(fetchUserData).length > 0) {
      const jsonUserArray = Object.entries(fetchUserData).map(([id, details]) => {
        return { key: id, ...details };
      });
      setUserData(jsonUserArray);
      setPageLoad(false);

    } else {
      setUserData([]);
      setPageLoad(false);

    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const start = () => {
    setLoading(true);
    fetchUserData();
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  return (
    <>
    {pageload && <Loader/>}
  {!pageload &&  <div className="w-full p-3 bg-white rounded">
      <div style={{ marginBottom: 16 }} className='flex justify-between'>
        <Button type="primary" onClick={start} loading={loading}>
          Refresh
        </Button>
        <Link to={"/register"}>
        <Button type="primary" icon={<PlusOutlined />}>
                Create
            </Button>
            </Link>
      </div>
      <Table columns={columns(fetchUserData)} dataSource={userData} />
    </div>}
    </>
  );
};

export default UsersList;
