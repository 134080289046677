const finalemail = `<div style="width: 100%; color: black;"><div style="font-family: Verdana, Arial, Helvetica, sans-serif; font-size: 10pt;"><table width="100%" cellspacing="0" cellpadding="0" style="border-collapse: collapse; border-width: 0px;"><tbody><tr><td align="center" style="background-color: rgb(233, 236, 239);"><table width="100%" cellspacing="0" cellpadding="0" style="max-width: 600px; border-collapse: collapse; border-width: 0px;"><tbody><tr><td valign="top" align="center" style="padding: 16px 24px;"><a href="https://app.mycertificate.in/" style="color: rgb(26, 130, 226); display: inline-block;"><img src="/img/logo.png" alt="Warm" width="220" height="auto" style="height: auto; line-height: 100%; text-decoration: none; border: 0px; outline: none; display: block; width: 220px; max-width: 220px; min-width: 220px;"></a></td></tr></tbody></table></td></tr><tr><td align="center" style="background-color: rgb(233, 236, 239);"><table width="100%" cellspacing="0" cellpadding="0" style="max-width: 600px; border-collapse: collapse; border-width: 0px;"><tbody><tr><td align="left" style="padding: 24px; font-family: &quot;Source Sans Pro&quot;, Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px; background-color: rgb(255, 255, 255);"><div class="editor remove-all emailcontent" ><div translate="no" class="ProseMirror tip_tap_editor_styles" tabindex="0">
                                                <h2 style="text-align: center">Greetings {{name}},</h2>
                                                <p>&nbsp;</p>
                                                <p style="text-align: center">Thank You so much for your Participation
                                                    in <strong>{{event.name}} by
                                                        {{organization.name}}</strong>.<br><br>Share your certificates
                                                    on social media handles using hashtags
                                                    <strong>{{event.hashtags}}</strong></p>
                                                <p style="text-align: center"><br class="ProseMirror-trailingBreak"></p>
                                                <p style="text-align: center"><br class="ProseMirror-trailingBreak"></p>
                                                <p style="text-align: center">Download or verify your certificate below
                                                </p>
                                                <p><br><br class="ProseMirror-trailingBreak"></p>
                                                <table width="100%" table-layout="fixed" style="table-layout: fixed;">
                                                    <tbody>
                                                        <tr>
                                                            <td colspan="1" rowspan="1">
                                                                <p style="text-align: center"><a target="_blank" rel="noopener noreferrer nofollow" class="editor_link editor_link editor_link_button" href="{{download_link}}" style="display:inline-block;padding:16.0px 36.0px;font-family:&quot;Source Sans Pro&quot;, Helvetica, Arial, sans-serif;font-size:16.0px;color:rgb(255,255,255);text-decoration:none;border-radius:6.0px;background-color:#1a82e2" title="Linked To: {{download_link}}">Download</a>
                                                                </p>
                                                            </td>
                                                            <td colspan="1" rowspan="1">
                                                                <p style="text-align: center"><a target="_blank" rel="noopener noreferrer nofollow" class="editor_link editor_link editor_link_button" href="{{verification_link}}" style="display:inline-block;padding:16.0px 36.0px;font-family:&quot;Source Sans Pro&quot;, Helvetica, Arial, sans-serif;font-size:16.0px;color:rgb(255,255,255);text-decoration:none;border-radius:6.0px;background-color:#1a82e2" title="Linked To: {{verification_link}}">Verify</a>
                                                                </p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <p><br class="ProseMirror-trailingBreak"></p>
                                                <p><br class="ProseMirror-trailingBreak"></p>
                                                <p>To get the most out of your digital badge, take these next steps:</p>
                                                <ul>
                                                    <li>
                                                        <p>Download your certificate.</p>
                                                    </li>
                                                    <li>
                                                        <p>Share on social media with hashtags.</p>
                                                    </li>
                                                    <li>
                                                        <p>Update your LinkedIn Profile.</p>
                                                    </li>
                                                </ul>
                                                <p><br class="ProseMirror-trailingBreak"></p>
                                                <p>Digital certificate save our trees and our environment. Say Hello
                                                    to&nbsp;<strong>Give My Certificate.</strong><br><br>We would like
                                                    to assure you that your data is safe with us and is used for one
                                                    reason alone: Verification of your Documents. We do not and will
                                                    never share your data with anyone.</p>
                                                <p><br class="ProseMirror-trailingBreak"></p>
                                                <p>In case you are having trouble reaching your certificate please copy
                                                    and paste below URL</p>
                                                <p>{{download_link}}</p>
                                                <p><br class="ProseMirror-trailingBreak"></p>
                                                <p><br>Regards</p>
                                                <p>{{organization.name}}</p>
                                                <p><br class="ProseMirror-trailingBreak"></p>
                                                <hr contenteditable="false">
                                                <p><br class="ProseMirror-trailingBreak"></p>
                                            </div></div></td></tr></tbody></table></td></tr><tr><td align="center" style="padding: 24px; background-color: rgb(233, 236, 239);"><table width="100%" cellspacing="0" cellpadding="0" style="max-width: 600px; border-collapse: collapse; border-width: 0px;"><tbody><tr><td align="center" style="padding: 12px 24px; font-family: &quot;Source Sans Pro&quot;, Helvetica, Arial, sans-serif; font-size: 14px; line-height: 20px; color: rgb(102, 102, 102); background-color: rgb(233, 236, 239);"><p style="margin: 0px;">You received this email because you have earned a certificate from <a href="{'{{organization.website}}'}">{{organization.name}}</a>.<br></p><a href="https://app.mycertificate.in/"><p style="display: block; margin-left: auto; margin-top: 20px; text-align: right; width: 100%;">Powered by</p><img alt="logo" src="/img/logo.png" width="45%" height="auto" style="display: block; margin-left: auto; margin-top: 2px;"></a></td></tr></tbody></table></td></tr></tbody></table><div><br></div></div></div>`;


export { finalemail };