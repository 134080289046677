import React, { useEffect, useState } from 'react';
import { Button, Table, Space, App, Popconfirm } from 'antd';
import NewEvent from '../components/NewEvent';
import { deleteEvents, getEventsByUserId, isSuperAdmin } from '../lib/db';
import EditEvents from '../components/EditEvents';
import { DeleteOutlined } from '@ant-design/icons';
import ViewEvent from '../components/ViewEvent';
import Loader from '../components/Loader';



const Events = () => {

  const [loading, setLoading] = useState(false);
  const [pageload,setPageLoad] = useState(true);
  const currentUser = localStorage.getItem('currentUser');
  const [organisationdata, setOrganisationData] = useState([]);
  const { message } = App.useApp();

  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const checkAdminStatus = async () => {
      if (currentUser) {
        const userdata = JSON.parse(currentUser);
        const adminStatus = await isSuperAdmin(userdata.data.id);
        setIsAdmin(adminStatus);
      }
    };
    checkAdminStatus();
  }, [currentUser]);

  const columns = (onEditSuccess) => [
    ...(isAdmin ? [{
      title: 'Created By',
      dataIndex: 'creatorEmail',
    }] : []),
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'Mobile',
      dataIndex: 'mobile',
    },
    {
      title: 'Start Date',
      dataIndex: 'startdate',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Space size="middle">
          <EditEvents eventdata={record} onEditSuccess={onEditSuccess} />
          <Popconfirm
            title="Delete the Row"
            description="Are you sure to delete this record?"
            onConfirm={()=>confirmDelete(record.key)}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
              <Button shape="circle" icon={<DeleteOutlined />} danger/>
          </Popconfirm>
          <ViewEvent eventdata={record} />
        </Space>
      ),
    },
  ];


  const confirmDelete = async(key) => {
    
    try{
      await deleteEvents(key);
      fetchEventData();
       message.success('Event Deleted Successfully');
       
  }
  catch(error){

  }
};
const cancel = (e) => {
  console.log(e);
  message.error('Cancelled');
};


  const fetchEventData = async () => {

    
    const userdata = JSON.parse(currentUser);


    const fetchorgdata = await getEventsByUserId(userdata.data.id);

    if (Object.keys(fetchorgdata).length > 0) {
      const jsonOrgArray = Object.entries(fetchorgdata).map(([id, details]) => {
        return { key: id, ...details };
      });
      setOrganisationData(jsonOrgArray);
      setPageLoad(false);
    }else {
      setOrganisationData([]);
      setPageLoad(false);

    }

  }


  useEffect(() => {

    fetchEventData();
  }, []);


  const start = () => {
    setLoading(true);
    fetchEventData();
    setTimeout(() => {

      setLoading(false);
    }, 1000);
  };





  return (
    <>
    {pageload && <Loader/>}
  {!pageload &&  <div className="w-full p-3 bg-white rounded">
      <div style={{ marginBottom: 16 }} className='flex justify-between'>
        <Button type="primary" onClick={start} loading={loading}>
          Refresh
        </Button>
        <NewEvent  onAddSuccess={fetchEventData}  />

      </div>
      <Table columns={columns(fetchEventData)} dataSource={organisationdata} />
    </div>}
    </>
  );
};

export default Events;
