import { Button, Result } from 'antd'
import React from 'react'

const ContactTeam = () => {
  return (
    <div>
        <Result
  status="500"
  title="Credits Expired"
  subTitle="Sorry, your total credits have expired or been used."
  extra={ <a target='_blank' href='https://wa.me/7653833191?text=My%20credits%20have%20been%20used.%20I%20would%20like%20to%20renew%20my%20plan'><Button type="primary">Contact Sales Team</Button></a>}
/>
    </div>
  )
}

export default ContactTeam