import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { App, Button, Col, DatePicker, Divider, Drawer, Form, Input, Row, Select, Space } from 'antd';
import { insertEvents } from '../lib/db';
const { Option } = Select;

const NewEvent = ({ onAddSuccess }) => {
    const [open, setOpen] = useState(false);
    const { message } = App.useApp();
    const [form] = Form.useForm();
    const [startdate, setStartdate] = useState("");
    const [enddate, setEnddate] = useState("");
    const currentUser = localStorage.getItem('currentUser');
    
    
    const eventTypeOptions = [

        { label: "Workshop", value: "Workshop" },
        { label: "Webinar", value: "Webinar" },
        { label: "Hackathon", value: "Hackathon" },
        { label: "Conference", value: "Conference" },
        { label: "Bootcamp", value: "Bootcamp" },
        { label: "Course", value: "Course" },
        { label: "Training", value: "Training" },
        { label: "Internship", value: "Internship" },
        { label: "Job", value: "Job" },
        { label: "Student Ambassador", value: "Student Ambassador" },
        { label: "Social Event", value: "Social Event" },
        { label: "Charity Event", value: "Charity Event" },
        { label: "Competition", value: "Competition" },
        { label: "Summit", value: "Summit" },
        { label: "Membership", value: "Membership" },
        { label: "Other", value: "Other" }
    ];

    const onFinish = (values) => {

        const { data, timestamp } = JSON.parse(currentUser);

        const updatedValues = {
            userid: data.id,
            ...values,
            startdate: startdate,
            enddate: enddate,
        };
        const sanitizedValues = Object.fromEntries(
            Object.entries(updatedValues).map(([key, value]) => [key, value !== undefined ? value : ""])
        );
        const insertData = async (data) => {
            try {
                await insertEvents(data);
                onAddSuccess();
                message.success('Event Created Successfully');
                form.resetFields();
                onClose();
            }
            catch (error) {

            }
        }
        insertData(sanitizedValues);

    };
    

    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    const onStartChange = (date, dateString) => {
        setStartdate(dateString);
    };
    const onEndChange = (date, dateString) => {
        setEnddate(dateString);
    };

    const tagsoptions = [];

    return (
        <>
            <Button type="primary" onClick={showDrawer} icon={<PlusOutlined />}>
                Create
            </Button>
            <Drawer
                title="Create a new Event"
                width={720}
                onClose={onClose}
                open={open}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={() => form.submit()} type="primary">
                            Submit
                        </Button>
                    </Space>
                }
            >
                <Form form={form} onFinish={onFinish} layout="vertical">
                    <Row gutter={16}>
                        <h1 className="font-bold py-3 text-xl">Basic Info</h1>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="name"
                                label="Name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required',


                                    },
                                ]}
                            >
                                <Input size="large" placeholder="Enter a Name" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="type"
                                label="Type"
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required',
                                    },
                                ]}
                            >

                                <Select size="large" placeholder="Select a type">
                                    <Option value="">Event Type</Option>
                                    {eventTypeOptions && eventTypeOptions.map(option => (
                                        <Option key={option.value} value={option.value}>
                                            {option.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="about"
                                label="About"
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required',
                                    },
                                ]}
                            >
                                <Input.TextArea rows={4} placeholder="Enter a about content" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider />
                    <Row gutter={16}>
                        <h1 className="font-bold py-3 text-xl">Hosting Details</h1>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="startdate"
                                label="Start Date"
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required',
                                    },
                                ]}
                            >
                                <DatePicker size="large" className='w-full' onChange={onStartChange} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="enddate"
                                label="End Date"
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required',
                                    },
                                ]}
                            >
                                <DatePicker size="large" className='w-full' onChange={onEndChange} />
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row gutter={16}>

                        <Col span={24}>
                            <Form.Item
                                name="hashtags"
                                label="Hashtags"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Keywords',
                                    },
                                ]}
                            >

                                <Select
                                    mode="tags"
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Tags"
                                    options={tagsoptions}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider />
                    <Row gutter={16}>
                        <h1 className="font-bold py-3 text-xl">Address</h1>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="locationtype"
                                label="Location Type"
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required',
                                    },
                                ]}
                            >
                                <Select
                                    options={[
                                        {
                                            value: 'online',
                                            label: 'Online',
                                        },
                                        {
                                            value: 'offline',
                                            label: 'Offline',
                                        }
                                    ]}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>

                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">Submit </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </>
    );
};
export default NewEvent;