import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { Form, Input, Select, Button, notification, Spin } from 'antd';
import { isSuperAdmin } from '../../lib/db';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader';

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('org-admin'); // Default role can be set here
  const [credits, setCredits] = useState(''); // Default role can be set here
  const { register } = useAuth();
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();
  const currentUser = localStorage.getItem('currentUser');

  useEffect(() => {
    const checkAdminStatus = async () => {
      if (currentUser) {
        const userdata = JSON.parse(currentUser);
        const adminStatus = await isSuperAdmin(userdata.data.id);
        setIsAdmin(adminStatus);

        if (!adminStatus) {
          navigate('/'); // Redirect non-admin users to homepage
        }
      } else {
        navigate('/'); // Redirect if no user is logged in
      }

      setLoading(false);
    };

    checkAdminStatus();
  }, [currentUser, navigate]);

  if (loading) {
   <Loader/>
  }

  if (!isAdmin) {
    return null; // Optionally render nothing while redirecting
  }



  const handleSubmit = async () => {

    try {
      const userdata = JSON.parse(currentUser);
      await register(email, password, role,credits,userdata.data.id);
      notification.success({
        message: 'Register Successful',
        description: 'You have successfully Registered.',
      });
      // Redirect to dashboard or homepage
    } catch (error) {
      notification.error({
        message: 'Register Failed',
        description: error.message,
      });
    }
  };

  return (
    <div className="max-w-md mx-auto my-8 p-6 bg-white shadow-lg rounded-lg">
      <div className="w-full">
        <h1 className='text-center py-3 font-bold text-xl'>Register</h1>
        <Form layout="vertical" onFinish={handleSubmit}>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: 'Please input your email!' }]}
          >
            <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password type='password'  value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
          </Form.Item>

          <Form.Item
            label="Role"
            name="role"
            rules={[{ required: true, message: 'Please select a role!' }]}
          >
            <Select value={role} onChange={(value) => setRole(value)} placeholder="Select a role">
              <Select.Option value="super-admin">Super Admin</Select.Option>
              <Select.Option value="org-admin">Organization Admin</Select.Option>
            </Select>
          </Form.Item>
          {role === 'org-admin' && (
            <Form.Item
              label="Credits"
              name="credits"
              rules={[{ required: true, message: 'Please input credits!' }]}
            >
              <Input type="number" value={credits} onChange={(e) => setCredits(e.target.value)} placeholder="Credits" />
            </Form.Item>
          )}


          <Form.Item>
            <Button type="primary" htmlType="submit">Register</Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Register;
