import React, { useState, useRef, useEffect } from 'react';
import { Stage, Layer, Image as KonvaImage, Text, Transformer, Rect } from 'react-konva';
import { InboxOutlined, DeleteOutlined, FontSizeOutlined, FileImageOutlined, SaveOutlined } from '@ant-design/icons';
import { Upload, Modal, Input, Button, InputNumber, Radio, Popconfirm, App, Select } from 'antd';
import Konva from 'konva';
import { FiAlignCenter, FiAlignLeft, FiAlignRight } from 'react-icons/fi';
import { FaBold, FaItalic, FaUnderline } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { createCertificateTemplates, getTemplateByUserIdAndTemplateId } from '../lib/db';
import { storage } from '../lib/firebase';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import useImage from 'use-image';

const { Dragger } = Upload;

const CertificateCanvasEdit = ({ templateId, template, userData }) => {
  const [uploadedImage, setUploadedImage] = useState(null);
  const [imageURL, setImageURL] = useState('');

  const [image, imageStatus] = useImage(imageURL);
  // const [image, setImage] = useState(null);
  const { message } = App.useApp();
  const [canvasSize, setCanvasSize] = useState({ width: 0, height: 0 });
  const [uploadedImageSize, setUploadedImageSize] = useState({ width: 0, height: 0 });
  const [selectedShape, setSelectedShape] = useState(null);
  const [qrCodeAdded, setQrcodeAdded] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalType, setModalType] = useState('');
  const [verifylinkclick, setVerifyLinkClick] = useState('');
  const [elementName, setElementName] = useState('');
  const [elements, setElements] = useState({});
  const [fileObject, setFileObject] = useState(null);
  const [elementInit, setElementInit] = useState(true);
  const [saveStart, setSaveStart] = useState(false);

  const [textProps, setTextProps] = useState({
    text: '',
    fontSize: 20,
    fill: '#000000',
    align: 'left',
    fontStyle: '',
    textDecoration: '',
  });
  const [imageProps, setImageProps] = useState({
    width: 100,
    height: 100,
  });
  const stageRef = useRef(null);
  const containerRef = useRef(null);
  const transformerRef = useRef(null);
  const canvasSizeRef = useRef(canvasSize);


  const immortalNames = [
    'verificationlink', 'fullname', 'qrcode'
  ];



  const updateCanvasSize = (img) => {
    console.log(img);
    const container = containerRef.current;
    const containerStyle = window.getComputedStyle(container);
    const containerWidth = container.clientWidth - parseFloat(containerStyle.paddingLeft) - parseFloat(containerStyle.paddingRight);
    const containerHeight = container.clientHeight - parseFloat(containerStyle.paddingTop) - parseFloat(containerStyle.paddingBottom);
    const aspectRatio = img.width / img.height;
    let newWidth, newHeight;

    if (containerWidth / containerHeight < aspectRatio) {
      newWidth = containerWidth;
      newHeight = containerWidth / aspectRatio;
    } else {
      newHeight = containerHeight;
      newWidth = containerHeight * aspectRatio;
    }
    console.log({ width: newWidth, height: newHeight });
    setUploadedImageSize({ width: img.width, height: img.height });
    setCanvasSize({ width: newWidth, height: newHeight });
  };



  useEffect(() => {
    const savedTemplate = template;
    if (savedTemplate && elementInit) {
      const { image_url, elements: savedElements } = savedTemplate.templatedesign;


      if (canvasSize.width > 0) {

        renderElements(savedElements);
        setElementInit(false);
      }
    }
    // if (canvasSize.width > 0) {
    //   resizeElements();
    // }
    canvasSizeRef.current = canvasSize;

  }, [canvasSize]);

  useEffect(() => {
    const handleResize = () => {
      if (image) {
        updateCanvasSize(image);
      }
    };

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [image]);

  useEffect(() => {
    if (templateId && template) {
      console.log('template:', template);
      console.log('template.templatedesign.image_url:', template.templatedesign.image_url);
      setImageURL(template.templatedesign.image_url);
      loadTemplate();
    }
  }, [templateId, template]);

  useEffect(() => {
    if (imageStatus === 'loaded') {
      console.log('Image loaded successfully');
      updateCanvasSize(image);
      setFileObject(image);
      setUploadedImage(image);
    
    } else if (imageStatus === 'loading') {
      console.log('Image is loading...');
    } else if (imageStatus === 'failed') {
      console.log('Failed to load image');
    }
  }, [imageStatus, image]);

  const loadTemplate = async () => {
    if (imageStatus === 'loaded') {
      updateCanvasSize(image);
      setFileObject(image);
    }
    setElements(template.templatedesign.elements);
  };

  // const loadTemplate = async () => {
  //   var savedTemplate = template;
  //   const { image_url, elements: savedElements } = savedTemplate.templatedesign;

  //   if (imageStatus === 'loaded') {
  //     setUploadedImage(image);
  //     updateCanvasSize(image);
  //     setFileObject(image);
  //     setElements(savedElements);
  //   }
  // };


  const renderElements = (savedElements) => {
    const stage = stageRef.current.getStage();
    const layer = stage.children[0];

    Object.keys(savedElements).forEach((key) => {
      const element = savedElements[key].data;
      if (savedElements[key].type === 'text') {
        addTextNode(key, element.text, element);
      } else if (savedElements[key].type === 'image') {
        addImageNode(key, element.url, element);
      }
      else if (savedElements[key].type === 'qr'){
        addImageNode(key, element.url, element);
      }
    });

    layer.draw();
  };

  const resizeElements = () => {
    const stage = stageRef.current.getStage();

    const scaleX = stage.width() / uploadedImageSize.width;
    const scaleY = stage.height() / uploadedImageSize.height;

    const resizedElements = Object.keys(elements).reduce((acc, key) => {
      const element = elements[key];
      const newElement = {
        ...element,
        data: {
          ...element.data,
          x: element.data.x * scaleX,
          y: element.data.y * scaleY,
          fontSize: element.data.fontSize ? element.data.fontSize * scaleY : "",
          width: element.data.width ? element.data.width * scaleX : "",
          height: element.data.height ? element.data.height * scaleY : "",
        },
      };
      return { ...acc, [key]: newElement };
    }, {});

    setElements(resizedElements);
  };

  const handleElementClick = (node) => {
    const layer = stageRef.current.getStage().children[0];
    if (transformerRef.current) {
      transformerRef.current.nodes([node]);
    }
    setSelectedShape(node);
    layer.draw();
  };

  const handleAddText = () => {
    setModalType('text');
    setModalVisible(true);
  };

  const handleInsertImage = () => {
    setModalType('image');
    setModalVisible(true);
  };

  const handleModalOk = () => {
    if (modalType === 'text') {
      addTextNode(elementName);
    } else if (modalType === 'image') {
      addImageNode(elementName);
    }
    setModalVisible(false);
    setElementName('');
  };

  const handleModalCancel = () => {
    setModalVisible(false);
    setElementName('');
  };

  const handleDragBoundFunc = (element, pos) => {

    const stage = stageRef.current.getStage();

    const newX = Math.max(0, Math.min(stage.width() - element.width(), pos.x));
    const newY = Math.max(0, Math.min(stage.height() - element.height(), pos.y));
    return { x: newX, y: newY };
  };

  const addTextNode = (name, textValue = 'Double click to edit', elementProps = {}) => {
    console.log(elementProps);
    const stage = stageRef.current.getStage();
    const layer = stage.children[0];
    const textNode = new Konva.Text({
      x: elementProps.x || 50,
      y: elementProps.y || 60,
      fontSize: elementProps.fontSize || 20,
      text: textValue,
      name,
      fill: elementProps.fill || '#000',
      draggable: true,
      dragBoundFunc: (pos) => handleDragBoundFunc(textNode, pos),
    });



    layer.add(textNode);

    const tr = new Konva.Transformer({
      node: textNode,
      padding: 5,
      flipEnabled: false,
      enabledAnchors: ['middle-left', 'middle-right'],
      boundBoxFunc: (oldBox, newBox) => {
        if (Math.abs(newBox.width) < 20) {
          return oldBox;
        }
        return newBox;
      },
    });

    layer.add(tr);
    if (transformerRef.current) {
      transformerRef.current.nodes([]);
    }
    transformerRef.current = tr;

    textNode.on('transform', () => {
      textNode.width(Math.max(textNode.width() * textNode.scaleX(), 20));
      textNode.scaleX(1);
      textNode.scaleY(1);
    });

    if (name != 'verificationlink') {
      textNode.on('dblclick', () => {
        const textPosition = textNode.absolutePosition();
        const stageBox = stage.container().getBoundingClientRect();

        const areaPosition = {
          x: stageBox.left + textPosition.x,
          y: stageBox.top + textPosition.y,
        };

        const textarea = document.createElement('textarea');
        document.body.appendChild(textarea);

        textarea.value = textNode.text();
        textarea.style.position = 'absolute';
        textarea.style.top = `${areaPosition.y}px`;
        textarea.style.left = `${areaPosition.x}px`;
        textarea.style.width = `${textNode.width()}px`;

        textarea.focus();

        const removeTextarea = () => {
          textarea.removeEventListener('blur', removeTextarea);
          document.body.removeChild(textarea);
          textNode.text(textarea.value);
          layer.batchDraw();
        };

        textarea.addEventListener('blur', removeTextarea);
      });
    }
    stage.on('click', (e) => {
      if (e.target === stage) {
        transformerRef.current.nodes([]);
        layer.draw();
      }
    });

    textNode.on('click', () => {
      transformerRef.current.nodes([textNode]);
      if (name == 'verificationlink') {
        setVerifyLinkClick(true);
      }
      else {
        setVerifyLinkClick(false);

      }
      setSelectedShape(textNode);
      layer.draw();
      setTextProps({
        text: textNode.text(),
        fontSize: textNode.fontSize(),
        fill: textNode.fill(),
        align: textNode.align(),
        fontStyle: textNode.fontStyle(),
        textDecoration: textNode.textDecoration(),
      });
    });

    textNode.on('dragmove', () => {
      // Update state or perform any additional actions on drag
      setElements(prevElements => ({
        ...prevElements,
        [name]: {
          ...prevElements[name],
          data: {
            ...prevElements[name].data,
            x: textNode.x(),
            y: textNode.y(),
          }
        }
      }));
    });

    setElements((prevElements) => ({
      ...prevElements,
      [name]: {
        type: 'text',
        data: {
          x: textNode.x(),
          y: textNode.y(),
          fill: textNode.fill(),
          text: textNode.text(),
          fontSize: textNode.fontSize(),
          id: name,
          stroke: textNode.stroke() ?? "",
          strokeWidth: textNode.strokeWidth(),
          width: textNode.width(),
        },
        header: name,
        required: true,
        initialDisplay: true,
      },
    }));

    layer.draw();
  };
  const handleqrSizeChange = (value) => {
    setImageProps({
      ...imageProps,
      height:value,
      width: value,
    });
    if (selectedShape) {
      selectedShape.width(value);
      selectedShape.height(value);
    }
  };

  const handleWidthChange = (value) => {
    setImageProps({
      ...imageProps,
      width: value,
    });
    if (selectedShape) {
      selectedShape.width(value);
    }
  };

  const handleHeightChange = (value) => {
    setImageProps({
      ...imageProps,
      height: value,
    });
    if (selectedShape) {
      selectedShape.height(value);
    }
  };

  const addImageNode = (name, url, elementProps = {}) => {
    const stage = stageRef.current.getStage();
    const layer = stage.children[0];
    const img = new window.Image();
    if(name !== 'qrcode'){
    img.src = '/img/placeholder.png';
    }
    else{
      img.src = '/img/verify.png';
      
    }

    img.onload = () => {
      const { size, ...restElementProps } = elementProps;
      const imgNode = new Konva.Image({
        image: img,
        x: elementProps.x ?? 50, // Use the coordinates from JSON or default to 50
        y: elementProps.y ?? 60, // Use the coordinates from JSON or default to 60
        width: size ?? (restElementProps.width ?? 100),
        height: size ?? (restElementProps.height ?? 100), 
        name,
        draggable: true,
        ...restElementProps,
        dragBoundFunc: (pos) => handleDragBoundFunc(imgNode, pos),
      });

      layer.add(imgNode);


      if(name == 'qrcode'){
        imgNode.className = 'qr';
      }

      const tr = new Konva.Transformer({
        node: imgNode,
        padding: 5,
        flipEnabled: false,
      });

      layer.add(tr);

      if (transformerRef.current) {
        transformerRef.current.nodes([]);
      }
      transformerRef.current = tr;

      stage.on('click', (e) => {
        if (e.target === stage) {
          transformerRef.current.nodes([]);
          layer.draw();
        }
      });

      imgNode.on('dragmove', () => {
        // Update state or perform any additional actions on drag
        setElements(prevElements => ({
          ...prevElements,
          [name]: {
            ...prevElements[name],
            data: {
              ...prevElements[name].data,
              x: imgNode.x(),
              y: imgNode.y(),
            }
          }
        }));
      });

      imgNode.on('click', () => {
        transformerRef.current.nodes([imgNode]);
        setSelectedShape(imgNode);
    
        layer.draw();
      });

      imgNode.on('transform', () => {
        imgNode.width(Math.max(imgNode.width() * imgNode.scaleX(), 20));
        imgNode.height(Math.max(imgNode.height() * imgNode.scaleY(), 20));
        imgNode.scaleX(1);
        imgNode.scaleY(1);
      });

      setElements((prevElements) => ({
        ...prevElements,
        [name]: {
          type: name == 'qrcode' ? 'qr':'image' ,
          data: {
            x: imgNode.x(),
            y: imgNode.y(),
            id: name,
            ...(name === 'qrcode' 
              ? { size: imgNode.width() } 
              : { height: imgNode.height(), width: imgNode.width(), url })
          },
          header: name,
          required: name === 'qrcode' ? false : true,
          initialDisplay: true,
          
        },
      }));

      layer.draw();
    };
  };

  const handleImageUpload = (canvasimg) => {
    return new Promise((resolve, reject) => {
      const storageRef = ref(storage, `images/${canvasimg.name}`);
      const uploadTask = uploadBytesResumable(storageRef, canvasimg);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Optional: Track the upload progress
        },
        (error) => {
          message.error('Upload failed!');
          reject(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            resolve(downloadURL);
          }).catch((error) => {
            reject(error);
          });
        }
      );
    });
  };
  const updateElementState = (id, newData) => {
    setElements((prevElements) => ({
      ...prevElements,
      [id]: {
        ...prevElements[id],
        data: {
          ...prevElements[id].data,
          ...newData,
        },
      },
    }));
  };

  const handleSave = async () => {
    try {
      setSaveStart(true);
     
      const templatesdata = template;


        const finalJson = {
          user_id: userData.id,
          id: templateId,
          name: templatesdata.name,
          image_url: templatesdata.templatedesign.config.image_url,
          config: {
            name: templatesdata.name,
            image_url:  templatesdata.templatedesign.config.image_url,
            rendered_dimensions: {
              height: canvasSize.height,
              width: canvasSize.width,
            },
            actual_dimensions: {
              height: templatesdata.templatedesign.config.actual_dimensions.height,
              width: templatesdata.templatedesign.config.actual_dimensions.width,
            },
          },
          elements: elements,
        };

        console.log(finalJson);
        const newTemplate = {
          ...templatesdata,
          templatedesign: finalJson
        };
        const sanitizedValues = Object.fromEntries(
          Object.entries(newTemplate).map(([key, value]) => [key, value !== undefined ? value : ""])
        );

        console.log(sanitizedValues);
        const insertData = async (id, data) => {
          try {
            await createCertificateTemplates(id, data);

            message.success('Template Updated Successfully');
            setSaveStart(false);
          }

          catch (error) {
            console.log(error);
            message.error('Template Not Created');

            setSaveStart(false);
          }
        }
        insertData(templatesdata?.key, sanitizedValues);
      

    } catch (error) {
      console.error('Error saving template:', error);
    }

  };

  const handleStageClick = (e) => {
    if (e.target === e.target.getStage() || e.target.name() === 'backgroundRect' || e.target === image || e.target.name() === 'backgroundImg') {
      transformerRef.current.nodes([]);
      stageRef.current.getStage().batchDraw();
      setSelectedShape(null);
      setVerifyLinkClick(false);
    }
  };
  const handleFontFamilyChange = (value) => {
    setTextProps({
      ...textProps,
      fontFamily: value,
    });

    if (selectedShape) {
      selectedShape.fontFamily(value);
      selectedShape.getLayer().batchDraw();

      updateElementState(selectedShape.name(), { fontFamily: value });
    }
  };

  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setTextProps({
      ...textProps,
      [name]: value,
    });
    selectedShape.text(value);
    selectedShape.getLayer().batchDraw();
    updateElementState(selectedShape.name(), { text: value });
  };

  const handleFontSizeChange = (value) => {
    setTextProps({
      ...textProps,
      fontSize: value,
    });
    selectedShape.fontSize(value);
    selectedShape.getLayer().batchDraw();
    updateElementState(selectedShape.name(), { fontSize: value });
  };

  const handleColorChange = (e) => {
    const { value } = e.target;
    setTextProps({
      ...textProps,
      fill: value,
    });
    selectedShape.fill(value);
    selectedShape.getLayer().batchDraw();
    updateElementState(selectedShape.name(), { fill: value });
  };

  const handleAlignmentChange = (e) => {
    const { value } = e.target;
    setTextProps({
      ...textProps,
      align: value,
    });
    selectedShape.align(value);
    selectedShape.getLayer().batchDraw();
    updateElementState(selectedShape.name(), { align: value });
  };

  const handleFontStyleChange = (value) => {
    setTextProps({
      ...textProps,
      fontStyle: value,
    });
    selectedShape.fontStyle(value);
    selectedShape.getLayer().batchDraw();
    updateElementState(selectedShape.name(), { fontStyle: value });
  };

  const handleTextDecorationChange = () => {
    const isUnderlined = textProps.textDecoration.includes('underline');
    const newTextDecoration = isUnderlined
      ? textProps.textDecoration.replace('underline', '').trim()
      : `${textProps.textDecoration} underline`.trim();
    setTextProps({
      ...textProps,
      textDecoration: newTextDecoration,
    });
    if (selectedShape) {
      selectedShape.textDecoration(newTextDecoration);
      selectedShape.getLayer().batchDraw();

      updateElementState(selectedShape.name(), { textDecoration: newTextDecoration });
    }
  };

  const handleDelete = () => {
    if (selectedShape) {
      selectedShape.remove();
      setSelectedShape(null);
      if (transformerRef.current) {
        transformerRef.current.nodes([]);
        transformerRef.current.getLayer().batchDraw();
      }
    }
  };


  const shouldHideButton = (selectedShape && immortalNames.includes(selectedShape.name()));


  return (
    <div>


      <div className='flex md:flex-row flex-col min-h-screen'>
        <div className="md:w-9/12 w-full h-full">
          <div ref={containerRef} className="p-3 flex min-h-[80vh] w-full overflow-hidden flex-col h-full">
            <Stage
              width={canvasSize.width}
              height={canvasSize.height}
              ref={stageRef}
              onMouseDown={handleStageClick}
            >
              <Layer className="layer">
                <Rect
                  name="backgroundRect"
                  width={canvasSize.width}
                  height={canvasSize.height}
                  fill="white"
                />
                <KonvaImage width={stageRef.current?.getStage().width()} height={stageRef.current?.getStage().height()} name="backgroundImg" image={image} />

              </Layer>
            </Stage>
          </div>
          <div className="bg-white p-3 w-fit rounded-lg md:mx-0 mx-auto">
            <Button onClick={handleAddText} icon={<FontSizeOutlined />} type="primary" style={{ marginRight: '10px' }}>Add Text</Button>
            <Button onClick={handleInsertImage} icon={<FileImageOutlined />} type="primary" style={{ marginRight: '10px' }}>Insert Image</Button>
            <Button onClick={handleSave} loading={saveStart} disabled={saveStart} icon={<SaveOutlined />} type="primary">Update</Button>

          </div>
        </div>
        <div className='md:w-3/12 w-full '>
          {selectedShape && selectedShape.className === 'Text' && (
            <div style={{ marginTop: '20px' }} className='bg-white p-3 rounded-lg'>
              <h1 className='text-xl text-center py-3 font-bold'>{selectedShape && selectedShape.name()}</h1>
              {!verifylinkclick && (
                <>
                  <label className='font-bold'>Text Value:</label>
                  <Input
                    name="text"
                    value={textProps.text}
                    className='my-3'
                    onChange={handleTextChange}
                    placeholder="Text"
                  />
                </>
              )}
              <label className='font-bold'>Font Size/Color:</label><br />
              <div className="flex gap-3 my-3">
                <InputNumber
                  name="fontSize"
                  value={textProps.fontSize}
                  onChange={handleFontSizeChange}
                  placeholder="Font Size"
                  min={3}
                  className='w-1/2'
                  max={100}
                />
                <Input
                  name="fill"
                  type="color"
                  value={textProps.fill}
                  onChange={handleColorChange}
                  placeholder="Color"
                  className='w-1/2'
                />
              </div>
              <label className='font-bold'>Font Family:</label><br />
                  <div className="flex gap-3 w-full my-3">

                    <Select className='w-full' onChange={handleFontFamilyChange} value={textProps.fontFamily ?? "Arial"}>
                      <Select.Option value="Arial" >Arial</Select.Option>
                      <Select.Option value="Helvetica">Helvetica</Select.Option>
                      <Select.Option value="Times New Roman">Times New Roman</Select.Option>
                      <Select.Option value="Courier New">Courier New</Select.Option>
                      <Select.Option value="Georgia">Georgia</Select.Option>
                      <Select.Option value="Verdana">Verdana</Select.Option>
                      {/* Add more font Select.Options as needed */}
                    </Select>
                  </div>
              <label className='font-bold'>Text Alignment:</label><br />

              <div className="flex justify-center items-center">
                <Radio.Group className='my-3 flex items-center justify-center' onChange={handleAlignmentChange} value={textProps.align}>
                  <Radio.Button className='flex justify-center items-center' value="left"><FiAlignLeft /></Radio.Button>
                  <Radio.Button className='flex justify-center items-center' value="center"><FiAlignCenter /></Radio.Button>
                  <Radio.Button className='flex justify-center items-center' value="right"><FiAlignRight /></Radio.Button>
                </Radio.Group>
              </div>
              <label className='font-bold'>Text Decoration:</label><br />
              <div className="flex my-3 justify-center items-center">
                <Button.Group>
                  <Button type="default" onClick={() => handleFontStyleChange('bold')}><FaBold /></Button>
                  <Button type="default" onClick={() => handleFontStyleChange('italic')}><FaItalic /></Button>
                  <Button onClick={handleTextDecorationChange}><FaUnderline /></Button>
                </Button.Group>
              </div>
              {!shouldHideButton &&
                (<div className="my-3 flex justify-center items-center w-full">
                  <Popconfirm
                    title="Delete the Element"
                    description="Are you sure to delete this element?"
                    onConfirm={handleDelete}
                    okText="Yes"
                    showCancel={false}
                  >
                    <Button value={"Delete"} icon={<DeleteOutlined />} danger>Delete</Button>

                  </Popconfirm>


                </div>)
              }
            </div>
          )}
             {selectedShape && selectedShape.className === 'qr' && (
                <div className='bg-white p-3 rounded-lg my-10'>
                  <h1 className='text-xl text-center py-3 font-bold'>{selectedShape && selectedShape.name()}</h1>
                  <div className="py-3">
                    <label className='font-bold'>Size:</label><br />
                    <InputNumber min={10} value={imageProps.width} max={300} onChange={handleqrSizeChange} />
                  </div>
                </div>
              )}
          {selectedShape && selectedShape.className === 'Image' && (
            <div className='bg-white p-3 rounded-lg my-10'>
              <h1 className='text-xl text-center py-3 font-bold'>{selectedShape && selectedShape.name()}</h1>
              <div className="py-3">
                <label className='font-bold'>Image Width:</label><br />
                <InputNumber min={10} value={imageProps.width} onChange={handleWidthChange} />
              </div>
              <div className="py-3">
                <label className='font-bold'>Image Height:</label><br />
                <InputNumber min={10} value={imageProps.height} onChange={handleHeightChange} />
              </div>

              {!shouldHideButton &&
                (<div className="my-3 flex justify-center items-center w-full">
                  <Popconfirm
                    title="Delete the Element"
                    description="Are you sure to delete this element?"
                    onConfirm={handleDelete}
                    okText="Yes"
                    showCancel={false}
                  >
                    <Button value={"Delete"} icon={<DeleteOutlined />} danger>Delete</Button>

                  </Popconfirm>


                </div>)
              }
            </div>
          )}
          {!selectedShape && <div className="w-full h-[75%] flex justify-center items-center flex-col">
            <h3>No Elements Selected</h3>
            <p>Click on any element to edit its properties.</p>
          </div>
          }

        </div>
      </div>

      <Modal
        title={`Add ${modalType === 'text' ? 'Text' : 'Image'} Element`}
        open={modalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        <Input placeholder="Element Name" value={elementName} onChange={(e) => setElementName(e.target.value)} />
      </Modal>
    </div>
  );
};

export default CertificateCanvasEdit;