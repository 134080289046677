import React, { useState, useRef, useEffect } from 'react';
import { Form, Input, Button, Select, Tooltip, App } from 'antd';
import {
  BoldOutlined, ItalicOutlined, UnderlineOutlined, AlignLeftOutlined, AlignCenterOutlined, AlignRightOutlined,
  MenuOutlined
} from '@ant-design/icons';
import { InitEmailTemplate } from '../lib/emailtemplate';
import { getEmailConfig, getEmailTemplatesByUserId, insertEmailConfig, insertEmailTemplate, updateEmailConfig } from '../lib/db';
import config from '../lib/config'
const { Option } = Select;

const EmailTemplateForm = () => {
  const [form] = Form.useForm();
  const [emailname, setEmailName] = useState("");
  const [emailTemplateInit, setEmailTemplateInit] = useState(InitEmailTemplate);
  const [selectedEmail,setSelectedEmail] = useState('Default');
  const [prevdatas, setPrevDatas] = useState("");
  const [saved, setSaved] = useState(false);
  const [emailSubjectInit, setEmailSubjectInit] = useState("Congratulations for your {{event.name}} certificate by {{organization.name}}");
  const editorRef = useRef(null);
  const currentUser = localStorage.getItem('currentUser');
  const { data, timestamp } = JSON.parse(currentUser);
  const [templateNames, setTemplateNames] = useState([]);
  const templateRef = useRef(null); 
  useEffect(() => {
    const fetchTemplateData = async () => {
      try {
        
        const prevdata = await getEmailTemplatesByUserId(data.id);

        const emailConfig = await getEmailConfig(data.id);
   
        if (emailConfig && !saved) {
          setSelectedEmail(emailConfig.emailTemplate);
        }

        if (prevdata) {
          const templatesArray = Object.values(prevdata);
          setPrevDatas(templatesArray);

          const selectedTemplate = templatesArray.find(template => template.name === emailConfig.emailTemplate);
          if(selectedTemplate == 'Default'){
            setEmailTemplateInit(InitEmailTemplate);
            setEmailSubjectInit("Congratulations for your {{event.name}} certificate by {{organization.name}}");
          }
          if (selectedTemplate) {
            setEmailTemplateInit(selectedTemplate.template);
            setEmailSubjectInit(selectedTemplate.subject);
          }

          const names = templatesArray.map(template => template.name); 
          setTemplateNames(names);
        } else {
          setEmailTemplateInit(InitEmailTemplate);
          setEmailSubjectInit("Congratulations for your {{event.name}} certificate by {{organization.name}}");
        }
      } catch (error) {
        console.error('Error fetching email template:', error.message);
      }
    };



    fetchTemplateData();
  }, [data.id,saved]);

  const handleEmailDefaultTemplate = async() =>{
    const updatedData = {
      emailTemplate: selectedEmail
    };
    // await insertEmailConfig({userid:data.id,emailTemplate:'Default'});
    await updateEmailConfig(data.id, updatedData);
    message.success("Selected Template set as Default/Current Template")
  }

  const handleInput = (e) => {
    // No need to update state here to avoid re-rendering on every input
  };

  const handleBlur = () => {
    // Update the state when the user finishes editing
    setEmailTemplateInit(editorRef.current.innerHTML);
  };
    
  const { message } = App.useApp();
  const execCommand = (command, value = null) => {
    document.execCommand(command, false, value);
  };

  const insertText = (text) => {
    const emailContentDiv = document.querySelector('.emailcontent');
    if (emailContentDiv) {
      text += emailContentDiv.innerHTML;
      setEmailTemplateInit(text); // Update the state with the new content
    }
  };

  const handleSave = async () => {
    const fulltemplate = templateRef.current.innerHTML;
    const emaildata = {
      userid:data.id,
      name:emailname,
      subject: emailSubjectInit,
      template: emailTemplateInit,
      fulltemplate: fulltemplate
    };
    try{
 
    await insertEmailTemplate(emaildata);
    message.success("Template Created Successfully!");
    setSaved(true);
    setSelectedEmail(emailname);
  }
  catch(error){
    console.log(error);
  }
  };
  const handleTemplateChange = (templateName) => {

    setSelectedEmail(templateName);
  
    // Use a callback function with setState to ensure you're working with the latest state.
    setSelectedEmail((prevSelectedEmail) => {
      const selectedTemplate = prevdatas.find(template => template.name === templateName);
      
      if (templateName === 'Default') {
        setEmailTemplateInit(InitEmailTemplate);
        setEmailSubjectInit("Congratulations for your {{event.name}} certificate by {{organization.name}}");
      } else if (selectedTemplate) {
        setEmailTemplateInit(selectedTemplate.template);
        setEmailSubjectInit(selectedTemplate.subject);
      }
  
      return templateName; // Return the new state value.
    });
  };
  

  return (
    <>
      <div className='w-full h-full'>
  
        <Select placeholder="Select a template"  value={selectedEmail} className='w-full' onChange={handleTemplateChange}>
        <Option value={"Default"}>Default</Option>
          {templateNames.map(name => (
            <Option key={name} value={name}>{name}</Option>
          ))}
        </Select>
      </div>
      <div className="w-full py-3">
        <h1>Email Subject:</h1>
        <Input type='text' value={emailSubjectInit} onChange={(e) => setEmailSubjectInit(e.target.value)} />
      </div>
      <div className='w-full h-full'>
        <h1>Email Body:</h1>
        <div className="toolbar">
          <Tooltip title="Bold">
            <Button icon={<BoldOutlined />} onClick={() => execCommand('bold')} />
          </Tooltip>
          <Tooltip title="Italic">
            <Button icon={<ItalicOutlined />} onClick={() => execCommand('italic')} />
          </Tooltip>
          <Tooltip title="Underline">
            <Button icon={<UnderlineOutlined />} onClick={() => execCommand('underline')} />
          </Tooltip>
          <Tooltip title="Align Left">
            <Button icon={<AlignLeftOutlined />} onClick={() => execCommand('justifyLeft')} />
          </Tooltip>
          <Tooltip title="Align Center">
            <Button icon={<AlignCenterOutlined />} onClick={() => execCommand('justifyCenter')} />
          </Tooltip>
          <Tooltip title="Align Right">
            <Button icon={<AlignRightOutlined />} onClick={() => execCommand('justifyRight')} />
          </Tooltip>
          <Tooltip title="Justify">
            <Button icon={<MenuOutlined />} onClick={() => execCommand('justifyFull')} />
          </Tooltip>
          <Select defaultValue="Variables" style={{ width: 150 }} onChange={insertText}>
            <Option value="{{name}}">Name</Option>
            <Option value="{{event.name}}">Event Name</Option>
            <Option value="{{organization.name}}">Organization Name</Option>
            <Option value="{{download_link}}">Download Link</Option>
            <Option value="{{verification_link}}">Verification Link</Option>
            <Option value="{{certificate_type}}">Certificate Type</Option>
          </Select>
        </div>
        <div ref={templateRef} style={{ width: '100%', color: 'black' }}>
          <div style={{ fontFamily: 'Verdana, Arial, Helvetica, sans-serif', fontSize: '10pt' }}>
            <table width="100%" cellSpacing="0" cellPadding="0" style={{ borderCollapse: 'collapse', borderWidth: '0px' }}>
              <tbody>
                <tr>
                  <td align="center" style={{ backgroundColor: 'rgb(233, 236, 239)' }}>
                    <table width="100%" cellSpacing="0" cellPadding="0"
                      style={{ maxWidth: '600px', borderCollapse: 'collapse', borderWidth: '0px' }}>
                      <tbody>
                        <tr>
                          <td valign="top" align="center" style={{ padding: '16px 24px' }}>
                            <a href={config.site_url} style={{ color: 'rgb(26, 130, 226)', display: 'inline-block' }}>
                              <img
                                src="{{organisation.logo}}" alt="{{organisation.logo}}"
                                width="220" height="auto"
                                style={{ height: 'auto', lineHeight: '100%', textDecoration: 'none', border: '0px', outline: 'none', display: 'block', width: '220px', maxWidth: '220px', minWidth: '220px' }}
                              />
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td align="center" style={{ backgroundColor: 'rgb(233, 236, 239)' }}>
                    <table width="100%" cellSpacing="0" cellPadding="0"
                      style={{ maxWidth: '600px', borderCollapse: 'collapse', borderWidth: '0px' }}>
                      <tbody>
                        <tr>
                          <td align="left"
                            style={{
                              padding: '24px', fontFamily: "'Source Sans Pro', Helvetica, Arial, sans-serif",
                              fontSize: '16px', lineHeight: '24px', backgroundColor: 'rgb(255, 255, 255)'
                            }}>
                            <div
                              ref={editorRef}
                              className="editor remove-all emailcontent"
                              contentEditable
                              dangerouslySetInnerHTML={{ __html: emailTemplateInit }}
                              onInput={handleInput}
                              onBlur={handleBlur}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td align="center" style={{ padding: '24px', backgroundColor: 'rgb(233, 236, 239)' }}>
                    <table width="100%" cellSpacing="0" cellPadding="0"
                      style={{ maxWidth: '600px', borderCollapse: 'collapse', borderWidth: '0px' }}>
                      <tbody>
                        <tr>
                          <td align="center"
                            style={{
                              padding: '12px 24px', fontFamily: "'Source Sans Pro', Helvetica, Arial, sans-serif",
                              fontSize: '14px', lineHeight: '20px', color: 'rgb(102, 102, 102)', backgroundColor: 'rgb(233, 236, 239)'
                            }}>
                            <p style={{ margin: '0px' }}>You received this email because you have earned a
                              certificate from <a href="{'{organization.website}'}">{'{{organization.name}}'}</a>.<br />
                            </p>
                            <a href={config.site_url}>
                            <p style={{ display: 'block', marginLeft: 'auto',marginTop:'20px',textAlign:'right',width:'100%' }}>Powered by</p>
                              <img
                                alt="logo"
                                src={config.powered_by_logo}
                                width="45%" height="auto"
                                style={{ display: 'block', marginLeft: 'auto',marginTop:'2px' }}
                              />
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            <div><br /></div>
          </div>
        </div>
        <div className="w-full h-full">
          <h1>Name:</h1>
          <Input type='text' value={emailname}  onChange={(e) => setEmailName(e.target.value)}/>
        </div>
      </div>
      <div className='w-full py-3 flex justify-between items-center'>
        <Button type='primary' onClick={handleSave}>Save</Button>
        <Button type='primary' onClick={handleEmailDefaultTemplate}>Set As Default/Current Email Template</Button>
      </div>
    </>
  );
};

export default EmailTemplateForm;
