import React, { useState, useEffect } from 'react';
import { Form, Input, Button, App } from 'antd';
import { saveEmailSettings, getSettingsByUserId, editSettings } from '../lib/db';

const EmailSetting = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { notification } = App.useApp(); 
  const currentUser = localStorage.getItem('currentUser');
  const userdata = JSON.parse(currentUser);
  const userId = userdata.data.id;
  const [isEdit, setIsEdit] = useState(false);
  const [settingId, setSettingId] = useState(null);

  useEffect(() => {
    const fetchSettings = async () => {
      setLoading(true);
      try {
        const settings = await getSettingsByUserId(userId);
        if (settings) {
          form.setFieldsValue(settings);
          setIsEdit(true);
          setSettingId(settings.id);
        }
      } catch (error) {
        
      } finally {
        setLoading(false);
      }
    };
    fetchSettings();
  }, [userId, form, notification]);

  const onFinish = (values) => {
    setLoading(true);
    const saveOrEditSettings = async () => {
      try {
        const emailsetting = {
          userid: userId,
          ...values,
        };

        if (isEdit) {
          await editSettings(settingId, emailsetting);
          notification.success({
            message: 'Settings Updated',
            description: 'Your email settings have been updated successfully.',
          });
        } else {
          await saveEmailSettings(emailsetting);
          notification.success({
            message: 'Settings Saved',
            description: 'Your email settings have been saved successfully.',
          });
        }
      } catch (error) {
        notification.error({
          message: 'Save Failed',
          description: error.message,
        });
      } finally {
        setLoading(false);
      }
    };
    saveOrEditSettings();
  };

  return (
    <Form
      className="w-full h-full"
      layout="vertical"
      onFinish={onFinish}
      form={form}
    >
      {/* <Form.Item name="settingName" label="Setting Name" rules={[{ required: true }]}>
        <Input placeholder="Enter Setting Name" />
      </Form.Item>
      <Form.Item name="smtpAdminEmail" label="SMTP Admin Email" rules={[{ required: true, type: 'email' }]}>
        <Input placeholder="Enter SMTP Admin Email" />
      </Form.Item>
      <Form.Item name="smtpHost" label="SMTP Host" rules={[{ required: true }]}>
        <Input placeholder="Enter SMTP Host" />
      </Form.Item>
      <Form.Item name="smtpPort" label="SMTP Port" rules={[{ required: true }]}>
        <Input placeholder="Enter SMTP Port" />
      </Form.Item>
      <Form.Item name="smtpUser" label="SMTP User" rules={[{ required: true }]}>
        <Input placeholder="Enter SMTP User" />
      </Form.Item>
      <Form.Item name="smtpPassword" label="SMTP Password" rules={[{ required: true }]}>
        <Input.Password placeholder="Enter SMTP Password" />
      </Form.Item>
      <Form.Item name="smtpSenderName" label="SMTP Sender Name" rules={[{ required: true }]}>
        <Input placeholder="Enter SMTP Sender Name" />
      </Form.Item> */}
      <Form.Item name="apikey" label="API Key" rules={[{ required: true }]}>
        <Input placeholder="Enter API key" />
      </Form.Item>
      <Form.Item name="fromemail" label="Email" rules={[{ required: true }]}>
        <Input placeholder="Enter From Email" />
      </Form.Item>
      <Form.Item name="domain" label="domain" rules={[{ required: true }]}>
        <Input placeholder="Enter Domain" />
      </Form.Item>
      <Form.Item name="username" label="Username" rules={[{ required: true }]}>
        <Input placeholder="Enter Username" />
      </Form.Item>
      <Form.Item name="password" label="Password" rules={[{ required: true }]}>
        <Input.Password placeholder="Enter Password" />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          {isEdit ? 'Update' : 'Save'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EmailSetting;
