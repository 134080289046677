import React, { useEffect, useState } from 'react';
import { PlusOutlined, LinkedinFilled, FacebookFilled, TwitterCircleFilled, InstagramFilled } from '@ant-design/icons';
import { App, Button, Col, DatePicker, Divider, Drawer, Form, Input, Row, Select, Space } from 'antd';
import ProfileImage from './ProfileImage';
import { country_arr, s_a } from '../lib/countries';
import { insertOrganization } from '../lib/db';
const { Option } = Select;

const NewOrganization = ({ onAddSuccess }) => {
    const [open, setOpen] = useState(false);
    const [logo, setLogo] = useState("");
    const { message } = App.useApp();
    const [form] = Form.useForm();
    const [establishmentdate,setEstablishmentdate] =  useState("");
    const currentUser = localStorage.getItem('currentUser');
    const [countries, setCountries] = useState([{
        "value": "+91",
        "label": "India (+91)"
    }, {
        "value": "+62",
        "label": "Indonesia (+62)"
    }]);

    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedCountryIndex, setSelectedCountryIndex] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [countryCode, setCountryCode] = useState('+91');
    
    const onFinish = (values) => {
 
    const { data, timestamp } = JSON.parse(currentUser);
 
    
    const updatedValues = {
        userid:data.id,
      ...values,
      establishmentdate: establishmentdate,
      countrycode: countryCode,
      country: selectedCountry,
    };
    const sanitizedValues = Object.fromEntries(
        Object.entries(updatedValues).map(([key, value]) => [key, value !== undefined ? value : ""])
      );
    const insertData = async(data) =>{
    try{
        await insertOrganization(data);
        onAddSuccess();
         message.success('Organization Created Successfully');
         form.resetFields();
         onClose();
    }
    catch(error){

    }
}
insertData(sanitizedValues);

      };
    const handleCountryChange = (countryIndex) => {
   console.log(country_arr[countryIndex]);
        setSelectedCountry(country_arr[countryIndex]);
        setSelectedCountryIndex(countryIndex);
        setSelectedState('');
    };

    const handleStateChange = (state) => {
        setSelectedState(state);
        console.log(state);
    };
    const handleCountryCodeChange = (value) => {
        setCountryCode(value);
      };

    useEffect(() => {
        fetch('/json/countries.json')
            .then(response => response.json())
            .then(data => {
                const transformedData = data.map(country => ({
                    value: country.dial_code,
                    label: `${country.name} (${country.dial_code})`
                }));
                setCountries(transformedData);
            })
            .catch(error => {
                console.error('Error fetching the countries:', error);
            });
    }, []);

    const handleLogoChange = (value) => {
        setLogo(value);
        form.setFieldsValue({ logo: value });
    }

    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    const onChange = (date, dateString) => {
       setEstablishmentdate(dateString);
       console.log(dateString);
    };



    return (
        <>
            <Button type="primary" onClick={showDrawer} icon={<PlusOutlined />}>
                Create
            </Button>
            <Drawer
                title="Create a new Organization"
                width={720}
                onClose={onClose}
                open={open}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={() => form.submit()} type="primary">
                            Submit
                        </Button>
                    </Space>
                }
            >
                <Form form={form} initialValues={{ logo: logo }}  onFinish={onFinish} layout="vertical">
                    <Row gutter={16}>
                        <h1 className="font-bold py-3 text-xl">Basic Info</h1>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="name"
                                label="Name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required',


                                    },
                                ]}
                            >
                                <Input size="large" placeholder="Enter a Name" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="type"
                                label="Type"
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required',
                                    },
                                ]}
                            >

                                <Select size="large" placeholder="Select a type">
                                    <Option value="">Organization Type</Option>
                                    <Option value="Student Organization">Student Organization</Option>
                                    <Option value="NGO/Non-profit">NGO/Non-profit</Option>
                                    <Option value="Educational Institution">Educational Institution</Option>
                                    <Option value="School K12">School K12</Option>
                                    <Option value="E-learning">E-learning</Option>
                                    <Option value="Government">Government</Option>
                                    <Option value="Technical">Technical</Option>
                                    <Option value="Social">Social</Option>
                                    <Option value="Sports">Sports</Option>
                                    <Option value="Gaming">Gaming</Option>
                                    <Option value="Honor Society">Honor Society</Option>
                                    <Option value="Other">Other</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="about"
                                label="About"
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required',
                                    },
                                ]}
                            >
                                <Input.TextArea rows={4} placeholder="Enter a about content" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="establishmentdate"
                                label="Establishment Date"
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required',
                                    },
                                ]}
                            >
                                <DatePicker size="large" className='w-full' onChange={onChange} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="organizationsize"
                                label="Organization Size"
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required',
                                    },
                                ]}
                            >

                                <Select size="large" placeholder="Select a organization size">
                                    <Option value="">Organization Size</Option>
                                    <Option value="1-5" selected>1-5</Option>
                                    <Option value="6-50">6-50</Option>
                                    <Option value="51-100">51-100</Option>
                                    <Option value="101-1000">101-1000</Option>
                                    <Option value="1001-10000">1001-10000</Option>
                                    <Option value="10001-100000">10001-100000</Option>
                                    <Option value="More than 100000">More than 100000</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="logo"
                                label="Logo"
                                hidden
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please Choose A Establishment Date',
                                    },
                                ]}
                            >
                                <Input className='w-full' hidden />
                            </Form.Item>
                            <p className='py-2'>Logo</p>
                            <ProfileImage logoValue={handleLogoChange} />
                        </Col>
                    </Row>
                    <Divider />

                    <Row gutter={16}>

                        <Col span={12}>
                            <Form.Item
                                name="country"
                                label="Country"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please select a country',
                                    },
                                ]}
                            >

                                <Select size="large" placeholder="Select a country" value={selectedCountry} onChange={handleCountryChange}>

                                    <Option value="">Select Country</Option>
                                    {country_arr.map((country, index) => (
                                        <Option key={index} value={index+1}>{country}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="state"
                                label="State"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please select a State',
                                    },
                                ]}
                            >

                                <Select size="large" placeholder="Select a state" value={selectedState} onChange={handleStateChange}>
                                    <Option value="">Select State</Option>
                                    {selectedCountryIndex && s_a[selectedCountryIndex] && s_a[selectedCountryIndex].split("|").map((state, index) => (
                                        <Option key={index} value={state}>{state}</Option>
                                    ))}

                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="shopname"
                                label="Shop no./Building name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required',


                                    },
                                ]}
                            >
                                <Input size="large" placeholder="Enter a building name" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="streetname"
                                label="Street Name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required',
                                    },
                                ]}
                            >
                                <Input size="large" placeholder="Enter a street name" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="city"
                                label="City"
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required',


                                    },
                                ]}
                            >
                                <Input size="large" placeholder="Enter a City Name" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="pincode"
                                label="Pin Code"
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required',
                                    },
                                ]}
                            >
                                <Input size="large" placeholder="Enter a Pin Code" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider />
                    <Row gutter={16}>
                        <h1 className="font-bold py-3 text-xl">Contact Info</h1>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="mobile"
                                label="Mobile"
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required',
                                    },
                                ]}
                            >
                                <Space.Compact>
                                <Select onChange={handleCountryCodeChange} size="large" defaultValue={countryCode} options={countries} />
                                <Input size="large" placeholder='Ex.90552250' />
                                </Space.Compact>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="email"
                                label="Email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required',
                                    },
                                ]}
                            >
                                <Input type='email' size="large" placeholder="Enter a Email" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="website"
                                label="Website"
                                rules={[
                                    {
                                        required: false,
                                        message: 'This field is required',
                                    },
                                ]}
                            >

                                <Input size="large" placeholder='Enter Your Website URL' />

                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider />
                    <Row gutter={16}>
                        <h1 className="font-bold py-3 text-xl">Social</h1>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="linkedin"
                            >
                                <Input size="large" placeholder="Enter Your Linkedin URL..." prefix={<LinkedinFilled />} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="facebook"
                            >
                                <Input size="large" placeholder="Enter Your Fackbook URL..." prefix={<FacebookFilled />} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="twitterx"
                            >
                                <Input size="large" placeholder="Enter Your TwitterX URL..." prefix={<TwitterCircleFilled />} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="instagram"
                            >
                                <Input size="large" placeholder="Enter Your Instagram URL..." prefix={<InstagramFilled />} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="instagram"
                            >
                                <Button  type="primary" htmlType="submit">Submit </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </>
    );
};
export default NewOrganization;