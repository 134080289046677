import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { LinkedinFilled, FacebookFilled, TwitterCircleFilled, InstagramFilled } from '@ant-design/icons';

import { getCertificateData, getCertificateDetailData, getEventById, getJobById, getOrganisationById } from '../lib/db';
import { Avatar, Result } from 'antd';
import config from '../lib/config'
import configs from '../lib/config';
const Verification = () => {
    
    const [certificateData, setCertificateData] = useState(null);
    const [jobData, setJobData] = useState(null);
    const [eventdata, setEventdata] = useState(null);
    const [organizationsdata, setOrganizationsdata] = useState(null);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [nodata, setNodata] = useState(false);


    const { id: certificateid } = useParams();

    useEffect(() => {

        const findCertificateData = async () => {
            const certificateDetails = await getCertificateDetailData(certificateid);
            if (certificateDetails) {
                const key = Object.keys(certificateDetails)[0];
                const jobId = certificateDetails[key].job_id;
                const index = certificateDetails[key].index;
                const recipient_id = certificateDetails[key].recipient_id;
                const certificatekey = certificateDetails[key].certificate_key;
                const certificatedata = await getCertificateData(certificatekey, index);
                const jobdata = await getJobById(jobId);
                if (certificatedata && jobdata) {
                    const eventdata = await getEventById(jobdata.events.id);
                    const organizationsdata = await getOrganisationById(jobdata.organizations.organization_id);
                    setCertificateData(certificatedata);
                    setOrganizationsdata(organizationsdata);
                    setEventdata(eventdata);
                    setJobData(jobdata)
                    console.log(organizationsdata);
                    setDataLoaded(true);
                }
            }
            else{
                setNodata(true);
                setDataLoaded(true);

            }

        }
        findCertificateData();
    }, [certificateid]);

    const getFirstLetters = (name) => {
        return name
            .split(' ') // Split the name into an array of words
            .map(word => word.charAt(0)) // Map each word to its first character
            .join(''); // Join the characters back into a single string
    };

    return (
        <div className='w-full h-full flex justify-center items-center'>
            {!dataLoaded && <h1>Loading...</h1>}
            {
                dataLoaded && organizationsdata && (
                    <div className='w-full h-full  flex justify-center max-w-md items-center flex-col gap-3'>
                        <div className="w-full h-full p-3 flex flex-col justify-center items-center rounded-lg  bg-white">
                            <div className="w-full flex flex-col py-3 justify-center items-center">
                                <img src="/img/verifyicon.gif" alt="icon" srcset="" className='w-28' />
                                <h1 className="font-bold">Verified</h1>
                            </div>
                            <div className="flex gap-2">
                                {organizationsdata.logo ?
                                    <Avatar src={<img src={organizationsdata.logo} alt="avatar" />} /> :
                                    <Avatar style={{ backgroundColor: '#fde3cf', color: '#f56a00' }}>{getFirstLetters(organizationsdata.name)}</Avatar>}
                                <h1 className='font-bold text-2xl'>{organizationsdata.name}</h1>
                            </div>
                        </div>
                        <div className="flex justify-center w-full h-full p-3  rounded-lg bg-white items-center">
                            <div className="flex-1">
                                <div className="w-full py-2">
                                    <h1 className="font-bold">
                                        Name
                                    </h1>
                                    <h1 className="font-light">
                                        {certificateData.extra_metadata.name}
                                    </h1>
                                </div>
                                <div className="w-full py-2">
                                    <h1 className="font-bold">
                                        Certificate Type
                                    </h1>
                                    <h1 className="font-light">
                                        {certificateData.type}
                                    </h1>
                                </div>
                                <div className="w-full py-2">
                                    <h1 className="font-bold">
                                        Event Name
                                    </h1>
                                    <h1 className="font-light">
                                        {eventdata.name}
                                    </h1>
                                </div>
                            </div>
                            <div className="flex-1">
                                <div className="w-full py-2">
                                    <h1 className="font-bold">
                                        Start Date
                                    </h1>
                                    <h1 className="font-light">
                                        {eventdata.startdate}
                                    </h1>
                                </div>
                                <div className="w-full py-2">
                                    <h1 className="font-bold">
                                        End Date
                                    </h1>
                                    <h1 className="font-light">
                                        {eventdata.enddate}
                                    </h1>
                                </div>
                                <div className="w-full py-2">
                                    <h1 className="font-bold">
                                        Event Type
                                    </h1>
                                    <h1 className="font-light">
                                        {eventdata.type}
                                    </h1>
                                </div>
                            </div>
                        </div>
                        <div className="w-full h-full p-3  rounded-lg bg-white">
                            <h1 className="font-bold">
                                About Organization
                            </h1>
                            <p>{organizationsdata.about}</p>
                        </div>
                        <div className="w-full h-full p-3 flex justify-between items-center rounded-lg bg-white">
                            <p>Powered By :- <a href={config.site_url} className='font-bold'>{config.name}</a></p>
                            <div className="flex gap-2">
                                <p>Follow Us On:- </p>
                                {organizationsdata?.linkedin !== '' &&
                                <a href={organizationsdata.linkedin} className='text-xl'>
                            <LinkedinFilled/>
                            </a>
                            }
                           {organizationsdata?.instagram !== '' && <a href={organizationsdata.instagram} className='text-xl'>
                            <InstagramFilled />
                            </a>}
                            { organizationsdata?.twitterx != '' &&
                            <a href={organizationsdata?.twitterx} className='text-xl'>
                            <TwitterCircleFilled/>
                            </a>}

                          {organizationsdata?.facebook !=='' &&  <a href={organizationsdata?.facebook} className='text-xl'>
                            <FacebookFilled/>
                            </a>
                            }

                            </div>
                        </div>
                    </div>
                )

            }
          {nodata && (
  <Result
    status="404"
    title="404"
    subTitle={
      <>
        The data you're looking for does not exist. If you believe this is a mistake, please contact us at 
        <a href={`mailto:${configs.email}`}>{configs.email}</a>.
      </>
    }
  />
)}

          
        </div>
    )
}

export default Verification