import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Stage, Image, Layer } from "react-konva";
import DrawingSheet from "./DrawingSheet";
import { Button, FloatButton } from "antd";
import {
  PictureOutlined,FilePdfOutlined,DownloadOutlined
} from '@ant-design/icons';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import configs from "../lib/config";
const PreviewCertificate = forwardRef(({ elementsData, configData, bg, imageLeftToRender = 0, isWhiteLabeled = false, imageElementsHolder }, ref) => {
console.log(imageElementsHolder);

  const [imageURL] = useState(bg);
  const [isLoading, setIsLoading] = useState(true);
  const [image, setImage] = useState(null);
  const [canvasBounds, setCanvasBounds] = useState({ height: 0, width: 0 });
  const [konvaBounds, setKonvaBounds] = useState({ height: 0, width: 0, top: 0, left: 0 });
  const [bgImgBounds, setBgImgBounds] = useState({ height: 0, width: 0 });
  const [prevBounds, setPrevBounds] = useState({ height: 0, width: 0 });
  const [scale, setScale] = useState(1);
  const [loader, setLoader] = useState(false);

  const layerRef = useRef(null);
  const imageRef = useRef(null);
  const stageRef = useRef(null);

  useImperativeHandle(ref, () => ({
    getBase64Image: () => {
      if (layerRef.current) {
        const pixelRatio = configData ? configData.actual_dimensions.height / configData.rendered_dimensions.height : 1;
        return layerRef.current.toDataURL({
          pixelRatio: pixelRatio / scale,
          mimeType: "image/jpeg"
        });
      }
      return null;
    }
  }));

  const reDrawChildren = () => {
    if (layerRef?.current) {
      const { height, width } = konvaBounds;
      if (height > 0 && width > 0) {
        
        const factorX = konvaBounds.width / width;
        const factorY = konvaBounds.height / height;
  
     

        Object.keys(elementsData || {}).forEach((element) => {
          let attrs = elementsData[element].data;
  
          // Apply scaling
          attrs.x *= factorX;
          attrs.y *= factorY;
          if ("fontSize" in attrs) attrs.fontSize *= factorX;
          if ("size" in attrs) attrs.size *= factorX;
          if ("width" in attrs) attrs.width *= factorX;
          if ("height" in attrs) attrs.height *= factorY;
  
          // Update element in state if needed
          elementsData[element].data = attrs;
        });
  
        // Force update of the Layer to reflect the changes
        layerRef.current.getLayer().batchDraw();
      
      }
      // console.log(elementsData);
      setPrevBounds({ ...konvaBounds });
    }
  };
  

  const initRenderSizing = (tempHeight) => {
    if (loader) return;
    if (!configData?.rendered_dimensions) return;

    const newScale = tempHeight / configData.rendered_dimensions.height;
    setScale(newScale);
    if (layerRef) {
      //  console.log(scale,newScale,tempHeight,configData.rendered_dimensions.height);
      
    Object.keys(elementsData || {}).forEach((element) => {
      let attrs = elementsData[element].data;

      // Apply scaling
      attrs.x *= newScale;
      attrs.y *= newScale;
      if ("fontSize" in attrs) attrs.fontSize *= newScale;
      if ("size" in attrs) attrs.size *= newScale;
      if ("width" in attrs) attrs.width *= newScale;
      if ("height" in attrs) attrs.height *= newScale;

      // Update element in state if needed
      elementsData[element].data = attrs;
    });
  }
    setLoader(true); 
  };

  const updateKonvaSize = () => {
    const bounds = document.getElementById("preview_certificate")?.getBoundingClientRect();
    if (bounds) {
      setCanvasBounds(bounds);
      var tempHeight = 1;
      if (bgImgBounds.width > 0 && bgImgBounds.height > 0 && canvasBounds.width > 0 && canvasBounds.height > 0) {
        const canvasAspect = bounds.width / bounds.height;
        const imageAspect = bgImgBounds.width / bgImgBounds.height;
       
        
        if (canvasAspect > imageAspect) {
          tempHeight = bounds.height;
          setKonvaBounds({
            height: bounds.height,
            width: (bgImgBounds.width * bounds.height) / bgImgBounds.height,
            top: 0,
            left: (bounds.width - (bgImgBounds.width * bounds.height) / bgImgBounds.height) / 2
          });
        } else {
          tempHeight = (bgImgBounds.height * bounds.width) / bgImgBounds.width;
          setKonvaBounds({
            height: (bgImgBounds.height * bounds.width) / bgImgBounds.width,
            width: bounds.width,
            top: (bounds.height - (bgImgBounds.height * bounds.width) / bgImgBounds.width) / 2,
            left: 0
          });
        }
        
        initRenderSizing(tempHeight);
        reDrawChildren();
      }
     
    }
   
    
   
  };
  

  const handleImageLoaded = (width, height) => {
    setImage(imageRef.current);
    setBgImgBounds({ height: height || 0, width: width || 0 });
   
    updateKonvaSize();
  };

  const loadImage = () => {
    const img = new window.Image();
    img.src = bg;
    img.crossOrigin = "Anonymous";
    imageRef.current = img;
    
    img.onload = () => { 
        setTimeout(() => {
            handleImageLoaded(img.width, img.height)
          }, 100);
    };
  };

  useEffect(() => {
    loadImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bg]);
  useEffect(() => {
    if(bgImgBounds.width > 0 && isLoading){
        loadImage();
        setIsLoading(false);
    }
    
  }, [bgImgBounds]);

  useEffect(() => {
    const handleResize = () => {
      updateKonvaSize();
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const downloadAsImage = () => {
    if (stageRef.current) {
      const dataURL = stageRef.current.toDataURL();
      const link = document.createElement('a');
      link.download = 'certificate.png';
      link.href = dataURL;
      link.click();
    }
  };




  const downloadAsPDF = () => {
    if (layerRef.current) {
      const pixelRatio = configData ? configData.actual_dimensions.height / configData.rendered_dimensions.height : 1;
      const base64Data = stageRef.current.toDataURL({
        pixelRatio: pixelRatio / scale,
        mimeType: "image/jpeg"
      });
if (base64Data) {
  let canvasImg = new window.Image();
  canvasImg.crossOrigin = "Anonymous";
  canvasImg.src = base64Data;
  canvasImg.onload = async () => {
    const doc = new jsPDF({
      unit: "px",
      format: [canvasImg.width, canvasImg.height],
      orientation: canvasImg.height > canvasImg.width ? "portrait" : "landscape",
    });
    doc.addImage(canvasImg, 0, 0, canvasImg.width, canvasImg.height);
    doc.save(`certificate.pdf`);
  };
}
    }
  };
  
  useEffect(()=>{

    console.log(elementsData);
    
  },[elementsData]);

  

  return !isLoading && image ? (
    <>
    <div className="w-full h-full">
      <Stage
        ref={stageRef}
        height={konvaBounds.height}
        width={konvaBounds.width}
        style={{ position: "absolute", marginLeft: konvaBounds.left, marginTop: konvaBounds.top }}
      >
        <Layer ref={layerRef}>
          <Image height={konvaBounds.height} width={konvaBounds.width} image={image} />
          <DrawingSheet elementsData={elementsData} imageElementsHolder={imageElementsHolder} isWhiteLabeled={isWhiteLabeled} />
        </Layer>
      </Stage>
 
    </div>
         <div className="flex md:flex-row flex-col justify-center py-5 items-center gap-4">
         <Button type="primary" icon={<DownloadOutlined />} onClick={downloadAsPDF}>
         Download as PDF
         </Button>
         {/* <Button icon={<DownloadOutlined />} onClick={downloadAsImage} >
         Download as Image
         </Button> */}
         </div>
        <p className="text-center py-3">Powered By :- <a href={configs.site_url} className='font-bold'>{configs.name}</a></p>

         </>
  ) : (
    <div>Loading...</div>
  );
});

export default PreviewCertificate;
