import React from 'react'
import CertificateCanvas from './CertificateCanvas'


const TemplatesStudio = () => {
  return (
    <div className='w-full'>
        <CertificateCanvas/>
    </div>
  )
}

export default TemplatesStudio