import React from 'react';
import { App, Avatar, Dropdown, Menu, } from 'antd';
import { UserOutlined, SettingOutlined, LogoutOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';




const AvatarDropdown = () => {
    const { message } = App.useApp();
    const { logout } = useAuth();
    const navigate = useNavigate();
    const handleMenuClick = (e) => {
        if (e.key === 'logout') {
          logout();
          message.success('Signed out successfully');
          navigate('/');
        } else if (e.key === 'profile') {
          navigate('/profile');
        
        } else if (e.key === 'setting') {
          navigate('/settings');
        } else {
          message.info(`Clicked on ${e.key}`);
        }
      };

    
    const items = [
        // {
        //   label: 'Profile',
        //   key: 'profile',
        //   icon: <UserOutlined />,
        // },
        {
          label: 'Setting',
          key: 'setting',
          icon: <SettingOutlined />,
        },
        {
          label: 'Logout',
          key: 'logout',
          icon: <LogoutOutlined />,
        }
      ];
    
      const menuProps = {
        items,
        onClick: handleMenuClick,
      };
    
  return (
    <Dropdown menu={menuProps}>
     <Avatar className='cursor-pointer' src={"https://doodleipsum.com/700/avatar-2?i=a84e32424b61b2c7a0c4e9d6451375c2"}  />
  </Dropdown>
  );
};

export default AvatarDropdown;
