import { Spin } from 'antd'
import React from 'react'
import { LoadingOutlined } from '@ant-design/icons';

const Loader = () => {
  return (
    <div className='w-full h-full flex justify-center items-center fixed top-0 left-0 min-h-screen'>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
    </div>
  )
}

export default Loader