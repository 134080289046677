import React, { useEffect, useState } from 'react';
import { Layout, theme } from 'antd';
import Sidebar from './components/Sidebar';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import Organizations from './pages/Organizations';
import Register from './pages/auth/Register';
import { useAuth } from './context/AuthContext';
import Login from './pages/auth/Login';
import Events from './pages/Events';
import Templates from './components/Templates';
import TemplatesStudio from './components/TemplatesStudio';
import EditCertificate from './components/EditCertificate';
import CertificateCreate from './components/CertificateCreate';
import Certificate from './pages/Certificate';
import CertificateList from './components/CertificateList';
import CertificateDisplay from './pages/CertificateDisplay';
import { Header } from 'antd/es/layout/layout';
import configs from "./lib/config"
import Verification from './pages/Verification';
import UsersList from './pages/UsersList';
import { fetchUserData, isSuperAdmin } from './lib/db';
import Settings from './pages/Settings';
import ContactTeam from './pages/ContactTeam';
const { Content } = Layout;

function AppContent() {
  const { currentUser } = useAuth();
 
  const [userData, setUserData] = useState(null);
  const location = useLocation();
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const shouldHideSidebar = location.pathname.startsWith('/c/') || location.pathname.startsWith('/v/');

  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const checkAdminStatus = async () => {
      if (currentUser) {

        const adminStatus = await isSuperAdmin(currentUser.id);
        setIsAdmin(adminStatus);
      }
    };
    checkAdminStatus();

    if (currentUser) {
      // Fetch user data here using currentUser.id
      fetchUserData(currentUser.id)
        .then(data => {
          setUserData(data);
         
        })
        .catch(error => {
          console.error('Error fetching user data:', error);
         
        });
    } 


  }, [currentUser]);

  const hasCreditsLeft = userData?.credits > (userData?.usedCredits ?? 0);


  return (
    <Layout className='common-layout'>
      {!shouldHideSidebar && <Sidebar />}
      {shouldHideSidebar && <Header
        style={{
          padding: 0,
          background: colorBgContainer,
          position: 'fixed',
          top: '0',
          left: '0',
          width: '100%',
          zIndex: 100,
        }}
        className='topbar'
      >
        <div className="flex px-4 w-full">
          <div className="flex-1">
            <div className='logo_container  justify-start w-full h-full flex items-center'>
              <a href={configs.site_url}>
                <img
                  src={configs.logo}
                  className='object-contain h-full max-h-[64px]'
                  alt="logo"
                />
              </a>
            </div>
          </div>
          <div className="flex-1">
            {/* Other content */}
          </div>
        </div>
      </Header>}

      <Layout>
        <Content className='common-content p-3'>
          <Routes>
            <Route exact path="/" element={currentUser ? <Home /> : <Navigate to="/login" replace />} />
            <Route exact path="/users" element={(currentUser && isAdmin) ? <UsersList /> : <Navigate to="/" replace />} />
            <Route exact path="/settings" element={(currentUser && isAdmin) ? <Settings /> : <Navigate to="/" replace />} />
            <Route exact path="/register" element={<Register />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/home" element={currentUser ? <Home /> : <Navigate to="/login" replace />} />

            <Route exact path="/organizations" element={currentUser ? (
              hasCreditsLeft || isAdmin ? (
                <Organizations />
              ) : (
                <ContactTeam />
              )
            ) : <Navigate to="/login" replace />} />
            <Route exact path="/events" element={currentUser ? (
              hasCreditsLeft || isAdmin ? (
                <Events />
              ) : (
                <ContactTeam />
              )
            ) : <Navigate to="/login" replace />} />
            <Route exact path="/templates" element={currentUser ? (
              hasCreditsLeft || isAdmin ? (
                <Templates />
              ) : (
                <ContactTeam />
              )
            )  : <Navigate to="/login" replace />} />
            <Route exact path="/templates/create/:id" element={currentUser ? (
              hasCreditsLeft || isAdmin ? (
                <TemplatesStudio />
              ) : (
                <ContactTeam />
              )
            ) : <Navigate to="/login" replace />} />
            <Route exact path="/templates/edit/:id" element={currentUser ? (
              hasCreditsLeft || isAdmin ? (
                <EditCertificate />
              ) : (
                <ContactTeam />
              )
            ) : <Navigate to="/login" replace />} />
            <Route exact path="/certificate" element={currentUser ? (
              hasCreditsLeft || isAdmin ? (
                <Certificate />
              ) : (
                <ContactTeam />
              )
            )  : <Navigate to="/login" replace />} />
            <Route exact path="/certificate/new" element={currentUser ? (
              hasCreditsLeft || isAdmin ? (
                <CertificateCreate />
              ) : (
                <ContactTeam />
              )
            ) : <Navigate to="/login" replace />} />
            <Route exact path="/certificatelist/:id" element={currentUser ? (
              hasCreditsLeft || isAdmin ? (
                <CertificateList />
              ) : (
                <ContactTeam />
              )
            ) : <Navigate to="/login" replace />} />
            <Route exact path="/c/:id" element={<CertificateDisplay />} />
            <Route exact path="/v/:id" element={<Verification />} />
          </Routes>
        </Content>
        {!shouldHideSidebar &&
        <div className="flex md:flex-row justify-center items-center text-center flex-col p-2 w-full">
          <p className='flex-1'>
            Copyright © {new Date().getFullYear()} <a href={configs.site_url}>{configs.name}</a>
          </p>

  
        </div>
        }
      </Layout>
    </Layout>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
