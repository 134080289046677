import React, { useEffect, useState } from 'react';
import { Button, Table, Space, App, Popconfirm, Tooltip } from 'antd';
import { deleteTemplate, getTemplatesByUserId, isSuperAdmin } from '../lib/db';

import { DeleteOutlined,EditOutlined } from '@ant-design/icons';
import NewTemplates from './NewTemplates';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Loader from './Loader';



const Templates = () => {

  const [loading, setLoading] = useState(false);
  const [pageload,setPageLoad] = useState(true);

  const currentUser = localStorage.getItem('currentUser');
  const [templatesdata, setTemplatesData] = useState([]);
  const { message } = App.useApp();

  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const checkAdminStatus = async () => {
      if (currentUser) {
        const userdata = JSON.parse(currentUser);
        const adminStatus = await isSuperAdmin(userdata.data.id);
        setIsAdmin(adminStatus);
      }
    };
    checkAdminStatus();
  }, [currentUser]);


  const columns = (onEditSuccess) => [
    ...(isAdmin ? [{
      title: 'Created By',
      dataIndex: 'creatorEmail',
    }] : []),
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Created On',
      dataIndex: 'created_at',
      render: (text) => moment(text).format('DD MMM YYYY H:m:s'),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Space size="middle">
        <Tooltip title="Edit" >
               <Link to={`/templates/edit/${record.template_id}`}><Button shape="circle" icon={<EditOutlined />} type="primary" ghost/></Link> 
        </Tooltip>
          <Popconfirm
            title="Delete the Row"
            description="Are you sure to delete this record?"
            onConfirm={()=>confirmDelete(record.key)}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
              <Button shape="circle" icon={<DeleteOutlined />} danger/>
          </Popconfirm>
          
        </Space>
      ),
    },
  ];


  const confirmDelete = async(key) => {
    
    try{
      await deleteTemplate(key);
      fetchTemplatesData();
       message.success('Template Deleted Successfully');
       
  }
  catch(error){

  }
};
const cancel = (e) => {
  console.log(e);
  message.error('Cancelled');
};


  const fetchTemplatesData = async () => {

    
    const userdata = JSON.parse(currentUser);


    const fetchorgdata = await getTemplatesByUserId(userdata.data.id);

    if (Object.keys(fetchorgdata).length > 0) {
      const jsonOrgArray = Object.entries(fetchorgdata).map(([id, details]) => {
        return { key: id, ...details };
      });
      setTemplatesData(jsonOrgArray);
      setPageLoad(false);

      
    }else {
      setTemplatesData([]);
      setPageLoad(false);

    }

  }


  useEffect(() => {

    fetchTemplatesData();
  }, []);


  const start = () => {
    setLoading(true);
    fetchTemplatesData();
    setTimeout(() => {

      setLoading(false);
    }, 1000);
  };





  return (
    <>
    {pageload && <Loader/>}
  {!pageload && <div className="w-full p-3 bg-white rounded">
      <div style={{ marginBottom: 16 }} className='flex justify-between'>
        <Button type="primary" onClick={start} loading={loading}>
          Refresh
        </Button>
        <NewTemplates  onAddSuccess={fetchTemplatesData}  />

      </div>
      <Table columns={columns(fetchTemplatesData)} dataSource={templatesdata} />
    </div>}
    </>
  );
};

export default Templates;
