import { child, get, getDatabase, push, ref, remove, set, update } from "firebase/database";
import { database } from "./firebase";



const isSuperAdmin = async (userId) => {
  try {
    const userRef = ref(database, `users/${userId}`);
    const snapshot = await get(userRef);

    if (snapshot.exists()) {
      const userData = snapshot.val();
      return userData.role === 'super-admin';
    } else {
      throw new Error('User not found');
    }
  } catch (error) {
    console.error('Error checking super admin status:', error.message);
    return false;
  }
};

const insertOrganization = async (data) => {
  try {
    const newOrganizationsRef = push(ref(database, 'organizations/')); 
    await set(newOrganizationsRef, data);

  } catch (error) {
    console.error('Error inserting organizations:', error.message);
    throw error;
  }
};
const insertEmailConfig = async (userId,data) => {
  try {
    const newEmailConfigRef = push(ref(database, `emailconfig/${userId}`)); 
    await set(newEmailConfigRef, data);

  } catch (error) {
    console.error('Error inserting EmailConfig:', error.message);
    throw error;
  }
};

const updateEmailConfig = async (userId, updatedData) => {
  try {
    const emailConfigRef = ref(database, `emailconfig/${userId}`);
    await update(emailConfigRef, updatedData);
    console.log('EmailConfig updated successfully');
  } catch (error) {
    console.error('Error updating EmailConfig:', error.message);
    throw error;
  }
};


const getOrganizationsByUserId = async (userId) => {
  try {
    const isAdmin = await isSuperAdmin(userId);
    const organizationsRef = ref(database, 'organizations');
    const snapshot = await get(organizationsRef);
    
    if (snapshot.exists()) {
      const organizations = snapshot.val();
      const userOrganizations = {};
      
      if (isAdmin) {
        // If the user is a super admin, return all organizations
        for (const id in organizations) {
          const org = organizations[id];
          // Fetch the creator's details
          const creatorRef = ref(database, `users/${org.userid}`);
          const creatorSnapshot = await get(creatorRef);
          const creator = creatorSnapshot.exists() ? creatorSnapshot.val() : {};

          userOrganizations[id] = {
            ...org,
            creatorEmail: creator.email || 'Email not available', // Include creator email
          };
        }
      } else {
        // If the user is not a super admin, return only organizations they created
        for (const id in organizations) {
          if (organizations[id].userid === userId) {
            userOrganizations[id] = organizations[id];
          }
        }
      }
      return userOrganizations;
    } else {
      console.log('No data available');
      return {};
    }
  } catch (error) {
    console.error('Error fetching organizations:', error.message);
    throw error;
  }
};
const getEmailConfig = async (userId) => {
  try {
    const emailConfigRef = ref(database, `emailconfig/${userId}`);
    const snapshot = await get(emailConfigRef);
    if (snapshot.exists()) {
      return snapshot.val();
    } else {
      console.error('No email configuration found for this user.');
      return null;
    }
  } catch (error) {
    console.error('Error fetching EmailConfig:', error.message);
    throw error;
  }
};


const updateOrganization = async (id, data) => {
  try {
    const organizationRef = ref(database, `organizations/${id}`);
    await update(organizationRef, data);
    console.log('Organization updated successfully');
  } catch (error) {
    console.error('Error updating organization:', error.message);
    throw error;
  }
};

const deleteOrganization = async (id) => {
  try {
    const organizationRef = ref(database, `organizations/${id}`);
    await remove(organizationRef);
    console.log('Organization deleted successfully');
  } catch (error) {
    console.error('Error deleting organization:', error.message);
    throw error;
  }
};

//Templates
const insertTemplates = async (data) => {
  try {
    const newTemplatesRef = push(ref(database, 'templates/')); 
    await set(newTemplatesRef, data);

  } catch (error) {
    console.error('Error inserting templates:', error.message);
    throw error;
  }
};

const getTemplatesByUserId = async (userId) => {
  try {
    const isAdmin = await isSuperAdmin(userId);
    const templatesRef = ref(database, 'templates');
    const snapshot = await get(templatesRef);
    
    if (snapshot.exists()) {
      const templates = snapshot.val();
      const userTemplates = {};
      
      if (isAdmin) {
        // If the user is a super admin, return all templates along with creator details
        for (const id in templates) {
          const template = templates[id];
          // Fetch the creator's details
          const creatorRef = ref(database, `users/${template.userid}`);
          const creatorSnapshot = await get(creatorRef);
          const creator = creatorSnapshot.exists() ? creatorSnapshot.val() : {};

          userTemplates[id] = {
            ...template,
            creatorEmail: creator.email || 'Email not available', // Include creator email
          };
        }
      } else {
        // If the user is not a super admin, return only templates created by the user
        for (const id in templates) {
          if (templates[id].userid === userId) {
            userTemplates[id] = templates[id];
          }
        }
      }
      return userTemplates;
    } else {
      console.log('No data available');
      return {};
    }
  } catch (error) {
    console.error('Error fetching templates:', error.message);
    throw error;
  }
};

const getTemplateByUserIdAndTemplateId = async (userId, templateId) => {
  try {
    const dbRef = ref(database);
    const snapshot = await get(child(dbRef, 'templates'));
    if (snapshot.exists()) {
      const templates = snapshot.val();
      for (const id in templates) {
        if (templates[id].userid === userId && templates[id].template_id === templateId) {
          const matchedTemplate = {
            ...templates[id],
            key: id
          };
         
          return matchedTemplate;
        }
      }
      console.log('No matching data found');
      return {};
    } else {
      console.log('No data available');
      return {};
    }
  } catch (error) {
    console.error('Error fetching template:', error.message);
    throw error;
  }
};

const deleteTemplate = async (id) => {
  try {
    const templateRef = ref(database, `templates/${id}`);
    await remove(templateRef);
    console.log('Template deleted successfully');
  } catch (error) {
    console.error('Error deleting template:', error.message);
    throw error;
  }
};

const createCertificateTemplates = async (id, data) => {
  console.log(id,data);
  try {
    const templatesRef = ref(database, `templates/${id}`);
    await update(templatesRef, data);
    console.log('Certificate created successfully');
  } catch (error) {
    console.error('Error creating certificate:', error.message);
    throw error;
  }
};

//Events
const insertEvents = async (data) => {
  try {
    const newEventsRef = push(ref(database, 'events/')); 
    await set(newEventsRef, data);

  } catch (error) {
    console.error('Error inserting Events:', error.message);
    throw error;
  }
};
const getEventsByUserId = async (userId) => {
  try {
    const isAdmin = await isSuperAdmin(userId);
    const eventsRef = ref(database, 'events');
    const snapshot = await get(eventsRef);
    
    if (snapshot.exists()) {
      const events = snapshot.val();
      const userEvents = {};
      
      if (isAdmin) {
        // If the user is a super admin, return all events along with creator details
        for (const id in events) {
          const event = events[id];
          // Fetch the creator's details
          const creatorRef = ref(database, `users/${event.userid}`);
          const creatorSnapshot = await get(creatorRef);
          const creator = creatorSnapshot.exists() ? creatorSnapshot.val() : {};

          userEvents[id] = {
            ...event,
            creatorEmail: creator.email || 'Email not available', // Include creator email
          };
        }
      } else {
        // If the user is not a super admin, return only events created by the user
        for (const id in events) {
          if (events[id].userid === userId) {
            userEvents[id] = events[id];
          }
        }
      }
      return userEvents;
    } else {
      console.log('No data available');
      return {};
    }
  } catch (error) {
    console.error('Error fetching Events:', error.message);
    throw error;
  }
};
const updateEvents = async (id, data) => {
  try {
    const eventRef = ref(database, `events/${id}`);
    await update(eventRef, data);
    console.log('Event updated successfully');
  } catch (error) {
    console.error('Error updating Event:', error.message);
    throw error;
  }
};

const deleteEvents = async (id) => {
  try {
    const eventRef = ref(database, `events/${id}`);
    await remove(eventRef);
    console.log('Event deleted successfully');
  } catch (error) {
    console.error('Error deleting event:', error.message);
    throw error;
  }
};

const getCollections = async (userId,collection) => {
  try {
    const dbRef = ref(database);
    const snapshot = await get(child(dbRef, collection));
    if (snapshot.exists()) {
      const data = snapshot.val();
      const userItems = [];
      for (const id in data) {
        if (data[id].userid === userId) {
          userItems.push({ id, ...data[id] });
        }
      }
      return userItems;
    } else {
      console.log('No data available');
      return {};
    }
  } catch (error) {
    console.error('Error fetching Collections:', error.message);
    throw error;
  }
};

const insertEmailTemplate = async (data) => {
 
  try {
    const newEmailTemplateRef = push(ref(database, 'emailtemplate/'));
    await set(newEmailTemplateRef, data);
    console.log('Email template saved successfully');
  } catch (error) {
    console.error('Error inserting email template:', error.message);
  }
};
const insertMaildata = async (emailDataSet, jobId) => {
  try {
    const maildataRef = ref(database, `maildata/data/${jobId}`);
    await set(maildataRef, emailDataSet);
    return maildataRef.key;  // This will return the jobId as the key
  } catch (error) {
    console.error('Error inserting email data:', error.message);
  }
};

const getSettingsByUserId = async (userId) => {
  const db = getDatabase();
  const settingsRef = ref(db, 'emailSettings');
  const snapshot = await get(settingsRef);

  if (snapshot.exists()) {
    const settings = snapshot.val();
    for (const key in settings) {
      if (settings[key].userid === userId) {
        return { id: key, ...settings[key] };
      }
    }
    throw new Error('No settings found for this user.');
  } else {
    throw new Error('No settings found.');
  }
};
const editSettings = async (id, newSettings) => {
  const db = getDatabase();
  const settingsRef = ref(db, `emailSettings/${id}`);
  
  await update(settingsRef, newSettings);
};

const getEmailTemplatesByUserId = async (userId) => {
  try {
    const dbRef = ref(database);
    const snapshot = await get(child(dbRef, `emailtemplate`));
    if (snapshot.exists()) {
      const emailtemplate = snapshot.val();
      const userEmailtemplate = {};
      for (const id in emailtemplate) {
        if (emailtemplate[id].userid === userId) {
          userEmailtemplate[id] = emailtemplate[id];
        }
      }
    
      return userEmailtemplate;
    } else {
      console.log('No data available');
      return {};
    }
  } catch (error) {
    console.error('Error fetching template:', error.message);
    throw error;
  }
};

const insertJobData = async (data,jobId) => {
 
  try {
    const newJobdataRef = ref(database, `jobs/${jobId}`);
    await set(newJobdataRef, data);
    console.log('Jobs saved successfully');
  } catch (error) {
    console.error('Error inserting Jobs:', error.message);
  }
};
const saveEmailSettings = async (data) => {
 
  try {
    const newEmailSettingdataRef = push(ref(database, 'emailSettings/'));
    await set(newEmailSettingdataRef, data);
    console.log('emailSettings saved successfully');
  } catch (error) {
    console.error('Error inserting emailSettings:', error.message);
  }
};

const fetchUserData = async (userId) => {
  try {
    const userRef = ref(database, `users/${userId}`);
    const userSnapshot = await get(userRef);
    
    if (userSnapshot.exists()) {
      return userSnapshot.val(); // Return user data if it exists
    } else {
      console.log("No user data available");
      return null; // Return null if no user data is found
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error; // Throw error to be handled by the caller
  }
};
const insertCertificateData = async (data,userId) => {
 
  try {
    const newCertificatedataRef = push(ref(database, 'certificates/'));
    await set(newCertificatedataRef, data);
    const userRef = ref(database, `users/${userId}`);
    const userSnapshot = await get(userRef);
    if (userSnapshot.exists()) {
      const userData = userSnapshot.val();
      const newUsedCredits = (userData.usedCredits || 0) + data.length;
            
      await update(userRef, { usedCredits: newUsedCredits });
    }

    return newCertificatedataRef.key;
  } catch (error) {
    console.error('Error inserting Certificate:', error.message);
  }
};
const insertCertificateDetailData = async (data,id) => {
 
  try {
    const newCertificatedataRef = push(ref(database, `certificatesdetails/${id}`));
    await set(newCertificatedataRef, data);
    console.log('Certificate saved successfully');
  } catch (error) {
    console.error('Error inserting Certificate:', error.message);
  }
};
const getCertificateDetailData = async (id) => {
  try {
    // Create a reference to the specific certificate details node
    const certDetailRef = ref(database, `certificatesdetails/${id}`);

    // Fetch data from the reference
    const snapshot = await get(certDetailRef);

    // Check if the snapshot exists and has data
    if (snapshot.exists()) {
      const data = snapshot.val();
      console.log('Certificate details retrieved successfully:', data);
      return data;
    } else {
      console.log('No certificate details found for this ID.');
      return null; // or handle as needed
    }
  } catch (error) {
    console.error('Error retrieving certificate details:', error.message);
    return null; // or handle as needed
  }
};

const getJobByUserId = async (userId) => {
  try {
    const isAdmin = await isSuperAdmin(userId);
    const jobsRef = ref(database, 'jobs');
    const snapshot = await get(jobsRef);

    if (snapshot.exists()) {
      const jobs = snapshot.val();
      const userJobs = {};

      if (isAdmin) {
        // If the user is a super admin, return all jobs along with creator details
        for (const id in jobs) {
          const job = jobs[id];
          // Fetch the creator's details
          const creatorRef = ref(database, `users/${job.userid}`);
          const creatorSnapshot = await get(creatorRef);
          const creator = creatorSnapshot.exists() ? creatorSnapshot.val() : {};

          userJobs[id] = {
            ...job,
            creatorEmail: creator.email || 'Email not available', // Include creator email
          };
        }
      } else {
        // If the user is not a super admin, return only jobs created by the user
        for (const id in jobs) {
          if (jobs[id].userid === userId) {
            userJobs[id] = jobs[id];
          }
        }
      }
      return userJobs;
    } else {
      console.log('No data available');
      return {};
    }
  } catch (error) {
    console.error('Error fetching Jobs:', error.message);
    throw error;
  }
};

const getCertificatesByJobId = async (jobId) => {
  try {
    const dbRef = ref(database);
    const snapshot = await get(child(dbRef, `certificates`));
    if (snapshot.exists()) {
      const certificates = snapshot.val();
      const certificatesByJobId = {};

      // Loop through each certificate group
      for (const groupId in certificates) {
        const group = certificates[groupId];

        // Filter certificates by the job_id
        const filteredCertificates = group.filter(cert => cert.job_id === jobId);
        
        // If any certificates match, add them to the result
        if (filteredCertificates.length > 0) {
          certificatesByJobId[groupId] = filteredCertificates;
        }
      }

      return certificatesByJobId;
    } else {
      console.log('No data available');
      return {};
    }
  } catch (error) {
    console.error('Error fetching certificates:', error.message);
    throw error;
  }
};
const getCertificateData = async (key, certificateIndex) => {
  try {
    // Create a reference to the specific path in the database
    const dbRef = ref(database, `certificates/${key}/${certificateIndex}`);
    
    // Fetch data from the reference
    const snapshot = await get(dbRef);

    // Check if the snapshot exists and contains data
    if (snapshot.exists()) {
      const certificates = snapshot.val();
      return certificates;
    } else {
      console.log('No data available');
      return {};
    }
  } catch (error) {
    console.error('Error fetching certificates:', error.message);
    throw error;
  }
};
const getTemplateData = async (key) => {
  try {
    // Create a reference to the specific path in the database
    const dbRef = ref(database, `templates/${key}`);
    
    // Fetch data from the reference
    const snapshot = await get(dbRef);

    // Check if the snapshot exists and contains data
    if (snapshot.exists()) {
      const certificates = snapshot.val();
      return certificates;
    } else {
      console.log('No data available');
      return {};
    }
  } catch (error) {
    console.error('Error fetching certificates:', error.message);
    throw error;
  }
};
const getEventById = async (key) => {
  try {
    // Create a reference to the specific path in the database
    const dbRef = ref(database, `events/${key}`);
    
    // Fetch data from the reference
    const snapshot = await get(dbRef);

    // Check if the snapshot exists and contains data
    if (snapshot.exists()) {
      const certificates = snapshot.val();
      return certificates;
    } else {
      console.log('No data available');
      return {};
    }
  } catch (error) {
    console.error('Error fetching certificates:', error.message);
    throw error;
  }
};
const getOrganisationById = async (key) => {
  try {
    // Create a reference to the specific path in the database
    const dbRef = ref(database, `organizations/${key}`);
    
    // Fetch data from the reference
    const snapshot = await get(dbRef);

    // Check if the snapshot exists and contains data
    if (snapshot.exists()) {
      const certificates = snapshot.val();
      return certificates;
    } else {
      console.log('No data available');
      return {};
    }
  } catch (error) {
    console.error('Error fetching certificates:', error.message);
    throw error;
  }
};
const getJobById = async (jobid) => {
  try {
    
    const dbRef = ref(database, 'jobs');
    
    
    const snapshot = await get(dbRef);

    
    if (snapshot.exists()) {
      const templates = snapshot.val();

      
      for (const key in templates) {
        if (templates[key].id === jobid) {
          return templates[key]; 
        }
      }

      console.log('No template found with the specified jobid');
      return null;
    } else {
      console.log('No data available');
      return null;
    }
  } catch (error) {
    console.error('Error fetching templates:', error.message);
    throw error;
  }
};

const deleteJobs= async (id) => {
  try {
    const JobRef = ref(database, `jobs/${id}`);
    await remove(JobRef);
    console.log('Job deleted successfully');
  } catch (error) {
    console.error('Error deleting Job:', error.message);
    throw error;
  }
};
const deleteCertificate= async (id,key) => {
  try {
    const JobRef = ref(database, `certificates/${id}/${key}`);
    await remove(JobRef);
    console.log('Certificate deleted successfully');
  } catch (error) {
    console.error('Error deleting Certificate:', error.message);
    throw error;
  }
};

const updateCertificate = async (id,index,data) => {
  try {
    const certificateRef = ref(database, `certificates/${id}/${index}`);
    await update(certificateRef, data);
    console.log('certificate updated successfully');
  } catch (error) {
    console.error('Error updating certificate:', error.message);
    throw error;
  }
};

const updateUser = async (userId, email, password, role, credits) => {
  try {
    const userRef = ref(database, `users/${userId}`);

    // Prepare the updated data object
    const updatedData = {
      email: email,
      role: role,
      credits: credits,
    };

    // Add password only if it is provided
    if (password) {
      updatedData.password = password;
    }

    // Update the user data in the database
    await update(userRef, updatedData);

    console.log('User updated successfully');
  } catch (error) {
    console.error('Error updating user:', error.message);
    throw new Error('Error updating user: ' + error.message);
  }
};
const deleteUser = async (userId) => {
  try {
    // Reference to the user record
    const userRef = ref(database, `users/${userId}`);

    // Remove the user data from the database
    await remove(userRef);

    console.log('User deleted successfully');
  } catch (error) {
    console.error('Error deleting user:', error.message);
    throw new Error('Error deleting user: ' + error.message);
  }
};
const getAllUsers = async (currentUserId) => {
  try {
    // Check if the current user is a super admin
    const userRef = ref(database, `users/${currentUserId}`);
    const userSnapshot = await get(userRef);

    if (!userSnapshot.exists()) {
      throw new Error('User not found');
    }

    const currentUser = userSnapshot.val();

    if (currentUser.role !== 'super-admin') {
      throw new Error('Access denied: User is not a super admin');
    }

    // If the user is a super admin, fetch all users
    const usersRef = ref(database, 'users');
    const usersSnapshot = await get(usersRef);

    if (usersSnapshot.exists()) {
      return usersSnapshot.val();
    } else {
      console.log('No users found');
      return {};
    }
  } catch (error) {
    console.error('Error fetching users:', error.message);
    throw error;
  }
};
const getUserCreatedRecordsCount = async (userId) => {
  try {
    const isAdmin = await isSuperAdmin(userId);
    let orgCount = 0;
    let eventCount = 0;
    let orgAdminCount = 0;
    let credits = 0;
    let usedCredits = 0;
    if (isAdmin) {
      // Fetch all organizations
      const organizationsRef = ref(database, 'organizations');
      const organizationsSnapshot = await get(organizationsRef);
      if (organizationsSnapshot.exists()) {
        const organizations = organizationsSnapshot.val();
        orgCount = Object.keys(organizations).length;
      }

      // Fetch all events
      const eventsRef = ref(database, 'events');
      const eventsSnapshot = await get(eventsRef);
      if (eventsSnapshot.exists()) {
        const events = eventsSnapshot.val();
        eventCount = Object.keys(events).length;
      }

      // Fetch all users and count the org-admins
      const usersRef = ref(database, 'users');
      const usersSnapshot = await get(usersRef);
      if (usersSnapshot.exists()) {
        const users = usersSnapshot.val();
        orgAdminCount = Object.values(users).filter(user => user.role === 'org-admin').length;
      }

      return {
       organization:orgCount,
        events:eventCount,
        orgadmin:orgAdminCount,
      };
    } else {
      // If not super admin, fetch only the user's records
      const organizationsRef = ref(database, 'organizations');
      const organizationsSnapshot = await get(organizationsRef);
      if (organizationsSnapshot.exists()) {
        const organizations = organizationsSnapshot.val();
        orgCount = Object.values(organizations).filter(org => org.userid === userId).length;
      }

      const eventsRef = ref(database, 'events');
      const eventsSnapshot = await get(eventsRef);
      if (eventsSnapshot.exists()) {
        const events = eventsSnapshot.val();
        eventCount = Object.values(events).filter(event => event.userid === userId).length;
      }

      const userRef = ref(database, `users/${userId}`);
      const userSnapshot = await get(userRef);
      if (userSnapshot.exists()) {
        const userData = userSnapshot.val();
        credits = userData.credits || 0;
        usedCredits = userData.usedCredits || 0;
      }

      return {
        organization:orgCount,
        events:eventCount,
        orgAdminCount: 0,
        credits:credits,
        usedCredits:usedCredits
      };
    }
  } catch (error) {
    console.error('Error fetching data:', error.message);
    throw error;
  }
};


export {insertOrganization,getOrganizationsByUserId,updateOrganization,deleteOrganization,insertEvents,getEventsByUserId,updateEvents,deleteEvents,insertTemplates,getTemplatesByUserId,getTemplateByUserIdAndTemplateId,createCertificateTemplates,deleteTemplate,getCollections,insertEmailTemplate,getEmailTemplatesByUserId,insertJobData,insertCertificateData,getJobByUserId,deleteJobs,getCertificatesByJobId,updateCertificate,getEmailConfig,insertEmailConfig,updateEmailConfig,deleteCertificate,insertCertificateDetailData,getCertificateDetailData,getCertificateData,getTemplateData,getJobById,getEventById,getOrganisationById,isSuperAdmin,updateUser,getAllUsers,deleteUser,getUserCreatedRecordsCount,saveEmailSettings,getSettingsByUserId,editSettings,insertMaildata,fetchUserData};