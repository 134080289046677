const configs = {
    "name": "My Certificate",
    "logo": "/img/logo.png",
    "powered_by_logo": "/img/logo.png",
    "logowithback": "/img/favicon.png",
    "site_url":"https://app.mycertificate.in/",
    "theme_color":'#0071bc',
    "certificate_url":"https://app.mycertificate.in/c/",
    "verify_url":"https://app.mycertificate.in/v/",
    "mailapi_url":"https://app.mycertificate.in/mailapi/",
    "powered_by_company":"TECHOCTANET SERVICES PVT LTD",
    "email":"info.mycertificate@octanet.in"
  };

  export default configs;
